const Branch = {
  title: 'Danh sách Chi nhánh',
  createBranch: 'Thêm mới Chi nhánh',
  editBranch: 'Chỉnh sửa Chi nhánh',

  errMsg: {
    existingBranchName: 'Đã tồn tại tên Chi Nhánh!',
    existingIdBranch: ' Mã chi nhánh đã tồn tại trong hệ thống'
  }
};

export default Branch;
