const account = {
  title: {
    welcomeBack: 'Welcome back',
    loginSystem: 'Login system',
    forgotPassword: {
      text: 'Forgot password',
      describe: 'Please enter your staff code to receive the link to restore the password.',
      hasSentTheRecoveryLink: 'Has sent the recovery link!',
      notificationLinkSentTo: 'A link sent to {{email}}',
      pleaseCheckYouEmail: 'Please check your email.',
      sendMailFailed: "Can't send emails."
    },
    resetPassword: {
      text: 'Reset password',
      describe: 'Please enter the new password.',
      successfulReset: 'Successful reset!',
      successfulResetDescribe: 'Please login with new your password.',
      resetPasswordFailed: "Can't reset password."
    }
  },

  notes: {
    sendMail:
      'In case the employee has not set up an email for the account, the password recovery link will be sent to the Super Admin email.'
  },

  validationRules: {
    invalidCredentials: 'Invalid Employee Code or Password',
    emailNotFound: 'Email address not found.',
    imgFileExtension: 'Please upload only jpeg, png, or jpg files',
    maxFileSize: 'File size too large. Please upload an image that is no larger than {{maxSize}}MB'
  }
};

export default account;
