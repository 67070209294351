import {
  GetListPermissionDTO,
  IBodyUpdatePermission,
  IGetListPermissionItem,
  ResponseRBACDTO,
  UpdateListPermissionDTO
} from 'src/dto/administration/user-permission.dto';
import { ResponseDTO } from 'src/dto/base.dto';
import { HttpService } from 'src/services/http.service';

export interface IPermissionStore {
  getListPermission(): Promise<ResponseDTO<IGetListPermissionItem>>;
  updateListPermission(body: IBodyUpdatePermission): Promise<ResponseDTO<ResponseRBACDTO>>;
}

export class PermissionStore implements IPermissionStore {
  constructor(private httpService: HttpService) {}
  public async getListPermission(): Promise<ResponseDTO<IGetListPermissionItem>> {
    const getListPermissionDto = new GetListPermissionDTO();
    return this.httpService.request<GetListPermissionDTO, IGetListPermissionItem>(
      getListPermissionDto
    );
  }

  public async updateListPermission(
    body: IBodyUpdatePermission
  ): Promise<ResponseDTO<ResponseRBACDTO>> {
    const updateListPermissionDto = new UpdateListPermissionDTO(body);
    const res: ResponseDTO<ResponseRBACDTO> = await this.httpService.request(
      updateListPermissionDto
    );
    return res;
  }
}
