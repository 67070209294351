import { messageResponse } from 'src/constants/message-response';

const warehouseHistory = {
  table: {
    title: 'Lịch sử Xuất/ Nhập kho',
    filter: {
      process: 'Quy trình',
      warehouseFrom: 'Kho xuất',
      warehouseTo: 'Kho nhập'
    },
    columns: {
      code: 'Mã',
      content: 'Nội dung',
      name: 'Tên sản phẩm / Gift voucher',
      warehouseFrom: 'Kho xuất',
      warehouseTo: 'Kho nhập',
      createdBy: 'Người tạo'
    }
  },

  placeholder: {
    searchTable: 'Tìm kiếm theo Mã, Tên SP, Gift voucher, Người tạo',
    addressGetProduct: 'Nhập địa chỉ lấy hàng',
    branchSelect: 'Chọn kho',
    productSelect: 'Chọn sản phẩm',
    giftVoucherSelect: 'Chọn Gift Voucher'
  },

  form: {
    import: {
      title: 'Tạo mới phiếu Nhập kho',
      titleDetail: 'Chi tiết phiếu Nhập kho',
      fields: {
        importFrom: 'Nhập hàng từ',
        importTo: 'Nhập hàng tới'
      }
    },

    export: {
      title: 'Tạo mới phiếu Xuất kho',
      titleDetail: 'Chi tiết phiếu Xuất kho',
      fields: {
        exportFrom: 'Xuất hàng từ',
        exportTo: 'Xuất hàng đến'
      }
    }
  },

  conditionsModal: {
    title: 'Thiết lập điều kiện',
    label: {
      conditions: 'Điều kiện sử dụng',
      placeOfUse: 'Nơi sử dụng'
    },
    checkbox: {
      serviceChange: 'Bao gồm service change',
      isWeekends: 'Không bao gồm ngày cuối tuần',
      isHolidays: 'Không bao gồm ngày lễ'
    }
  },
  message: {
    importSuccess: 'Nhập hàng thành công',
    exportSuccess: 'Xuất hàng thành công',
    importFailed: 'Nhập hàng thất bại',
    exportFailed: 'Xuất hàng thất bại',
    [messageResponse.giftVoucherNotEnoughQuantity]: 'Số lượng tồn kho không đủ',
    [messageResponse.productNotEnoughQuantity]: 'Số lượng tồn kho không đủ',
    [messageResponse.giftVoucherCodeWithSeriFromStartNumberToEndNumberIsExisted]:
      'Số Seri đã tồn tại trong kho',
    [messageResponse.startNumberGreaterThanEndNumber]:
      'Số Seri không hợp lệ: Giá trị đến phải lớn hơn giá trị từ',
    [messageResponse.someGiftVoucherCodeWithSeriFromStartNumberToEndNumber]:
      'Số serie không tồn tại trong kho',
    [messageResponse.giftVoucherCodeNotEnoughQuantity]: 'Số lượng tồn kho không đủ',
    [messageResponse.productIdNotEnoughQuantity]: 'Số lượng sản phầm tồn kho không đủ',
    [messageResponse.someGiftVoucherCodeWithSeriFromStartNumberToEndNumberIsNotExistedInWarehouseFrom]:
      'Số serie không tồn tại trong kho'
  },
  parentWarehouse: 'Kho tổng',
  allWarehouse: 'Tất cả kho'
};

export default warehouseHistory;
