import React from 'react';
import { Outlet } from 'react-router-dom';
import LogoImage from 'src/assets/images/logo.svg';
import styles from './bare.layout.module.less';

const BareLayout: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={LogoImage} />
        <Outlet />
      </div>
      <div className={styles.contentImage}></div>
    </div>
  );
};

export default BareLayout;
