export enum TherapeuticIndicationEnum {
  GENERAL = 'GENERAL',
  FACE = 'FACE',
  HEAD = 'HEAD',
  NECK_FONT = 'NECK-FRONT',
  NECK_BACK = 'NECK-BACK',
  CHEST = 'CHEST',
  SHOULDER = 'SHOULDER',
  ARM_FRONT_RIGHT = 'ARM-FRONT-RIGHT',
  ARM_FRONT_LEFT = 'ARM-FRONT-LEFT',
  ARM_BACK_RIGHT = 'ARM-BACK-RIGHT',
  ARM_BACK_LEFT = 'ARM-BACK-LEFT',
  HAND_FRONT_RIGHT = 'HAND-FRONT-RIGHT',
  HAND_FRONT_LEFT = 'HAND-FRONT-LEFT',
  HAND_BACK_RIGHT = 'HAND-BACK-RIGHT',
  HAND_BACK_LEFT = 'HAND-BACK-LEFT',
  BELLY = 'BELLY',
  BACK = 'BACK',
  THIGH_FRONT_RIGHT = 'THIGH-FRONT-RIGHT',
  THIGH_FRONT_LEFT = 'THIGH-FRONT-LEFT',
  THIGH_BACK_RIGHT = 'THIGH-BACK-RIGHT',
  THIGH_BACK_LEFT = 'THIGH-BACK-LEFT',
  LEG_FRONT_RIGHT = 'LEG-FRONT-RIGHT',
  LEG_FRONT_LEFT = 'LEG-FRONT-LEFT',
  LEG_BACK_RIGHT = 'LEG-BACK-RIGHT',
  LEG_BACK_LEFT = 'LEG-BACK-LEFT',
  FOOT_FRONT_RIGHT = 'FOOT-FRONT-RIGHT',
  FOOT_FRONT_LEFT = 'FOOT-FRONT-LEFT',
  FOOT_BACK_RIGHT = 'FOOT-BACK-RIGHT',
  FOOT_BACK_LEFT = 'FOOT-BACK-LEFT'
}

export interface ITherapeuticIndication {
  id: number;
  name: string;
  percent?: number;
}

export interface ITherapeuticIndicationRequest {
  sortBy?: string;
  sortOrder?: string;
  keyword?: string;
}
