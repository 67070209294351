import React from 'react';
import './index.less';
import ReactBoilerplatesApp from './ReactBoilerplatesApp';
import { createRoot } from 'react-dom/client';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const rootElement = document.getElementById('root');
if (!rootElement) {
	throw new Error('Failed to find the root element');
}
const root = createRoot(rootElement);
root.render(<ReactBoilerplatesApp />);
