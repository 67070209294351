import { messageResponse } from 'src/constants/message-response';

const Booking = {
  form: {
    titleCreate: 'Create Booking',
    titleUpdate: 'Update Booking',
    quantity: 'Customer Quantity',
    allergies: 'Allergies',
    titleService: 'Service information',
    titleMassage: 'Massage options',
    titleCustomer: 'Customer information',
    move: 'Other',
    newCustomer: 'New Customer',
    service: {
      requestedByCustomer: 'Requested by Customer',
      seeTour: 'See Tour',
      seeRoom: 'See Room'
    },
    massage: {
      title: 'Massage area'
    }
  },

  overview: {
    availableBeds: 'Available beds',
    totalTherapists: 'Total number of Therapists',
    idleTherapists: 'Available Therapist',
    requestedTherapists: 'Requested Therapists',
    orderedTherapists: 'Therapist with Booking',
    maleCustomer: 'Male Customer',
    femaleCustomer: 'Female Customer'
  },

  status: {
    booked: 'New',
    cancelled: 'Cancelled',
    onService: 'In progress',
    done: 'Completed'
  },

  button: {
    addEmployee: 'Add Therapists',
    addService: 'Add Service',
    remove: 'Remove',
    start: 'Start',
    stop: 'Stop'
  },

  message: {
    [messageResponse.serviceAlreadyStarted]: 'Service Already Started',
    [messageResponse.serviceAlreadyStopped]: 'Service Already Stopped',
    [messageResponse.serviceNotStarted]: 'Service Not Started',
    [messageResponse.anotherServiceIsRunning]: 'Another service is currently being performed',
    [messageResponse.bedNotAvailable]: 'Bed Not Available',
    [messageResponse.employeeNotAvailable]:
      'Cannot start, technician is currently performing a service in another booking!',
    [messageResponse.employeeNotIdle]: 'Employee Not Idle',
    [messageResponse.timeBeforeNow]: 'The appointment time must be after the current time',
    [messageResponse.customerPhoneExists]: 'Phone number already exists in the system!'
  },

  modal: {
    confirmCancelBooking: {
      title: 'Confirm cancel of booking',
      content: 'Are you sure you want to cancel this booking?'
    }
  },

  tooltip: {
    selectPackage: 'Each package can only be selected once in the list.'
  }
};

export default Booking;
