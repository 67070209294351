const products = {
  managementProduct: 'Danh sách Sản phẩm',
  createProduct: 'Thêm mới Sản phẩm',
  editProduct: 'Chỉnh sửa Sản phẩm',

  placeholder: {
    search: 'Tìm kiếm theo Mã SP, Tên SP'
  }
};

export default products;
