const Menu = {
  booking: 'Booking',
  room: 'Phòng',
  employee: 'Nhân viên',
  arrangeTour: 'Xếp Tour',
  promotionDiscountCats: 'Chiến dịch giảm giá',
  pos: 'POS',
  order: 'Đơn hàng',
  depositCategory: 'Phân hạng nạp tiền',
  warehouseHistory: 'Lịch sử Xuất/Nhập',
  inventory: 'Tồn kho',
  dashboard: 'Dashboard',
  myProfile: 'Thông tin của tôi',
};

export default Menu;
