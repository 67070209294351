import { messageResponse } from 'src/constants/message-response';

const rooms = {
  managementRoom: 'Quản lý Phòng',
  createRoom: 'Thêm mới Phòng',
  updateRoom: 'Chỉnh sửa Phòng',

  table: {
    quantity: 'Số lượng giường',
  },

  placeholder: {
    search: 'Tìm kiếm theo Tên phòng',
  },

  toastMessage: {
    [messageResponse.roomNameExist]: 'Tên phòng đã tồn tại trong cùng chi nhánh'
  }
};

export default rooms;
