import { GiftVoucherCatEnum } from 'src/constants/gift-voucher-category';
import { messageResponse } from 'src/constants/message-response';
import {
  DiscountSourceTypeEnum,
  ItemCatEnum,
  OrderStatusEnum,
  PaymentMethodEnum
} from 'src/constants/order.constant';

const order = {
  title: {
    create: 'Create a new Invoice',
    update: 'Update Order'
  },

  placeholder: {
    depositAmount: '50,000'
  },

  button: {
    addTherapist: 'Add Therapist',
    addBooking: 'Add Booking'
  },

  table: {
    column: {
      name: 'Name',
      time: 'Time'
    }
  },

  discountType: {
    [DiscountSourceTypeEnum.MEMBERSHIP_LEVEL]: 'Member Discounts Program',
    [DiscountSourceTypeEnum.GIFT_VOUCHER]: 'Gift Voucher',
    [DiscountSourceTypeEnum.PROMOTION]: 'Promotion Campaign',
    [GiftVoucherCatEnum.DISCOUNT_WITH_SERVICES]: 'Discount With Services',
    [GiftVoucherCatEnum.SERVICE_SPECIFIC_DISCOUNT]: 'Service Specific Discount'
  },

  status: {
    [OrderStatusEnum.PAID]: 'Paid',
    [OrderStatusEnum.UNPAID]: 'UnPaid'
  },

  paymentMethod: {
    [PaymentMethodEnum.NON_VIA_POS]: 'Cash',
    [PaymentMethodEnum.VIA_POS]: 'Visa',
    [PaymentMethodEnum.OTHER]: 'Other',
  },

  text: {
    minutes: 'minutes',
    expiry: 'Expiry',
    currentStock: 'Current Stock',
    tip: 'Tip',
    subTotal: 'Sub Total',
    vat: 'VAT',
    usePoint: 'Use deposited money',
    balance: 'Balance',
    freeService: 'Free Service',
    promotionPeriod: 'Promotion Period',
    notApplyDiscount: 'Not Apply Discount',
    totalBillDiscount: 'Total Bill Discount',
    roomDiscount: 'Room Discount',
    amountTendered: 'Amount Tendered',
    changeOwed: 'Change Owed',
    amountDue: 'Amount Due'
  },

  tab: {
    [ItemCatEnum.SERVICE]: 'Service',
    [ItemCatEnum.PRODUCT]: 'Product',
    [ItemCatEnum.GIFT_VOUCHER]: 'Gift voucher',
    [ItemCatEnum.DEPOSIT]: 'Deposit',
    [ItemCatEnum.PACKAGE]: 'Spa Package',
  },

  resMsg: {
    paidSuccess: 'Successfully',
    unpaidSuccess: 'Save Successfully',
    paidFailed: 'Failed',
    unpaidFailed: 'Save Failed',
    invalidGiftVoucher: 'Invalid Gift voucher',
    [messageResponse.productIdNotExist]: 'Product Not Found',
    [messageResponse.productNotEnoughQuantity]: 'Product Not Enough Quantity to Sell',
    [messageResponse.productIdNotExistInWarehouse]: 'Product Not Found In Branch',
    [messageResponse.prefixCodeNotEnoughQuantity]: 'Not Enough Gift Voucher Quantity To Sell',
    [messageResponse.giftVoucherCodeNotExist]: 'Gift Voucher Not Found',
    [messageResponse.serviceIdNotExist]: 'Service Not Found',
    [messageResponse.promotionIdNotExist]: 'Promotion Not Found',
    [messageResponse.customerIdNotExist]: 'Customer Not Found',
    [messageResponse.giftVoucherDiscountNotExist]: 'Gift Voucher Discount Not Found',
    [messageResponse.giftVoucherDiscountIsUsed]: 'Gift Voucher Discount Is Used',
    [messageResponse.giftVoucherDiscountIsExpired]: 'Gift Voucher Discount Is Expired',
    [messageResponse.giftVoucherDiscountCannotAppliedToday]:
      'Gift Voucher Discount Cannot Applied Today',
    [messageResponse.giftVoucherDiscountCannotAppliedHoliday]:
      'Gift Voucher Discount Cannot Applied For Holiday',
    [messageResponse.giftVoucherInvalidToSell]: 'Gift Voucher Invalid To Sell',
    [messageResponse.noServicesUsedAreEligibleForTheVoucherDiscount]:
      'No Services Used Are Eligible For Gift Voucher Discount',
    [messageResponse.customerNotFound]: 'Customer Not Found',
    [messageResponse.branchNotFound]: 'Branch Not Found',
    [messageResponse.oneOrMoreServiceNotFoundInBranch]: 'One Or More Service Not Found In Branch',
    [messageResponse.requireAtLeastOneItem]: 'Require At Least One Item In Order',
    [messageResponse.oneOrMoreProductNotFoundInWarehouse]:
      'One Or More Product Not Found In Branch',
    [messageResponse.oneOrMoreGiftVoucherNotFoundInWarehouse]:
      'One Or More Gift Voucher Not Found In Warehouse',
    [messageResponse.promotionNotFound]: 'Promotion Not Found',
    [messageResponse.promotionNotFoundInBranch]: 'Promotion Not Found In Branch',
    [messageResponse.promotionCatNameNotFound]: 'Promotion Cat Name Not Found',
    [messageResponse.todayIsOutsideThePromotion]: 'Today Is Outside The Promotion',
    [messageResponse.dayOfWeekNotBeAppliedThePromotion]: 'Day Of Week Not Applied The Promotion',
    [messageResponse.currentTimeIsNotWithinThePromotion]:
      'Current Time Is Not Within The Promotion',
    [messageResponse.numberOfCustomersNotEnoughToApplyPromotion]:
      'Number Of Customers Not Enough To Apply Promotion',
    [messageResponse.numberOfServicesNotEnoughToApplyPromotion]:
      'Number Of Services Not Enough To Apply Promotion',
    [messageResponse.totalItemsLessThanMinItemsInInvoiceOfPromotion]:
      'Total Items Less Than Min Items In Invoice Of Promotion',
    [messageResponse.totalItemsGreaterThanMaxItemsInInvoiceOfPromotion]:
      'Total Items Greater Than Max Items In Invoice Of Promotion',
    [messageResponse.invoiceValueLessThanMinInvoiceValueOfPromotion]:
      'Invoice Value Less Than Min Invoice Value Of Promotion',
    [messageResponse.requiredAtLeastPaymentTransaction]: 'Required At Least Payment Transaction',
    [messageResponse.someItemsInvalid]: 'Some Items Invalid',
    [messageResponse.invoiceNotFound]: 'Invoice Not Found',
    [messageResponse.notAllowedUpdatePaidInvoice]: 'Not Allowed Update Paid Invoice',
    [messageResponse.currencyCodeNotFound]: 'Currency Code Not Found',
    [messageResponse.noPayableServiceInFreeList]: 'No Payable Service In Free List',
    [messageResponse.cannotApplyThisPromotion]: 'Cannot Apply This Promotion',
    [messageResponse.giftVoucherCodesCannotEmpty]: 'Gift Voucher Codes Cannot Empty',
    [messageResponse.discountSourceTypeNotFound]: 'Discount Source Type Not Found',
    [messageResponse.giftVoucherIsUsed]: 'Gift Voucher Is Used',
    [messageResponse.giftVoucherIsSold]: 'Gift Voucher Is Sold',
    [messageResponse.cannotApplyForThisBranch]: 'Cannot Apply For This Branch',
    [messageResponse.cannotApplyOnWeekend]: 'Cannot Apply On Weekend',
    [messageResponse.cannotApplyToHoliday]: 'Cannot Apply To Holiday',
    [messageResponse.giftVoucherNotFound]: 'Gift voucher not found',
    [messageResponse.invoiceNotIncludeGiftVoucherServicesDiscounts]:
      'Hóa đơn không bao gồm DV được giảm giá',
    [messageResponse.giftVoucherIsExpired]: 'Gift voucher is expired',
    [messageResponse.giftVoucherHasNotBeenPurchased]: 'Gift voucher has not been purchased',
    [messageResponse.lockerNoCanNotBeLargerThan20Characters]:
      "Locker No can't be larger than 20 characters"
  }
};

export default order;
