import { messageResponse } from 'src/constants/message-response';

const Booking = {
  form: {
    titleCreate: 'Thêm mới Booking',
    titleUpdate: 'Cập nhật Booking',
    quantity: 'Số lượng khách',
    allergies: 'Thông tin dị ứng',
    titleService: 'Thông tin dịch vụ',
    titleMassage: 'Tùy chọn massage',
    titleCustomer: 'Thông tin khách hàng',
    move: 'Khác',
    newCustomer: 'Khách hàng mới',

    service: {
      requestedByCustomer: 'Yêu cầu KTV',
      seeTour: 'Xem tour',
      seeRoom: 'Xem phòng'
    },
    massage: {
      title: 'Vùng massage'
    }
  },

  overview: {
    availableBeds: 'Giường trống',
    totalTherapists: 'Tổng số KTV',
    idleTherapists: 'KTV trống',
    requestedTherapists: 'KTV được Y/C',
    orderedTherapists: 'KTV có Booking',
    maleCustomer: 'Khách Nam',
    femaleCustomer: 'Khách Nữ'
  },

  status: {
    booked: 'Mới',
    cancelled: 'Đã huỷ',
    onService: 'Đang thực hiện',
    done: 'Hoàn thành'
  },

  button: {
    addEmployee: 'Thêm KTV',
    addService: 'Thêm DV',
    remove: 'Xoá',
    start: 'Bắt đầu',
    stop: 'Dừng'
  },

  message: {
    [messageResponse.serviceAlreadyStarted]: 'Dịch vụ đã bắt đầu',
    [messageResponse.serviceAlreadyStopped]: 'Dịch vụ đã dừng',
    [messageResponse.serviceNotStarted]: 'Dịch vụ chưa bắt đầu',
    [messageResponse.anotherServiceIsRunning]: 'Dịch vụ khác đang được thực hiện',
    [messageResponse.bedNotAvailable]: 'Giường đang không có sẵn',
    [messageResponse.employeeNotAvailable]:
      'Không thể bắt đầu, KTV đang thực hiện dịch vụ ở Booking khác!',
    [messageResponse.employeeNotIdle]: 'KTV đang không sẵn sàng',
    [messageResponse.timeBeforeNow]: 'Thời gian đặt hẹn phải sau hiện tại',
    [messageResponse.customerPhoneExists]: 'SĐT đã tồn tại trong hệ thống!'
  },

  modal: {
    confirmCancelBooking: {
      title: 'Xác nhận hủy booking',
      content: 'Bạn có chắc chắn muốn hủy booking này?'
    }
  },

  tooltip: {
    selectPackage: 'Mỗi gói chỉ được chọn một lần trong danh sách'
  }
};

export default Booking;
