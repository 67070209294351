const productContainers = {
  form: {
    titleCreate: 'Add New Product Unit',
    titleUpdate: 'Update Product Unit'
  },

  errMsg: {
    existingUnit: 'Unit name already exists!'
  }
};

export default productContainers;
