import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import { IProductsItem } from 'src/interfaces/master-data/products';
import { IPrefixCodeItem } from '../product-group/gift-voucher.dto';
import { IProductCat } from './product-cats.dto';
import {
  IProductDetails,
  ITransactionCategory,
  IVoucherDetails,
  IWarehouse
} from './inventory-history.dto';
import { TABLE_SORT_DIRECTION } from 'src/constants';
import { IBranchItem } from 'src/interfaces/master-data/branch';
import { Dayjs } from 'dayjs';

export interface IInventory {
  id: number;
  productCategory: IProductCat;
  product?: IProductsItem;
  prefixCode?: IPrefixCodeItem;
  warehouse: IWarehouse;
  currentStock: number;
}

export interface IInventoryRequest {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: TABLE_SORT_DIRECTION;
  keyword?: string;
  catId?: number[];
  warehouseId?: number[];
}

export interface IInventoryHistoryItem {
  catId: number;
  createdAt: string;
  createdBy: {
    additionalInfo: {};
    code: string;
    email: string;
    fullName: string;
    gender: string;
    hireDate: string;
    id: number;
    imagePath: string;
    isActive: boolean;
    phone: string;
    therapistCode?: string;
  };
  desc: string;
  giftVoucherDetails: IVoucherDetails[];
  productDetails: IProductDetails[];
  distributor: string;
  transactionCategory: ITransactionCategory;
  warehouseFrom: {
    id: number;
    name: string;
    branch: IBranchItem;
  };
  warehouseIdFrom: number;
  warehouseIdTo: number;
  id: number;
}

export interface IInventoryHistoriesDetail {
  name: string;
  code: string;
  impQuantity: number;
  expQuantity: number;
  remainingQuantity: number;
  histories: IInventoryHistoryItem[];
}

export interface IInventoryHistoriesDetailRequest {
  warehouseId: string;
  dateFrom?: string | Dayjs;
  dateTo?: string | Dayjs;
}

export class GetListInventoryDTO extends DTO {
  public param: object | undefined;
  public query: IInventoryRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.INVENTORIES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IInventoryRequest) {
    super();
    this.query = query;
  }
}

export class GetInventoryHistoriesDetailDTO extends DTO {
  public param: { id: string };
  public query: IInventoryHistoriesDetailRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.INVENTORY_HISTORIES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }, query?: IInventoryHistoriesDetailRequest) {
    super();
    this.param = param;
    this.query = query;
  }
}
