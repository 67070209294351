const validationRules = {
  'password-invalid': 'Invalid login password.',
  'user-invalid': 'Invalid Username or Password.',
  'user-inactive':
    'Your account is currently inactive. Please contact customer support for assistance in reactivating your account.',
  'username-existed': 'Username has already exists. Please enter another username',
  'email-existed': 'Email has already exists. Please enter another email.',
  'employeeID-existed': 'Employee ID has already exists. Please enter another Employee ID.',
  'deposit-category-rank-exist': 'The ranking value is duplicated. Please enter it again!'
};

export default validationRules;
