import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreatePackageDTO,
  DetailPackageDTO,
  GetListPackageDTO,
  UpdatePackageDTO
} from 'src/dto/package.dto';
import { IOptions } from 'src/interfaces';
import {
  IPackageBody,
  IPackageItem,
  IPackageRequest,
  IPackage
} from 'src/interfaces/master-data/package';
import { IHttpService } from 'src/services/http.service';

export interface IPackageStore {
  listPackage: IPackageItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  totalUnread: number;
  fetched: boolean;
  fetchList(request?: IPackageRequest, isStoreData?: boolean): Promise<ResponseDTO<IPackage>>;
  search(request?: IPackageRequest): Promise<IPackage | undefined>;
  create(body: IPackageBody): Promise<ResponseDTO<IPackageItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IPackageItem>>;
  update(body: IPackageBody, param: { id: string }): Promise<ResponseDTO<IPackageItem>>;
  destroyStoreWhenLogout(): void;
  optionList: IOptions[];
}

export class PackageStore implements IPackageStore {
  listPackage: IPackageItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  totalUnread = 0;
  fetched = false;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listPackage: observable.ref,
      pageSize: observable,
      totalPages: observable,
      totalUnread: observable,
      totalRecords: observable,
      fetched: observable,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IPackageRequest): Promise<ResponseDTO<IPackage>> {
    const requestDTO = new GetListPackageDTO(request);
    const result: ResponseDTO<IPackage> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listPackage = result.data?.paginatedResults ?? [];
        this.totalPages = result.data?.total ?? 0;
        this.totalRecords = result.data?.total ?? 0;
        this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;
        this.fetched = true;
      });
    }
    return result;
  }

  public async search(request?: IPackageRequest): Promise<IPackage | undefined> {
    const requestDTO = new GetListPackageDTO(request);
    const result: ResponseDTO<IPackage> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      return result.data;
    }
    return undefined;
  }

  public async create(body: IPackageBody) {
    const createDTO = new CreatePackageDTO(body);
    const res: ResponseDTO<IPackageItem> = await this.http.request(createDTO);
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailPackageDTO(query);
    const res: ResponseDTO<IPackageItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IPackageBody, param: { id: string }) {
    const updateStoreDTO = new UpdatePackageDTO(body, param);
    const res: ResponseDTO<IPackageItem> = await this.http.request(updateStoreDTO);
    this.fetchList();
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.pageSize = 1;
      this.totalPages = 0;
      this.totalUnread = 0;
      this.listPackage = [];
    });
  }

  get optionList(): IOptions[] {
    return this.listPackage.map((item: IPackageItem) => ({
      label: item.name,
      value: item.id
    }));
  }
}
