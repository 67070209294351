const Employee = {
  employeeManagement: 'Danh sách Nhân viên',
  createEmployees: 'Thêm mới nhân viên',
  updateEmployees: 'Chỉnh sửa thông tin nhân viên',

  form: {
    password: 'Mật khẩu đăng nhập',
    hireDate: 'Ngày vào làm'
  },

  placeholder: {
    inputSearch: 'Tìm kiếm theo Mã NV, Tên, Email, Số điện thoại'
  },

  errorMessage: {
    therapistCodeExists: 'Mã KTV đã tồn tại trong hệ thống Mộc Hương Spa'
  },

  statuses: {
    available: 'Đang có sẵn',
    idle: 'Đang rãnh',
    onService: 'Đang thực hiện dịch vụ',
    isNotCheckin: 'Chưa check-in'
  }
};

export default Employee;
