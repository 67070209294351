const Report = {
  reportType: {
    bedRoomPerformance: 'Hiệu suất Phòng/Giường',
    giftVoucher: 'Gift Voucher',
    product: 'Sản phẩm',
    customer: 'Khách hàng',
    promotionCampaign: 'Chương trình tháng',
    therapist: 'KTV',
    revenue: 'Doanh thu'
  },

  revenue: {
    estimatedRevenue: 'Doanh thu',
    tourFee: 'Tiền Tour',
    discount: 'Các khoản giảm giá',
    discountCat: {
      membershipLevel: 'Hạng thành viên',
      promotionCampaign: 'Chiến dịch',
      giftVoucher: 'Gift Voucher'
    },
    viaDeposit: 'Thanh toán bằng tiền trong tài khoản MH Spa',
    finalizedRevenue: 'Doanh thu thực nhận',
    nonViaPOS: 'Thanh toán bằng Visa',
    viaPOS: 'Thanh toán bằng tiền mặt'
  },

  therapist: {
    employeeCode: 'Mã NV',
    name: 'Tên KTV',
    alias: 'Mã KTV',
    branch: 'Chi nhánh',
    serviceQty: 'SL DV đã thực hiện',
    serviceCat: 'Nhóm DV',
    serviceDetails: 'Chi tiết từng DV',
    totalExtraFees: 'Tiền Yêu cầu',
    totalCommissionFees: 'Tiền Tour',
    TIPS: 'Tiền Tip',
    package: 'Combo liệu trình',
    packageDetails: 'Chi tiết combo liệu trình'
  },

  product: {
    code: 'Mã SP',
    name: 'Tên SP',
    importTransactionQty: 'SL Nhập',
    paymentTransactionQty: 'SL Bán',
    exportTransactionQty: 'SL Điều chuyển',
    inventoryQty: 'SL Tồn Kho'
  },

  giftVoucher: {
    prefixCode: 'Mã Gift',
    importQty: 'SL Nhập',
    exportQty: 'SL Bán',
    paymentQty: 'SL Điều chuyển',
    stockQty: 'SL Tồn',
    redeemedQty: 'SL Gift đã quay về',
    notRedeemedQty: 'SL Gift chưa quay về',
    expiredButNotRedeemedQty: 'SL đã hết hạn'
  },

  bedRoomPerformance: {
    bedQty: 'Số lượng giường được sử dụng',
    noOfServicesPerformed: 'Số lượng DV đã thực hiện',
    totalBedHours: 'Tổng thời gian sử dụng các Giường'
  },

  customer: {
    groupName: 'Nhóm',
    name: 'Tên',
    totalAmountBeforeDiscount: 'Tổng Doanh thu (không tính discount + VAT)',
    discountBySourceType: 'Tổng giảm giá theo từng loại',
    finalizedAmount: 'Doanh thu thực của KH',
    finalizedAmountByPaymentMethod: 'Tổng thanh toán theo từng hình thức',
    beforeDeposit: 'Giá trị tồn đầu tài khoản',
    depositValue: 'Tổng giá trị nạp vào tài khoản',
    totalPaidByDeposit: 'Tổng thanh toán bằng tiền trong tài khoản',
    afterDeposit: 'Giá trị còn lại trong tài khoản'
  },

  promotionCampaign: {
    promotionName: 'Tên Chương trình',
    refLink: 'Link chi tiết Chương trình',
    finalizedRevenue: 'Doanh thu thực của các hoá đơn có áp dụng Chương trình',
    depositUsedAmount: 'Doanh thu dùng tiền nạp của các hoá đơn có áp dụng Chương trình',
    totalDiscount: 'Tổng số giảm giá khi áp dụng Chương trình',
    customersByCat: 'Tổng số Nhóm KH/KH áp dụng Chương trình'
  },

  export: {
    therapistReport: 'Báo cáo KTV',
    roomReport: 'Báo cáo phòng giường',
    customerReport: 'Báo cáo khách hàng',
    revenueReport: 'Báo cáo doanh thu',
    productReport: 'Báo cáo sản phẩm',
    giftVoucherReport: 'Báo cáo mã khuyến mãi',
    promotionReport: 'Báo cáo chương trình tháng'
  }
};

export default Report;
