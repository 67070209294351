const GiftVoucherGroups = {
  managementGiftVoucherGroups: 'Gift Voucher Groups Management',
  createGiftVoucherGroups: 'Create New Gift Voucher Group',
  updateGiftVoucherGroups: 'Update Gift Voucher Group',

  form: {
    typeOfGiftVoucherGroups: 'Gift Voucher Type',
    includeServiceExtraFee: 'Include service fee',
    discountOnOrder: 'Discount on any Services',
    discountOnService: 'Discount on specific Services'
  },

  placeholder: {
    search: 'Search by Code',
    giftVoucherGroupsInput: 'Select Gift Voucher Type'
  },

  details: {
    title: 'Gift Voucher Group Details'
  }
};

export default GiftVoucherGroups;
