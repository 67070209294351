import { makeAutoObservable, runInAction } from 'mobx';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CustomerCreateDTO,
  CustomerBody,
  CustomerUpdateDTO,
  CustomerUpdatelParam,
  ExportCustomersDTO,
  ImportCustomersDTO
} from 'src/dto/customer/create.dto';
import {
  CustomerDepositDetailDTO,
  CustomerDetailDTO,
  CustomerDetailParam
} from 'src/dto/customer/detail.dto';
import { CustomerDTO, ICustomerRequest, ICustomerResponse } from 'src/dto/customer/list.dto';
import { IPaginationResponse } from 'src/interfaces';
import { ICustomer } from 'src/interfaces/customer';
import { IHttpService } from 'src/services/http.service';

export interface ICustomerStore {
  list: ICustomer[];

  fetchList(request?: ICustomerRequest): Promise<ICustomerResponse>;
  search(request?: ICustomerRequest): Promise<IPaginationResponse<ICustomer> | undefined>;
  fetchCreate(body?: CustomerBody): Promise<ResponseDTO<unknown>>;
  fetchUpdate(param: CustomerDetailParam, body?: CustomerBody): Promise<ResponseDTO<unknown>>;
  fetchDetail(param?: CustomerDetailParam): Promise<ResponseDTO<ICustomer>>;
  fetchDetailCustomerDeposit(param?: CustomerDetailParam): Promise<ResponseDTO<ICustomerResponse>>;
  export(): Promise<boolean>;
  import(body: FormData): Promise<ResponseDTO<ICustomer[]>>;

  get optionList(): { label: string; value: number }[];
}

export class CustomerStore implements ICustomerStore {
  list: ICustomer[] = [];

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {});
  }

  public async fetchList(request: ICustomerRequest): Promise<ICustomerResponse> {
    const requestDTO = new CustomerDTO(request);
    const result: ResponseDTO<ICustomerResponse> = await this.http.request(requestDTO);
    runInAction(() => {
      this.list = result.data?.paginatedResults as ICustomer[];
    });
    return result?.data as ICustomerResponse;
  }

  public async search(
    request: ICustomerRequest
  ): Promise<IPaginationResponse<ICustomer> | undefined> {
    const requestDTO = new CustomerDTO(request);
    const result: ResponseDTO<IPaginationResponse<ICustomer>> = await this.http.request(requestDTO);
    return result?.data as IPaginationResponse<ICustomer>;
  }

  public async fetchCreate(body: CustomerBody): Promise<ResponseDTO<unknown>> {
    const requestDTO = new CustomerCreateDTO(body);
    return await this.http.request(requestDTO);
  }

  public async fetchUpdate(
    param: CustomerUpdatelParam,
    body: CustomerBody
  ): Promise<ResponseDTO<unknown>> {
    const requestDTO = new CustomerUpdateDTO(param, body);
    return await this.http.request(requestDTO);
  }

  public async fetchDetail(param: CustomerDetailParam): Promise<ResponseDTO<ICustomer>> {
    const requestDTO = new CustomerDetailDTO(param);
    return await this.http.request(requestDTO);
  }

  public async fetchDetailCustomerDeposit(
    param: CustomerDetailParam
  ): Promise<ResponseDTO<ICustomerResponse>> {
    const requestDTO = new CustomerDepositDetailDTO(param);
    return await this.http.request(requestDTO);
  }

  public async export(): Promise<boolean> {
    const exportCustomersDTO = new ExportCustomersDTO();
    return this.http.downloadFile(
      exportCustomersDTO.interpolatedUrl(),
      undefined,
      exportCustomersDTO.method
    );
  }

  public async import(body: FormData): Promise<ResponseDTO<ICustomer[]>> {
    const importCustomersDTO = new ImportCustomersDTO(body);
    const res: ResponseDTO<ICustomer[]> = await this.http.request(importCustomersDTO);
    return res;
  }

  get optionList(): { label: string; value: number }[] {
    return this.list.map((item: ICustomer) => ({
      label: `${item.phone} - ${item.fullName}`,
      value: item.id
    }));
  }
}
