import { Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PermissionType } from 'src/constants/permission';
import { PAGE_ROUTE } from 'src/constants/route';
import useMenuItem from 'src/hooks/use-menu-item';
import useService from 'src/hooks/use-service';
import { IAuthenticationService } from 'src/services/authentication.service';
import styles from './menu.module.less';
interface IMenuItem {
  key: string;
  requiredPermission?: Array<PermissionType>;
  children?: IMenuItem[];
}

interface Props {
  collapsed?: boolean;
}

const AppMenu: React.FC<Props> = ({ collapsed }: Props) => {
  const defaultMenu = useMenuItem();

  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

  const location = useLocation();
  //store
  const authService: IAuthenticationService = useService('authenticationService');

  const listMyPermission = authService.listMyPermission;
  // set active menu
  const getSelectedKeys = (pathname: string, menu: IMenuItem[]): string[] => {
    const selectedKeys: string[] = [];

    const processItem = (item: IMenuItem) => {
      if (pathname.includes(item?.key) && item.key !== '/') {
        selectedKeys.push(item?.key as never);
      }

      if (item.children && item?.children?.length > 0) {
        item.children.forEach((x) => {
          if (`/${pathname.split('/')[1]}` === x.key) {
            selectedKeys.push(x?.key as never);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            x.children && processChildren(x.children);
          }
        });
      }
    };

    const processChildren = (children: IMenuItem[]) => {
      children.forEach((child) => {
        processItem(child);
      });
    };

    if (pathname === PAGE_ROUTE.DASHBOARD) {
      selectedKeys.push(PAGE_ROUTE.DASHBOARD as never);
    } else {
      menu?.forEach((item: IMenuItem) => {
        processItem(item);
        processChildren(item.children ?? []);
      });
    }

    return selectedKeys;
  };

  useEffect(() => {
    const appendChild = document.getElementsByClassName('ant-menu-item-icon');
    if (collapsed) {
      Array(appendChild.length)
        .fill(null)
        .forEach((_, index) => {
          appendChild[index].setAttribute('style', 'transform: translate(-5px, 5px);');
        });
    } else {
      Array(appendChild.length)
        .fill(null)
        .forEach((_, index) => {
          appendChild[index].removeAttribute('style');
        });
    }

    return () =>
      Array(appendChild.length)
        .fill(null)
        .forEach((_, index) => {
          appendChild[index].removeAttribute('style');
        });
  }, [collapsed, getSelectedKeys(location.pathname, menuItems as IMenuItem[])]);

  const recursion = (item: IMenuItem, menu: IMenuItem[]) => {
    if (!item.children) {
      if (item.requiredPermission?.length) {
        const isShowMenu = item.requiredPermission.some((permission) =>
          listMyPermission.includes(permission)
        );
        delete item.requiredPermission;
        isShowMenu && menu.push(item);
      } else {
        delete item.requiredPermission;
        menu.push(item);
      }
      return menu;
    } else {
      const menuChildren = item.children?.reduce(
        (menuChildren: Array<IMenuItem>, itemChildren: IMenuItem) => {
          return recursion(itemChildren, menuChildren);
        },
        [] as IMenuItem[]
      );
      if (menuChildren?.length) {
        item.children = menuChildren;
        delete item.requiredPermission;
        menu.push(item);
      }
      return menu;
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const allMenus = defaultMenu?.reduce((menu, item): IMenuItem[] => {
        return recursion(item as IMenuItem, menu);
      }, [] as IMenuItem[]);

      setMenuItems(allMenus);
    }, 1000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [listMyPermission, defaultMenu]);

  return (
    <div className={styles.menu}>
      <Menu
        items={menuItems}
        mode="inline"
        defaultActiveFirst
        style={{ whiteSpace: 'normal', height: '90%' }}
        selectedKeys={getSelectedKeys(location.pathname, menuItems as IMenuItem[])}
        className={`${styles.menu_wrapper} ${collapsed && styles.menu_collapsed}`}
        defaultOpenKeys={[]}
      />
    </div>
  );
};

export default observer(AppMenu);
