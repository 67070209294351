import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from '../base.dto';

export class WorkSchedulesCheckInOutBody {
  startTime?: string;
  endTime?: string;
  employeeId!: number;
  branchId!: number;
  shiftId!: number;

  constructor(employeeId: number, branchId: number, shiftId: number) {
    this.employeeId = employeeId;
    this.branchId = branchId;
    this.shiftId = shiftId;
  }
}

export class WorkSchedulesDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: WorkSchedulesCheckInOutBody;
  public url: string = ENDPOINT.WORK_SCHEDULES;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: WorkSchedulesCheckInOutBody) {
    super();
    this.body = body;
  }
}
