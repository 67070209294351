import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateDepositCatsDTO,
  GetListDepositCatsDTO,
  IDepositCatsBody,
  DetailDepositCatsDTO,
  UpdateDepositCatsDTO,
  DeleteDepositCatDTO
} from 'src/dto/master-data/deposit-cats.dto';
import { IDepositCatsItem } from 'src/interfaces/master-data/deposit-cats';
import { IHttpService } from 'src/services/http.service';

export interface IDepositCatsStore {
  listDepositCats: IDepositCatsItem[];
  fetchList(): Promise<void>;
  create(body: IDepositCatsBody): Promise<ResponseDTO<IDepositCatsItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IDepositCatsItem>>;
  update(body: IDepositCatsBody, param: { id: string }): Promise<ResponseDTO<IDepositCatsItem>>;
  destroyStoreWhenLogout(): void;
  delete(param: { id: string }): Promise<ResponseDTO<IDepositCatsItem>>;
}

export class DepositCatsStore implements IDepositCatsStore {
  listDepositCats: IDepositCatsItem[] = [];

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listDepositCats: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(): Promise<void> {
    const requestDTO = new GetListDepositCatsDTO();
    const result: ResponseDTO<IDepositCatsItem[]> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listDepositCats = result.data ?? [];
      });
    }
  }

  public async create(body: IDepositCatsBody) {
    const createDTO = new CreateDepositCatsDTO(body);
    const res: ResponseDTO<IDepositCatsItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailDepositCatsDTO(query);
    const res: ResponseDTO<IDepositCatsItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IDepositCatsBody, param: { id: string }) {
    const updateStoreDTO = new UpdateDepositCatsDTO(body, param);
    const res: ResponseDTO<IDepositCatsItem> = await this.http.request(updateStoreDTO);
    this.fetchList();
    return res;
  }

  public async delete(param: { id: string }) {
    const deleteStoreDTO = new DeleteDepositCatDTO(param);
    const res: ResponseDTO<IDepositCatsItem> = await this.http.request(deleteStoreDTO);
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listDepositCats = [];
    });
  }
}
