const rbac = {
  resource: {
    appointment: 'Booking',
    pos: 'POS',
    report: 'Report',
    employee: 'Employee',
    customer: 'Customer',
    service: 'Service',
    product: 'Product',
    room: 'Room',
    promotionCampaign: 'Promotion Campaign'
  },

  resourceAndPermission: {
    appointment: {
      READ_ONLY: 'VIEW',
      CRUD: 'VIEW/ADD/UPDATE'
    },

    POS: {
      ROOM_BED_READ: 'ROOM/BED: VIEW',
      INVOICE_READ_ONLY: 'INVOICE: VIEW',
      INVOICE_CRUD: 'INVOICE: VIEW/ADD/UPDATE'
    },

    report: {
      THERAPIST_READ: 'THERAPIST: VIEW',
      REVENUE_READ: 'REVENUE: VIEW',
      CUSTOMER_READ: 'CUSTOMER: VIEW',
      PRODUCT_READ: 'PRODUCT: VIEW',
      GIFT_VOUCHER_READ: 'GIFT_VOUCHER: VIEW',
      ROOM_BED_PERFORMANCE_READ: 'ROOM_BED_PERFORMANCE: VIEW',
      PROMOTION_CAMPAIGN_READ: 'PROMOTION_CAMPAIGN: VIEW'
    },

    employee: {
      THERAPIST_PRIORITY_READ: 'THERAPIST_PRIORITY: VIEW',
      WORK_SCHEDULE_READ_ONLY: 'WORK_SCHEDULE: VIEW',
      WORK_SCHEDULE_CRUD: 'WORK_SCHEDULE: VIEW/UPDATE/IMPORT/EXPORT',
      EMPLOYEE_READ_ONLY: 'EMPLOYEE: VIEW',
      EMPLOYEE_CRUD: 'EMPLOYEE: VIEW/ADD/UPDATE'
    },

    customer: {
      READ: 'VIEW',
      CRUD: 'VIEW/ADD/UPDATE'
    },

    service: {
      READ: 'VIEW',
      CRUD: 'VIEW/ADD/UPDATE'
    },

    product: {
      INVENTORY_READ_ONLY: 'INVENTORY: VIEW',
      INVENTORY_IMPORT_EXPORT: 'INVENTORY: IMPORT/EXPORT',
      IMPORT_EXPORT_HISTORY: 'IMPORT/EXPORT HISTORY: VIEW'
    },

    room: {
      READ_ONLY: 'VIEW',
      CRUD: 'VIEW/ADD/UPDATE'
    },

    promotionCampaign: {
      READ_ONLY: 'VIEW',
      CRUD: 'VIEW/ADD/UPDATE'
    }
  },

  msgIndicator: {
    PoLP: 'Required Permissions (PoLP): These permissions must remain enabled and cannot be unchecked for this role.',
    PBAC: 'Unavailable Permissions (PBAC): These permissions cannot be assigned to this role.'
  },

  validationRules: {
    'role-existed': 'Role Name has already existed in the system. Please enter another Role Name.'
  }
};

export default rbac;
