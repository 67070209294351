import { IHttpService } from './http.service';
import { ILoginResponse, LogInDTO, LogoutDTO } from 'src/dto/authentication.dto';
import { ResponseDTO } from 'src/dto/base.dto';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  USER_ACCESS_TOKEN,
  USER_LANGUEGE,
  USER_REFRESH_TOKEN,
  USER_REMEMBER_ME
} from 'src/constants/app';
import { IPermission, IPermisstionItem } from 'src/dto/administration/user-permission.dto';
import { PermissionType } from 'src/constants/permission';
import { LANGUAGE } from 'src/constants/language';

export interface IAuthenticationService {
  isAuthenticated: boolean;
  listMyPermission: PermissionType[];
  login(form: LogInDTO): Promise<ResponseDTO<ILoginResponse>>;
  logout(): Promise<boolean>;
  setListPermission(listPermission: IPermission[]): void;
  language: LANGUAGE;
}

export class AuthenticationService implements IAuthenticationService {
  @observable
  isAuthenticated = false;
  @observable
  listMyPermission: PermissionType[];
  @observable
  language = LANGUAGE.EN;
  constructor(private readonly httpService: IHttpService) {
    const localToken =
      localStorage.getItem(USER_ACCESS_TOKEN) ?? sessionStorage.getItem(USER_ACCESS_TOKEN);

    this.isAuthenticated = !!localToken;
    this.listMyPermission = [];
    this.language = (localStorage.getItem(USER_LANGUEGE) as LANGUAGE) ?? LANGUAGE.EN;
    makeObservable(this);
  }

  @action.bound
  setAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  setListPermission(listPermission: IPermisstionItem[]) {
    this.listMyPermission = listPermission.map((item) => item.name);
  }

  public async login(form: LogInDTO): Promise<ResponseDTO<ILoginResponse>> {
    const result: ResponseDTO<ILoginResponse> = await this.httpService.request<
      LogInDTO,
      ILoginResponse
    >(form);
    if (result.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS && result.data) {
      runInAction(() => {
        this.isAuthenticated = true;
      });

      if (form.body.rememberMe) {
        this.httpService.setRememberMe(true);
        this.httpService.setToken(result.data.accessToken, result.data.refreshToken);
      } else {
        this.httpService.setRememberMe(false);
        this.httpService.setSession(result.data.accessToken, result.data.refreshToken);
        this.httpService.setToken('', result.data.refreshToken);
      }
      localStorage.setItem(USER_LANGUEGE, this.language);
    }

    return result;
  }

  public async logout(): Promise<boolean> {
    const dto = new LogoutDTO();
    try {
      const result: ResponseDTO<boolean> = await this.httpService.request(dto);
      this.httpService.setToken(undefined, undefined);
      this.httpService.setSession(undefined, undefined);
      return !!result.data;
    } catch (error) {
      throw Error;
    } finally {
      runInAction(() => {
        this.isAuthenticated = false;
        localStorage.removeItem(USER_REMEMBER_ME);
        localStorage.removeItem(USER_ACCESS_TOKEN);
        localStorage.removeItem(USER_REFRESH_TOKEN);
        sessionStorage.removeItem(USER_ACCESS_TOKEN);
        sessionStorage.removeItem(USER_REFRESH_TOKEN);
      });
    }
  }
}
