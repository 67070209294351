const account = {
  title: {
    welcomeBack: 'Welcome back',
    loginSystem: 'Đăng nhập hệ thống',
    forgotPassword: {
      text: 'Quên mật khẩu',
      describe: 'Vui lòng nhập mã nhân viên của bạn để nhận link khôi phục lại mật khẩu.',
      hasSentTheRecoveryLink: 'Đã gửi link khôi phục!',
      notificationLinkSentTo: 'Một đường link đã được gửi đến {{email}}',
      pleaseCheckYouEmail: 'Vui lòng kiểm tra email của bạn.',
      sendMailFailed: 'Không thể gửi email.'
    },
    resetPassword: {
      text: 'Đặt lại mật khẩu',
      describe: 'Vui lòng nhập mật khẩu mới',
      successfulReset: 'Đặt lại thành công',
      successfulResetDescribe: 'Vui lòng đăng nhập lại bằng mật khẩu mới của bạn.',
      resetPasswordFailed: 'Không thể đặt lại mật khẩu.'
    }
  },

  notes: {
    sendMail:
      'Trong trường hợp nhân viên chưa cài đặt email cho tài khoản, link khôi phục mật khẩu sẽ được gửi đến email của Super Admin.'
  },

  validationRules: {
    invalidCredentials: 'Sai Mã Nhân Viên hoặc Mật Khẩu',
    emailNotFound: 'Không tìm thấy địa chỉ email.',
    imgFileExtension: 'Vui lòng chỉ tải lên các tệp jpeg, png hoặc jpg!',
    maxFileSize: 'Kích thước tệp quá lớn. Vui lòng tải lên hình ảnh không lớn hơn {{maxSize}}MB!'
  }
};

export default account;
