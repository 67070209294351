const groupService = {
  manageServiceGroup: 'Service Group Management',
  searchPlaceholder: 'Search by Service Group',
  createServiceGroup: 'Create New Service Group',
  updateServiceGroup: 'Update Service Group',

  form: {
    serviceGroupName: 'Service Group Name'
  }
};

export default groupService;
