const ProductCats = {
  title: 'Danh sách Nhóm sản phẩm',
  form: {
    titleCreate: 'Thêm mới Nhóm sản phẩm',
    titleUpdate: 'Chỉnh sửa Nhóm sản phẩm',
    name: 'Tên nhóm sản phẩm'
  },
  productCatsNameAlreadyExists: 'Đã tồn tại tên nhóm sản phẩm!',
  placeholder: {
    search: 'Tìm kiếm theo nhóm sản phẩm'
  }
};

export default ProductCats;
