const ProductCats = {
  title: 'Product Group Management',
  form: {
    titleCreate: 'Add New Product Group',
    titleUpdate: 'Update Product Group',
    name: 'Product Group Name'
  },
  productCatsNameAlreadyExists: 'Product group name already exists!',
  placeholder: {
    search: 'Search by Product Group'
  }
};

export default ProductCats;
