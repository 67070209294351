const groupService = {
  manageServiceGroup: 'Danh sách Nhóm dịch vụ',
  searchPlaceholder: 'Tìm kiếm theo Nhóm Dịch vụ',
  createServiceGroup: 'Thêm mới Nhóm dịch vụ',
  updateServiceGroup: 'Chỉnh sửa Nhóm dịch vụ',

  form: {
    serviceGroupName: 'Tên nhóm dịch vụ'
  }
};

export default groupService;
