import { IRoomCatsBody, IRoomCatsRequest } from 'src/interfaces/master-data/room-cats';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListRoomCatsDTO extends DTO {
  public param: object | undefined;
  public query: IRoomCatsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.ROOM_CATS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IRoomCatsRequest) {
    super();
    this.query = query;
  }
}

export class CreateRoomCatsDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IRoomCatsBody;
  public url: string = ENDPOINT.ROOM_CATS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IRoomCatsBody) {
    super();
    this.body = body;
  }
}

export class DetailRoomCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.ROOM_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateRoomCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IRoomCatsBody;
  public url: string = ENDPOINT.ROOM_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IRoomCatsBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DeleteRoomCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: unknown;
  public url: string = ENDPOINT.ROOM_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
