import { messageResponse } from 'src/constants/message-response';

const GiftVoucher = {
  managementGiftVoucher: 'Quản lý Gift voucher',
  createGiftVoucher: 'Thêm mới Gift voucher',
  updateGiftVoucher: 'Chỉnh sửa Gift voucher',

  table: {
    promotionalValue: 'Giá trị KM',
  },

  form: {
    giftVoucherCats: 'Loại thẻ',
    includeServiceExtraFee: 'Bao gồm phí dịch vụ',
    numberOfGiftVoucher: 'Số lượng thẻ',
  },

  placeholder: {
    search: 'Tìm kiếm theo Ký hiệu',
    giftVoucherCatInput: 'Chọn loại thẻ',
  },

  details: {
    title: 'Chi tiết Gift Voucher',
  },

  errMsg: {
    [messageResponse.prefixCodesExists]: 'Ký hiệu đã tồn tại trong hệ thống'
  }
};

export default GiftVoucher;
