import { Gender, Weekday } from 'src/constants/promotion';

const promotion = {
  code: 'Mã chiến dịch',
  name: 'Tên chiến dịch',
  nameDiscount: 'Tên chiến dịch giảm giá',
  applicableObject: 'Đối tượng áp dụng',
  customersApply: 'Khách hàng áp dụng',
  conditionsApply: 'Điều kiện áp dụng',
  promotionType: 'Loại khuyến mãi',
  minimumQuantity: 'Số lượng mặt hàng tối thiểu',
  maximumQuantity: 'Số lượng mặt hàng tối đa',
  minServicesInInvoice: 'Số lượng dịch vụ tối thiểu',
  minCustomerCount: 'Số lượng khách tối thiểu',
  minimumBill: 'Giá trị bill tối thiểu',
  discount: 'Khuyến mãi',
  isActive: 'Kích hoạt',
  [Gender.FEMALE]: 'Nữ',
  [Gender.MALE]: 'Nam',
  weekdays: {
    [Weekday.MONDAY]: 'Thứ 2',
    [Weekday.TUESDAY]: 'Thứ 3',
    [Weekday.WEDNESDAY]: 'Thứ 4',
    [Weekday.THURSDAY]: 'Thứ 5',
    [Weekday.FRIDAY]: 'Thứ 6',
    [Weekday.SATURDAY]: 'Thứ 7',
    [Weekday.SUNDAY]: 'Chủ nhật'
  },

  placeholder: {
    startDate: 'Chọn ngày bắt đầu',
    endDate: 'Chọn ngày kết thúc',
    startTime: 'Chọn giờ bắt đầu',
    endTime: 'Chọn giờ kết thúc',
    applicableObject: 'Chọn đối tượng',
    membership: 'Chọn hạng thành viên',
    search: 'Tìm kiếm theo Tên chiến dịch'
  },

  criteria: {
    hasServiceCharge: 'Đã bao gồm Service Charge?',
    usedOnWeekends: 'Được sử dụng vào cuối tuần?',
    usedOnHolidays: 'Được sử dụng vào ngày lễ?',
    date: 'Ngày áp dụng',
    time: 'Thời gian áp dụng',
    hours: 'Giờ áp dụng'
  },

  edit: {
    title: 'Cập nhật Chiến dịch giảm giá'
  }
};

export default promotion;
