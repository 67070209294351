const CustomerCats = {
  title: 'Customer Group Management',
  form: {
    titleCreate: 'Create Customer Group',
    titleUpdate: 'Update Customer Group'
  },
  customerCatsNameAlreadyExists: 'Customer group name already exists!',
  placeholder: {
    search: 'Search by Customer Group'
  }
};

export default CustomerCats;
