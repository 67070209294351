const RoomCats = {
  title: 'Quản lý Loại phòng',

  table: {
    search: 'Tìm kiếm theo Loại phòng'
  },

  form: {
    titleCreate: 'Thêm mới Loại phòng',
    titleUpdate: 'Chỉnh sửa Loại phòng',
    name: 'Tên loại phòng',
  },

  roomCatsNameAlreadyExists: 'Tên loại phòng đã tồn tại trong hệ thống!'
};

export default RoomCats;
