const Customer = {
  title: 'Danh sách Khách hàng',
  allergies: 'Thông tin dị ứng',
  is_pregnant: 'Đang mang thai',

  label: {
    date: 'Thời gian',
    newCustomerGroup: 'KH mới'
  },

  create: {
    title: 'Thêm mới Khách hàng'
  },

  errMsg: {
    existingPhoneNo: 'SĐT đã tồn tại trong hệ thống!'
  },

  placeholder: {
    categoryId: 'Chọn nhóm khách hàng',
    search: 'Tìm kiếm theo Tên, Email, Số điện thoại'
  },
  edit: {
    title: 'Cập nhật thông tin Khách hàng'
  },

  detail: {
    title: 'Chi tiết Khách hàng',
    depositSpending: 'Chi tiêu tích lũy',
    membershipChangedAt: 'Ngày vào hạng'
  }
};

export default Customer;
