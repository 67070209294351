import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
export interface IProductsRequest {
  keyword?: string;
  status?: string;
  catId?: number;
  page?: number;
  limit?: number;
  isActive?: boolean;
}

export interface IProductsBody {
  name: string;
  code: string;
  catId: number;
  exitPrice: number;
  isActive?: boolean;
  containerId: number;
}

export type IProductUpdateBody = Partial<IProductsBody>;

export class GetListProductsDTO extends DTO {
  public param: object | undefined;
  public query: IProductsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.PRODUCT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IProductsRequest) {
    super();
    this.query = query;
  }
}

export class CreateProductsDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IProductsBody;
  public url: string = ENDPOINT.PRODUCT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IProductsBody) {
    super();
    this.body = body;
  }
}

export class DetailProductsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.PRODUCT_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateProductsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IProductUpdateBody;
  public url: string = ENDPOINT.PRODUCT_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IProductUpdateBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class ExportProductsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.PRODUCT_EXPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
}

export class ImportProductsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.PRODUCT_IMPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}
