import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CalculateOrderDTO,
  CreateOrderDTO,
  DetailOrderDTO,
  GetExistAppoinmentInvoicesDTO,
  GetListOrderDTO,
  UpdateOrderDTO
} from 'src/dto/master-data/order.dto';
import { IPaginationResponse } from 'src/interfaces';
import {
  IOrder,
  IOrderBody,
  IOrderCalculateResponse,
  IOrderRequest
} from 'src/interfaces/master-data/order';

import { IHttpService } from 'src/services/http.service';

export interface IOrderStore {
  listOrder: IOrder[];
  fetched: boolean;
  pagination: {
    page: number;
    limit: number;
    current: number;
    total: number;
  };
  params?: IOrderRequest;
  fetchList(request?: IOrderRequest): Promise<void>;
  calculateOrder(request?: IOrderBody): Promise<ResponseDTO<IOrderCalculateResponse>>;
  create(body: IOrderBody): Promise<ResponseDTO<IOrder>>;
  getDetail(param: { id: number }): Promise<ResponseDTO<IOrder & IOrderBody>>;
  getExistAppointmentInvoices(): Promise<ResponseDTO<number[]>>;
  update(body: IOrderBody, param: { id: number }): Promise<ResponseDTO<IOrder>>;
  destroyStoreWhenLogout(): void;
}

export class OrderStore implements IOrderStore {
  listOrder: IOrder[] = [];
  fetched = false;
  pagination = {
    page: PAGINATION_CONFIGURATION.DEFAULT_PAGE,
    limit: PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE,
    current: 1,
    total: 0
  };
  params?: IOrderRequest | undefined;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listOrder: observable.ref,
      pagination: observable.ref,
      fetched: observable.ref,
      fetchList: action.bound
    });
  }
  order(request?: IOrderBody): Promise<ResponseDTO<IOrder>> {
    throw new Error('Method not implemented.');
  }

  public async fetchList(request?: IOrderRequest): Promise<void> {
    this.params = request;
    const requestDTO = new GetListOrderDTO(request);
    const result: ResponseDTO<IPaginationResponse<IOrder>> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listOrder = result.data?.paginatedResults ?? [];
        this.pagination = {
          limit: result.data?.limit || this.pagination.limit,
          page: result.data?.page || this.pagination.page,
          current: result.data?.current || 0,
          total: result.data?.total || 0
        };
      });
    }
  }

  public async create(body: IOrderBody) {
    const createDTO = new CreateOrderDTO(body);
    const res: ResponseDTO<IOrder> = await this.http.request(createDTO);
    return res;
  }

  public async calculateOrder(body: IOrderBody) {
    const calculateOrderDTO = new CalculateOrderDTO(body);
    const res: ResponseDTO<IOrderCalculateResponse> = await this.http.request(calculateOrderDTO);
    return res;
  }

  public async getDetail(query: { id: number }) {
    const detailDTO = new DetailOrderDTO(query);
    const res: ResponseDTO<IOrder & IOrderBody> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IOrderBody, param: { id: number }) {
    const updateStoreDTO = new UpdateOrderDTO(body, param);
    const res: ResponseDTO<IOrder> = await this.http.request(updateStoreDTO);
    return res;
  }
  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listOrder = [];
    });
  }

  public async getExistAppointmentInvoices(): Promise<ResponseDTO<number[]>> {
    const requestDTO = new GetExistAppoinmentInvoicesDTO();
    const res: ResponseDTO<number[]> = await this.http.request(requestDTO);
    return res;
  }
}
