import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import { INotificationType } from '../notification-type/notification-type.dto';
import { NOTIFICATION_FILTER_TYPE } from 'src/constants/notification';

export class INotificationRequest {
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  state?: NOTIFICATION_FILTER_TYPE;
}

export class INotification {
  id!: number;
  customerId!: number;
  employeeId!: number;
  content!: string;
  refLink!: string;
  readAt!: Date | string;
  type!: INotificationType;
  createdAt!: string;
}

export class INotificationResponse {
  paginatedResults!: INotification[];
  current!: number;
  page!: number;
  limit!: number;
  total!: number;
}

export class ListNotificationDTO extends DTO {
  public param: undefined;
  public query: INotificationRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.NOTIFICATIONS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: INotificationRequest) {
    super();
    this.query = query;
  }
}

export class ReadNotificationByIdDTO extends DTO {
  public param: { id: number } | undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.READ_NOTIFICATIONS_BY_ID;
  public method: HTTP_METHOD = HTTP_METHOD.PATCH;
  public readonly responseType: ResponseType = 'json';
  constructor(param?: { id: number }) {
    super();
    this.param = param;
  }
}

export class ReadAllNotificationDTO extends DTO {
  public param: { id: number } | undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.READ_ALL_NOTIFICATIONS;
  public method: HTTP_METHOD = HTTP_METHOD.PATCH;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
  }
}

export class GetCountNotificationDTO extends DTO {
  public param: undefined;
  public query: INotificationRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.COUNT_NOTIFICATIONS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: INotificationRequest) {
    super();
    this.query = query;
  }
}
