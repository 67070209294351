import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export interface IDepositCatsBody {
  minAmount: number;
  maxAmount: number;
  discountValue: number;
}

export class GetListDepositCatsDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DEPOSIT_CATS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
}

export class CreateDepositCatsDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IDepositCatsBody;
  public url: string = ENDPOINT.DEPOSIT_CATS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IDepositCatsBody) {
    super();
    this.body = body;
  }
}

export class DetailDepositCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DEPOSIT_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateDepositCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IDepositCatsBody;
  public url: string = ENDPOINT.DEPOSIT_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IDepositCatsBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DeleteDepositCatDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: unknown;
  public url: string = ENDPOINT.DEPOSIT_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
