import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateProductCatsDTO,
  DeleteProductCatsDTO,
  DetailProductCatsDTO,
  GetListProductCatsDTO,
  UpdateProductCatsDTO
} from 'src/dto/master-data/product-cats.dto';
import {
  IProductCatsBody,
  IProductCatsItem,
  IProductCatsRequest
} from 'src/interfaces/master-data/product-cats';
import { IHttpService } from 'src/services/http.service';

export interface IProductCatsStore {
  listProductCats: IProductCatsItem[];
  fetched: boolean;
  requestParams?: IProductCatsRequest;
  fetchList(request?: IProductCatsRequest): Promise<void>;
  create(body: IProductCatsBody): Promise<ResponseDTO<IProductCatsItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IProductCatsItem>>;
  update(body: IProductCatsBody, param: { id: string }): Promise<ResponseDTO<IProductCatsItem>>;
  delete(param: { id: string }): Promise<ResponseDTO<IProductCatsItem>>;
  destroyStoreWhenLogout(): void;
}

export class ProductCatsStore implements IProductCatsStore {
  listProductCats: IProductCatsItem[] = [];
  fetched = false;
  requestParams?: IProductCatsRequest;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      fetchList: action.bound,
      listProductCats: observable.ref,
      fetched: observable.ref
    });
  }

  public async fetchList(request?: IProductCatsRequest): Promise<void> {
    runInAction(() => {
      this.requestParams = request;
    });
    const requestDTO = new GetListProductCatsDTO(request);
    const result: ResponseDTO<IProductCatsItem[]> = await this.http.request(requestDTO);
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listProductCats = result.data ?? [];
        this.fetched = true;
      });
    }
  }

  public async create(body: IProductCatsBody) {
    const createDTO = new CreateProductCatsDTO(body);
    const res: ResponseDTO<IProductCatsItem> = await this.http.request(createDTO);
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailProductCatsDTO(query);
    const res: ResponseDTO<IProductCatsItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IProductCatsBody, param: { id: string }) {
    const updateStoreDTO = new UpdateProductCatsDTO(body, param);
    const res: ResponseDTO<IProductCatsItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async delete(param: { id: string }) {
    const deleteStoreDTO = new DeleteProductCatsDTO(param);
    const res: ResponseDTO<IProductCatsItem> = await this.http.request(deleteStoreDTO);
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listProductCats = [];
    });
  }
}
