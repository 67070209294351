/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import axios, { AxiosError, AxiosResponse, CancelToken, InternalAxiosRequestConfig } from 'axios';
import { makeAutoObservable, observable } from 'mobx';
import { ENDPOINT, HTTP_METHOD, HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { API_STATUS } from 'src/constants/api-status';
import { USER_ACCESS_TOKEN, USER_REFRESH_TOKEN, USER_REMEMBER_ME } from 'src/constants/app';
import { messageResponse } from 'src/constants/message-response';
import { PAGE_ROUTE } from 'src/constants/route';
import { DTO, ResponseDTO } from 'src/dto/base.dto';
import { API_HOST } from 'src/environments/environment';
import i18n, { i18nKey } from 'src/locales/i18n';
import eventEmitter from 'src/store/event';

const controller = new AbortController();
export interface IHttpService {
  setToken(token?: string, refreshToken?: string): void;
  setSession(token?: string, refreshToken?: string): void;
  setRememberMe(isRememberMe: boolean): void;
  request<reqT extends DTO, resT>(dto: reqT): Promise<ResponseDTO<resT>>;
  uploadFile<resT>(url: string, form: FormData, singer: any): Promise<ResponseDTO<resT>>;
  downloadFile(
    url: string,
    headers?: object | undefined,
    method?: HTTP_METHOD,
    meta?: { name: string },
    cancelToken?: CancelToken
  ): Promise<boolean | any>;
  progress?: number;
  getToken(): string;
  dispose(): void;
}

export const cleanUpStorage = () => {
  localStorage.removeItem(USER_ACCESS_TOKEN);
  localStorage.removeItem(USER_REFRESH_TOKEN);
  sessionStorage.removeItem(USER_ACCESS_TOKEN);
  sessionStorage.removeItem(USER_REFRESH_TOKEN);
  localStorage.removeItem(USER_REMEMBER_ME);
};

export class HttpService implements IHttpService {
  private token: string | null;
  private refresh_token: string | null;
  private remember_me: boolean;
  progress?: number;
  refreshingFunc?: any;

  constructor() {
    this.refreshingFunc = undefined;
    this.remember_me =
      JSON.parse(localStorage.getItem(USER_REMEMBER_ME) as any)?.isRememberMe ?? false;
    this.token = this.remember_me
      ? localStorage.getItem(USER_ACCESS_TOKEN)
      : sessionStorage.getItem(USER_ACCESS_TOKEN);
    this.refresh_token = this.remember_me
      ? localStorage.getItem(USER_REFRESH_TOKEN)
      : sessionStorage.getItem(USER_REFRESH_TOKEN);
    axios.defaults.baseURL = API_HOST + ENDPOINT.ROOT;
    axios.defaults.validateStatus = (status: number) => status !== 401;
    axios.interceptors.response.use(this.onResponse, this.onResponseError);
    makeAutoObservable(this, {
      progress: observable.ref
    });
  }

  private isUnauthorizedError(error: AxiosError) {
    return error.response?.status === 401;
  }

  private handleErrorNotFound(convertedResponse: {
    responseCode: HTTP_STATUS_RESPONSE_KEY;
    message: string;
  }) {
    if (convertedResponse.responseCode === HTTP_STATUS_RESPONSE_KEY.NOT_FOUND) {
      if (convertedResponse.message === messageResponse.messageUserNotFound) {
        message.error(i18n.t(`${i18nKey.account.validationRules.emailNotFound}`));
      }
      if (convertedResponse.message === messageResponse.invalidEmailOrPassword) {
        message.error(i18n.t(`${i18nKey.account.validationRules.invalidCredentials}`));
      }
    }
  }

  private handleErrorBadRequest(convertedResponse: {
    responseCode: HTTP_STATUS_RESPONSE_KEY;
    message: string;
  }) {
    if (convertedResponse.responseCode === HTTP_STATUS_RESPONSE_KEY.BAD_REQUEST) {
      if (convertedResponse.message === messageResponse.invalidEmailOrPassword) {
        message.error(i18n.t(`${i18nKey.account.validationRules.invalidCredentials}`));
      }

      if (convertedResponse.message === messageResponse.inactiveAccountNotification) {
        if (![`${PAGE_ROUTE.LOGIN}`].includes(window.location.pathname)) {
          eventEmitter.emit('logout');
          setTimeout(() => {
            cleanUpStorage();
            location.assign('/');
          });
        }
      }
    }
  }

  private onResponse = (response: AxiosResponse): AxiosResponse => {
    const convertedResponse = response?.data;
    if (
      convertedResponse.responseCode &&
      convertedResponse.responseCode !== HTTP_STATUS_RESPONSE_KEY.SUCCESS
    ) {
      this.handleErrorNotFound(convertedResponse);

      if (convertedResponse.responseCode === HTTP_STATUS_RESPONSE_KEY.FORBIDDEN) {
        eventEmitter.emit('forbidden');
        message.error(i18n.t(`${i18nKey.httpResponseMessage._401_Unauthorized_Access_Denided}`));
      }
      this.handleErrorBadRequest(convertedResponse);
    }
    return response;
  };

  private handleErrorMessages(error: AxiosError) {
    if (error.message === 'Network Error') {
      if (
        error.config?.url === ENDPOINT.PROFILE ||
        error.config?.url === ENDPOINT.LOGIN ||
        error.config?.url === ENDPOINT.LOGOUT
      ) {
        message.error(i18n.t(`${i18nKey.commonMsg.networkErr}`));
      }
    }

    if (error.message === 'Cancel Upload') {
      message.error('Cancel Upload');
    }
  }

  private onResponseError = async (error: AxiosError): Promise<AxiosError> => {
    if (!this.token || !this.isUnauthorizedError(error)) {
      this.handleErrorMessages(error);
      return Promise.reject(error.message);
    }

    const originalConfig: InternalAxiosRequestConfig<any> | undefined = error.config;

    try {
      if (!this.refreshingFunc) {
        this.refreshingFunc = this.refreshToken();
      }

      await this.refreshingFunc;

      if (originalConfig) {
        // Retry the original request
        originalConfig.headers.Authorization = `Bearer ${this.token}`;

        try {
          return await axios.request(originalConfig);
        } catch (innerError: any) {
          // If the original request failed with 401 again, it means the server returned an invalid token for the refresh request
          if (this.isUnauthorizedError(innerError)) {
            throw innerError;
          }
        }
      }
    } catch (err) {
      console.log(err);
      cleanUpStorage();
      setTimeout(() => {
        cleanUpStorage();
        location.assign('/');
      });
    } finally {
      this.refreshingFunc = undefined;
    }

    return Promise.reject(error);
  };

  private refreshToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINT.REFRESH_TOKEN, { refreshToken: this.refresh_token })
        .then((response) => {
          const newToken = response.data.data.accessToken;
          if (this.remember_me) {
            this.setToken(newToken, this.refresh_token);
          } else {
            this.setSession(newToken, this.refresh_token);
            this.setToken('', this.refresh_token);
          }
          resolve(newToken);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public async request<reqT extends DTO, resT>(dto: reqT): Promise<ResponseDTO<resT>> {
    try {
      const response = await axios({
        method: dto.method,
        headers: {
          Authorization: this.token ? 'Bearer ' + this.token : '',
          'X-Frame-Options': 'DENY',
          'Content-Security-Policy': "frame-ancestors 'self';",
          ...dto.headers
        },
        url: this.replaceURLToParamURL(dto.url, dto.param ?? {}),
        data: dto.body,
        params: dto.query,
        responseType: dto.responseType,
        signal: controller.signal
      });
      const data = response.data || response.data.data;
      return { ...data };
    } catch (error: unknown) {
      return {
        data: undefined,
        responseCode: HTTP_STATUS_RESPONSE_KEY.UNKNOWN
      };
    }
  }

  public setToken(token: string, refreshToken?: string | null): void {
    if (this.remember_me) {
      this.token = token;
      this.refresh_token = refreshToken ?? '';
      localStorage.setItem(USER_ACCESS_TOKEN, token);
    }
    refreshToken
      ? localStorage.setItem(USER_REFRESH_TOKEN, refreshToken)
      : localStorage.setItem(USER_REFRESH_TOKEN, null as never);
  }

  public setSession(token: string, refreshToken?: string | null): void {
    this.token = token;
    this.refresh_token = refreshToken ?? '';
    sessionStorage.setItem(USER_ACCESS_TOKEN, token);
    refreshToken
      ? sessionStorage.setItem(USER_REFRESH_TOKEN, refreshToken)
      : sessionStorage.setItem(USER_REFRESH_TOKEN, null as never);
  }

  public setRememberMe(isRememberMe: boolean): void {
    this.remember_me = isRememberMe;
    localStorage.setItem(USER_REMEMBER_ME, JSON.stringify({ isRememberMe: isRememberMe }));
  }

  public async uploadFile<resT>(
    url: string,
    form: FormData,
    source: any
  ): Promise<ResponseDTO<resT>> {
    try {
      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: this.token ? 'Bearer ' + this.token : '',
          'X-Frame-Options': 'DENY',
          'Content-Security-Policy': "frame-ancestors 'self';",
          'Content-Type': 'multipart/form-data'
        },
        url: url,
        responseType: 'json',
        data: form,
        cancelToken: source,
        onUploadProgress: (event) => {
          this.progress = event.progress;
        }
      });
      const data = response.data || response.data.data;
      return { ...data };
    } catch (error: unknown) {
      return {
        data: undefined,
        responseCode: HTTP_STATUS_RESPONSE_KEY.UNKNOWN
      };
    }
  }

  public async downloadFile(
    url: string,
    headers?: object | undefined,
    method?: HTTP_METHOD,
    meta?: { name: string },
    cancelToken?: CancelToken
  ): Promise<boolean | any> {
    try {
      const response: any = await axios({
        url: url,
        headers: {
          Authorization: this.token ? 'Bearer ' + this.token : '',
          ...headers
        },
        method: method || HTTP_METHOD.GET,
        responseType: 'blob',
        cancelToken: cancelToken
      });

      if (response.status !== 201 && response.status !== 200) return false;
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = response.headers['content-disposition']?.split("filename*=UTF-8''")[1];
      link.setAttribute('download', decodeURI(fileName || meta?.name));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return true;
    } catch (error) {
      if (error === API_STATUS.CANCELLED) return error;
      return false;
    }
  }

  public getToken(): string {
    return this.token ?? '';
  }
  private replaceURLToParamURL = (url: string, param: object): string => {
    let newUrl = url;
    Object.entries(param).forEach(([key, value]) => {
      newUrl = newUrl.replace(':' + key.toString(), value.toString());
    });
    return newUrl;
  };

  public dispose(): void {
    this.progress = 0;
  }
}

const httpClient = new HttpService();

export default httpClient;
