import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { IGiftVoucherReportsRequest } from 'src/interfaces/reports/giftVoucher';
import { DTO } from '../base.dto';

export class GetListGiftVoucherReportsDTO extends DTO {
    public param: object | undefined;
    public query: IGiftVoucherReportsRequest | undefined;
    public body: undefined;
    public url: string = ENDPOINT.GIFT_VOUCHER_REPORT;
    public method: HTTP_METHOD = HTTP_METHOD.GET;
    public readonly responseType?: ResponseType = 'json';
    constructor(query?: IGiftVoucherReportsRequest) {
        super();
        this.query = query;
    }
}
