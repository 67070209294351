import {
  Col,
  Drawer,
  Dropdown,
  Layout,
  MenuProps,
  Row,
  Space,
  Typography,
  Avatar,
  Badge
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Notification from 'src/assets/icons/notification.svg';
import IconDown from 'src/assets/icons/icon-down.svg';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { PAGE_ROUTE } from 'src/constants/route';
import useService from 'src/hooks/use-service';
import useStore from 'src/hooks/use-store';
import { IUserInfo } from 'src/interfaces/user';
import { i18nKey } from 'src/locales/i18n';
import { IAuthenticationService } from 'src/services/authentication.service';
import { IUserService } from 'src/services/user.service';
import eventEmitter from 'src/store/event';
import { IUserStore } from 'src/store/user.store';
import AppMenu from './components/menu/menu';
import styles from './main.layout.module.less';
import LogoImage from 'src/assets/images/logo.png';
import ArrowLeft from 'src/assets/icons/arrow-left.svg';
import ToastifyConfirm from 'src/components/toastify-confirm/toastify-confirm';
import Notifications from 'src/pages/notifications/components/notification-popover/notifications-popover';
import { INotificationStore } from 'src/store/notification/notification.store';
import { NOTIFICATION_FILTER_TYPE } from 'src/constants/notification';
import OneSignal from 'react-onesignal';
import { ONE_SIGNAL_APP_ID } from 'src/environments/environment';
import { RoleName } from 'src/constants/permission';
import IconFlagEN from 'src/assets/icons/iconFlagEN.svg';
import IconFlagVI from 'src/assets/icons/iconFlagVI.svg';
import { LANGUAGE, LANGUAGE_ICON } from 'src/constants/language';
import { IBranchStore } from 'src/store/master-data/branch.store';
import { IConfiguration } from 'src/store/configuration.store';

const runOneSignal = async () => {
  OneSignal.init({
    appId: ONE_SIGNAL_APP_ID,
    allowLocalhostAsSecureOrigin: true,
    notifyButton: {
      enable: true
    }
  }).then(() => {
    OneSignal.Slidedown.promptPush();
  });
};

const { Sider, Content } = Layout;

const MainLayout: React.FC = () => {
  //store
  const userService: IUserService = useService('userService');
  const userStore: IUserStore = useStore('userStore');
  const authService: IAuthenticationService = useService('authenticationService');
  const notificationStore: INotificationStore = useStore('notificationStore');
  const configStore: IConfiguration = useStore('configuration');

  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [menuBar, setMenuBar] = useState(false);
  const [openToastify, setOpenToastify] = useState<boolean>(false);
  const [openNotifi, setOpenNotifi] = useState(false);
  const navigator = useNavigate();
  const branchStore: IBranchStore = useStore('branchStore');

  const contentRef: any = useRef(null);

  const handleSubmit = async () => {
    setOpenToastify(true);
  };

  const items: MenuProps['items'] = [
    {
      label: <Typography>{t(i18nKey.setting.profile)}</Typography>,
      key: '0',
      onClick: () => navigate(PAGE_ROUTE.PROFILE)
    },
    {
      label: <Typography>{t(i18nKey.setting.logout)}</Typography>,
      key: '1',
      onClick: handleSubmit
    }
  ];

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const itemsSettingLanguage: MenuProps['items'] = [
    {
      label: (
        <div className={styles.container_selectOptionLang}>
          <img src={IconFlagVI} alt="IconFlagVI" />
          <Typography>{t(i18nKey.setting.languageVI)}</Typography>
        </div>
      ),
      key: LANGUAGE.VN,
      onClick: () => handleChangeLanguage(LANGUAGE.VN)
    },
    {
      label: (
        <div className={styles.container_selectOptionLang}>
          <img src={IconFlagEN} alt="IconFlagEN" />
          <Typography>{t(i18nKey.setting.languageEN)}</Typography>
        </div>
      ),
      key: LANGUAGE.EN,
      onClick: () => handleChangeLanguage(LANGUAGE.EN)
    }
  ];

  const getProfile = async () => {
    const res = await userService.getUserProfile();

    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      setTimeout(() => {
        if (res.data?.role.name === RoleName.RECEPTIONIST) {
          res.data?.branches.map((branch: any) => {
            OneSignal.User.addTag(branch.id.toString(), 'subscribed');
          });
        }
      }, 2000);

      userStore.updateUserInfo(res.data as IUserInfo);
      await branchStore.fetchList();
    }
  };

  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });

  // handle scroll event
  const handleScroll = (elTopOffset: any, elHeight: any) => {
    if (window.pageYOffset > 50) {
      setSticky({ isSticky: true, offset: elHeight });
      configStore.changeSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
      configStore.changeSticky({ isSticky: false, offset: 0 });
    }
  };


  useEffect(() => {
    if (authService.isAuthenticated) {
      getProfile();
    }
  }, [authService.isAuthenticated]);

  useEffect(() => {
    eventEmitter.on('forbidden', () => {
      navigate(PAGE_ROUTE.BOOKING);
    });

    return () => {
      eventEmitter.listenersMap.delete('forbidden');
    };
  }, []);

  useEffect(() => {
    runOneSignal();
  }, []);

  useEffect(() => {
    notificationStore.getCount({ state: NOTIFICATION_FILTER_TYPE.UNREAD });
  }, []);

  // Sticky Menu Area
  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target.scrollTop > 80) {
        configStore.changeSticky({ isSticky: true, offset: e.target.scrollTop });
      } else {
        configStore.changeSticky({ isSticky: false, offset: 0 });
      }
    };

    const contentElement = contentRef.current;
    contentElement.addEventListener('scroll', handleScroll);

    return () => {
      contentElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const toggleMenuBar = () => {
    setMenuBar(!menuBar);
  };

  const handleLogout = async () => {
    try {
      if (userStore.userInfo?.branches.length) {
        OneSignal.User.removeTags(
          userStore.userInfo.branches.map((branch: any) => branch.id.toString())
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      authService.logout();
      branchStore.reset();
      setOpenToastify(false);
    }
  };

  const handleCancellation = () => {
    setOpenToastify(false);
  };

  const handleSetNotify = (value: boolean) => {
    setOpenNotifi(value);
  };

  const handleSetSeeAll = (value: boolean) => {
    setOpenNotifi(false);
    navigator(PAGE_ROUTE.NOTIFICATION);
  };

  const onOpenNoti = async (e: any) => {
    e.stopPropagation();
  };

  return (
    <Layout className={styles.container}>
      <div className={styles.container_header}>
        <Row style={{ height: '100%' }} justify={'space-between'} align={'middle'}>
          <Col>
            <div className={styles.container_header_left}>
              <Row gutter={12} justify={'space-between'} align={'middle'}>
                <Col>
                  <Link to={PAGE_ROUTE.BOOKING}>
                    <img src={LogoImage} alt="" />
                  </Link>
                </Col>
                <Col>
                  <Typography className={styles.container_header_title}>
                    {t(i18nKey.layout.title)}
                  </Typography>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <Row align={'middle'} gutter={16} className={styles.container_header_profile}>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <Dropdown
                  menu={{
                    items: itemsSettingLanguage,
                    selectable: true,
                    defaultSelectedKeys: [i18n.language]
                  }}
                  trigger={['click']}
                  placement="bottom"
                  dropdownRender={(menu) => (
                    <div style={{ marginTop: 10 }}>
                      {React.cloneElement(menu as React.ReactElement)}
                    </div>
                  )}>
                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <img
                      style={{ width: 24, height: 18, borderRadius: 4 }}
                      src={LANGUAGE_ICON.get(i18n.language)}
                    />
                  </div>
                </Dropdown>
              </Col>
              <Col>
                {/* <img className={styles.bell} src={Notification} /> */}
                <div onClick={onOpenNoti} onKeyDown={(e) => e.stopPropagation()}>
                  <Notifications
                    open={openNotifi}
                    handleSetNotify={handleSetNotify}
                    handleSetSeeAll={handleSetSeeAll}>
                    <Badge count={notificationStore.unreadCount} size="small" showZero>
                      <Avatar className={styles.wrapperNoti}>
                        <img className={styles.noti} src={Notification} alt="" />
                      </Avatar>
                    </Badge>
                  </Notifications>
                </div>
              </Col>
              <Col>
                <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <div style={{ marginTop: 10 }}>
                      {React.cloneElement(menu as React.ReactElement)}
                    </div>
                  )}>
                  <Space style={{ cursor: 'pointer' }}>
                    {/* <Avatar
                        src={userStore?.userInfo?.imagePath ?? DefaultAvatar}
                        size={28}
                        icon={<UserOutlined />}
                      /> */}
                    <Typography className={styles.container_headerLayout_name}>
                      {userStore?.userInfo?.fullName}
                    </Typography>
                    <Col className={styles.downOutline}>
                      <img src={IconDown} alt="" />
                    </Col>
                  </Space>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Layout>
        <Drawer
          className={styles.container_menuDrawer}
          title="Menu"
          placement="left"
          width="400px"
          closable={!menuBar}
          onClose={toggleMenuBar}
          open={menuBar}>
          <AppMenu />
        </Drawer>
        <Sider
          style={{ background: 'var(--Gray-White, #FFF)', flexBasis: '240' }}
          collapsible
          collapsed={collapsed}
          trigger={null}
          className={`${styles.container_slider} ${
            collapsed && styles.container_slider_collapsed
          }`}>
          <AppMenu collapsed={collapsed} />
          <div
            className={styles.container_slider_bar}
            onClick={toggleCollapsed}
            onKeyDown={toggleCollapsed}>
            <img src={ArrowLeft} alt="LogoutIcon" />
          </div>
        </Sider>
        <Layout>
          <Content className={styles.container_content} ref={contentRef}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>

      <ToastifyConfirm
        openToastify={openToastify}
        onCancel={handleCancellation}
        onSubmit={handleLogout}
        description={`${t(i18nKey.confirmationPopup.logout)}`}
      />
    </Layout>
  );
};

export default observer(MainLayout);
