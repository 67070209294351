export const PAGINATION_CONFIGURATION = {
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_SIZE: 10,
  DEFAULT_LIMIT: 100000
};

export enum TABLE_SORT_DIRECTION {
  DESC = 'DESC',
  ASC = 'ASC'
}

export enum DISCOUNT_CATS {
  PERCENTAGE_BASED = 'PERCENTAGE-BASED',
  FIXED_VALUE = 'FIXED-VALUE'
}

export const DISCOUNT_CATS_VALUE = {
  [DISCOUNT_CATS.FIXED_VALUE]: 'VNĐ',
  [DISCOUNT_CATS.PERCENTAGE_BASED]: '%'
};

export enum GIFT_VOUCHER_CATS {
  DISCOUNT_ON_ORDER = 'DISCOUNT-ON-ORDER',
  DISCOUNT_ON_SERVICE = 'DISCOUNT-ON-SERVICE'
}

export enum GIFT_VOUCHER_GROUPS_CATS {
  SERVICE_SPECIFIC_DISCOUNT = 'SERVICE-SPECIFIC-DISCOUNT',
  DISCOUNT_WITH_SERVICES = 'DISCOUNT-WITH-SERVICES'
}

export enum GIFT_VOUCHER_GROUPS_CATS_CODE {
  DISCOUNT_WITH_SERVICES = 1,
  SERVICE_SPECIFIC_DISCOUNT = 2
}

export enum GIFT_VOUCHER_GROUPS_CODE {
  FIXED_VALUE = 1,
  PERCENTAGE_BASED = 2
}

export const TABLE_SCROLL_Y_DEFAULT = 600;

export enum MODE_ACTIONS {
  EDIT = 'edit',
  DELETE = 'delete',
  INFO = 'info',
  NAVIGATE = 'navigate'
}

export const GROUP_TIME_BOOKINGS = ['9:00', '12:00', '14:00', '17:00', '20:00'];
export const BOOKING_END_TIME = '21:00';

export enum ROUTE_ACTIONS {
  IMPORT = 'import',
  EXPORT = 'export'
}

export enum TABLE_FILTER_TYPES {
  SINGLE_SELECT = 'SINGLE-SELECT',
  MULTIPLE_SELECT = 'MULTIPLE-SELECT',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE'
}

export const DEFAULT_VALUES = {
  ZERO_NUMBER: 0,
  ONE_NUMBER: 1,
  EMPTY_STRING: '',
  EMPTY_ARRAY: [],
  EMPTY_OBJECT: {},
  UNDEFINED: undefined,
  FALSE: false,
  TRUE: true,
  NEGATIVE_ONE: -1,
  NEGATIVE: '-'
};

export const defaultInputNumberFormatter = (value: any) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const defaultInputNumberParser = (value: any) =>
  value?.replace(/\$\s?|(,*)/g, '') as unknown as number;

export const STORAGE_KEYS = {
  BOOKING_FILTER: 'booking_filter'
};

export const NON_MEMBERSHIP = 'NON-MEMBERSHIP';
export const NEW_CUSTOMERS = 'NEW-CUSTOMERS';
export const DEFAULT_PROD_CAT = 'GIFT-VOUCHER';
