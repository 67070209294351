const notifications = {
  title: 'Thông báo',
  table: {
    content: 'Nội dung',
    time: 'Thời gian'
  },

  button: {
    markAllAsRead: 'Đánh dấu đã đọc tất cả',
    markAsRead: 'Đánh dấu đã đọc',
    seeAll: 'Xem tất cả'
  },

  type: {
    alert: 'Nhắc nhở',
    report: 'Đặt lịch'
  },

  emptyText: 'Không có thông báo nào'
};

export default notifications;
