import { messageResponse } from 'src/constants/message-response';

const GiftVoucher = {
  managementGiftVoucher: 'Gift Voucher Management',
  createGiftVoucher: 'Create Gift Voucher',
  updateGiftVoucher: 'Update Gift Voucher',

  form: {
    giftVoucherCats: 'Gift voucher type',
    includeServiceExtraFee: 'Includes service fee',
    numberOfGiftVoucher: 'Number of Gift Voucher'
  },

  placeholder: {
    search: 'Search by Gift Voucher Group Name',
    giftVoucherCatInput: 'Select Gift Voucher card type'
  },

  details: {
    title: 'Gift voucher details'
  },

  errMsg: {
    [messageResponse.prefixCodesExists]: 'Symbol already exists in the system'
  }
};

export default GiftVoucher;
