import { makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateMembershipDTO,
  DeleteMembershipDTO,
  DetailMembershipDTO,
  GetListMembershipDTO,
  UpdateMembershipDTO
} from 'src/dto/master-data/membership.dto';
import {
  IMembershipBody,
  IMembershipItem,
  IMembershipRequest
} from 'src/interfaces/master-data/membership';
import { IHttpService } from 'src/services/http.service';

export interface IMembershipStore {
  listMemberships: IMembershipItem[];
  requestParams?: IMembershipRequest;
  fetchList(request?: IMembershipRequest): Promise<void>;
  create(body: IMembershipBody): Promise<ResponseDTO<IMembershipItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IMembershipItem>>;
  update(body: IMembershipBody, param: { id: string }): Promise<ResponseDTO<IMembershipItem>>;
  delete(param: { id: string }): Promise<ResponseDTO<IMembershipItem>>;
}

export class MembershipStore implements IMembershipStore {
  listMemberships: IMembershipItem[] = [];
  requestParams?: IMembershipRequest = undefined;
  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listMemberships: observable.ref,
      requestParams: observable.ref
    });
  }

  public async fetchList(request?: IMembershipRequest) {
    runInAction(() => {
      this.requestParams = request;
    });
    const requestDTO = new GetListMembershipDTO(request);
    const result: ResponseDTO<IMembershipItem[]> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listMemberships = (Array.isArray(result?.data) && result.data) || [];
      });
    }
  }

  public async create(body: IMembershipBody) {
    const createDTO = new CreateMembershipDTO(body);
    const res: ResponseDTO<IMembershipItem> = await this.http.request(createDTO);
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailMembershipDTO(query);
    const res: ResponseDTO<IMembershipItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IMembershipBody, param: { id: string }) {
    const updateStoreDTO = new UpdateMembershipDTO(body, param);
    const res: ResponseDTO<IMembershipItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async delete(param: { id: string }) {
    const deleteStoreDTO = new DeleteMembershipDTO(param);
    const res: ResponseDTO<IMembershipItem> = await this.http.request(deleteStoreDTO);
    return res;
  }
}
