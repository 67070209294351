import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';

export class CustomerBody {
  qrCode?: string;
  fullName?: string;
  dob?: string;
  email?: string;
  phone?: string;
  address?: string;
  gender?: string;
  additionalInfo?: string;
  countryId?: number;
  categoryId?: number;
}

export class CustomerCreateDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: CustomerBody;
  public url: string = ENDPOINT.CUSTOMER;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: CustomerBody) {
    super();
    this.body = body;
  }
}

export class CustomerUpdatelParam {
  id!: string;
}

export class CustomerUpdateDTO extends DTO {
  public param: CustomerUpdatelParam;
  public query: undefined;
  public body: CustomerBody;
  public url: string = ENDPOINT.CUSTOMER_ID;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(param: CustomerUpdatelParam, body: CustomerBody) {
    super();
    this.param = param;
    this.body = body;
  }
}

export class ExportCustomersDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.CUSTOMER_EXPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
}

export class ImportCustomersDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.CUSTOMER_IMPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}
