import { IPromotionCampaignsReportsRequest } from 'src/interfaces/reports/promotionCampaign';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListPromotionCampaignsReportsDTO extends DTO {
  public param: object | undefined;
  public query: IPromotionCampaignsReportsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.PROMOTION_CAMPAIGN_REPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType?: ResponseType = 'json';
  constructor(query?: IPromotionCampaignsReportsRequest) {
    super();
    this.query = query;
  }
}
