const Branch = {
  title: 'Branch Management',
  createBranch: 'Create Branch',
  editBranch: 'Edit Branch',

  errMsg: {
    existingBranchName: 'Existing Branch Name!',
    existingIdBranch: 'Branch code already exists in the system'
  }
};

export default Branch;
