import { DTO } from './base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { IPackageBody, IPackageRequest } from 'src/interfaces/master-data/package';

export class GetListPackageDTO extends DTO {
  public param: object | undefined;
  public query: IPackageRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.PACKAGES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IPackageRequest) {
    super();
    this.query = query;
  }
}

export class CreatePackageDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IPackageRequest;
  public url: string = ENDPOINT.PACKAGES;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IPackageBody) {
    super();
    this.body = body;
  }
}

export class DetailPackageDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.PACKAGES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdatePackageDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IPackageBody;
  public url: string = ENDPOINT.PACKAGES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IPackageBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}
