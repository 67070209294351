const validationRules = {
  'password-invalid': 'Mật khẩu đăng nhập không đúng.',
  'user-invalid': 'Thông tin đăng nhập không đúng.',
  'user-inactive':
    'Tài khoản của bạn hiện không hoạt động. Vui lòng liên hệ với bộ phận hỗ trợ khách hàng để được hỗ trợ kích hoạt lại tài khoản của bạn.',
  'username-existed': 'Username has already exists. Please enter another username',
  'email-existed': 'Email has already exists. Please enter another email.',
  'employeeID-existed': 'Employee ID has already exists. Please enter another Employee ID.',
  'deposit-category-rank-exist': 'Giá trị phân hạng đang trùng, vui lòng nhập lại!'
};

export default validationRules;
