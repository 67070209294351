import { CancelToken } from 'axios';
import dayjs from 'dayjs';
import { makeAutoObservable, observable } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  WorkSchedulesCheckInOutBody,
  WorkSchedulesDTO
} from 'src/dto/work-schedules/work-schedules-check-in-out.dto';
import {
  WorkSchedulesEmployeeBody,
  WorkScheduleEmployeeDTO
} from 'src/dto/work-schedules/work-schedules-employee.dto';
import { WorkSchedulesExportDTO } from 'src/dto/work-schedules/work-schedules-export.dto';
import { WorkSchedulesShiftDTO } from 'src/dto/work-schedules/work-schedules-shift.dto';
import { WorkSchedulesRequest, WorkScheduleDTO } from 'src/dto/work-schedules/work-schedules.dto';
import { Shift, WorkSchedules } from 'src/interfaces/work-schedules';
import { IHttpService } from 'src/services/http.service';

export interface IWorkSchedulesStore {
  shiftList: Shift[];
  querySchedule?: WorkSchedulesRequest;
  fetchList(request?: WorkSchedulesRequest): Promise<WorkSchedules[]>;
  fetchEmployee(body?: WorkSchedulesEmployeeBody): Promise<boolean>;
  fetchCheckInOut(body: WorkSchedulesCheckInOutBody): Promise<ResponseDTO<string>>;
  fetchExport(
    query: WorkSchedulesRequest,
    fileName: string,
    cancelToken?: CancelToken
  ): Promise<boolean>;
  fetchShift(): Promise<void>;
  setQuerySchedule(values: WorkSchedulesRequest): void;
}

export class workSchedulesStore implements IWorkSchedulesStore {
  shiftList: Shift[] = [];
  querySchedule: WorkSchedulesRequest = {};

  constructor(private http: IHttpService) {
    this.querySchedule = {
      date: dayjs().format('YYYY-MM-DD'),
      keyword: ''
    };

    makeAutoObservable(this, {
      shiftList: observable,
      querySchedule: observable
    });
  }

  public async fetchList(request: WorkSchedulesRequest): Promise<WorkSchedules[]> {
    const requestDTO = new WorkScheduleDTO(request);
    const result: ResponseDTO<WorkSchedules[]> = await this.http.request(requestDTO);
    return result.data as WorkSchedules[];
  }

  public async fetchCheckInOut(body: WorkSchedulesCheckInOutBody): Promise<ResponseDTO<string>> {
    const requestDTO = new WorkSchedulesDTO(body);
    return this.http.request(requestDTO);
  }

  public async fetchShift(): Promise<void> {
    const requestDTO = new WorkSchedulesShiftDTO();
    const result = await this.http.request(requestDTO);
    this.shiftList = result.data as Shift[];
  }

  public async fetchExport(
    query: WorkSchedulesRequest,
    fileName: string,
    cancelToken?: CancelToken
  ): Promise<boolean> {
    const gyomuDekidakaCsvDTO = new WorkSchedulesExportDTO(query);
    return this.http.downloadFile(
      gyomuDekidakaCsvDTO.interpolatedUrl(),
      undefined,
      gyomuDekidakaCsvDTO.method,
      {
        name: `${fileName}.xlsx`
      },
      cancelToken
    );
  }

  public async fetchEmployee(body: WorkSchedulesEmployeeBody): Promise<boolean> {
    const requestDTO = new WorkScheduleEmployeeDTO(body);
    const result: ResponseDTO<WorkSchedules[]> = await this.http.request(requestDTO);
    return result.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS;
  }

  public setQuerySchedule(values: WorkSchedulesRequest) {
    this.querySchedule = values;
  }
}
