import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { ResponseDTO } from 'src/dto/base.dto';
import { GetInventoryHistoriesDetailDTO, GetListInventoryDTO, IInventory, IInventoryHistoriesDetail, IInventoryHistoriesDetailRequest, IInventoryRequest } from 'src/dto/master-data/inventory.dto';
import { IPaginationResponse } from 'src/interfaces';
import { IHttpService } from 'src/services/http.service';

export interface IInventoryStore {
  pagination: {
    page: number;
    limit: number;
    current: number;
    total: number;
  };
  params?: IInventoryRequest;
  listInventory: IInventory[];
  fetchedInventory: boolean;
  fetchList(request?: IInventoryRequest): Promise<void>;
  getDetail(query: {id: string}, request?: IInventoryHistoriesDetailRequest): Promise<ResponseDTO<IInventoryHistoriesDetail>>;
}

export class InventoryStore implements IInventoryStore {
  listInventory: IInventory[] = [];
  fetchedInventory = false;
  pagination = {
    page: PAGINATION_CONFIGURATION.DEFAULT_PAGE,
    limit: PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE,
    current: 1,
    total: 0
  };
  params?: IInventoryRequest | undefined;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listInventory: observable.ref,
      fetchedInventory: observable.ref,
      pagination: observable.ref,
      params: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IInventoryRequest) {
    this.params = { ...this.params, ...request };
    const requestDTO = new GetListInventoryDTO(request);
    const result: ResponseDTO<IPaginationResponse<IInventory>> = await this.http.request(
      requestDTO
    );
    if (result?.data) {
      runInAction(() => {
        this.listInventory = result?.data?.paginatedResults || (result?.data as any) || [];
        this.fetchedInventory = true;
        this.pagination = {
          limit: result.data?.limit || this.pagination.limit,
          page: result.data?.page || this.pagination.page,
          current: result.data?.current || 0,
          total: result.data?.total || 0
        };
      });
    }
  }

  public async getDetail(param: {id: string}, request?: IInventoryHistoriesDetailRequest) {
    const detailDTO = new GetInventoryHistoriesDetailDTO(param, request);
    const res: ResponseDTO<IInventoryHistoriesDetail> = await this.http.request(detailDTO);
    return res;
  }
}
