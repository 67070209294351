import { TherapeuticIndicationEnum } from 'src/interfaces/master-data/therapeutic-indication';

export default {
  [TherapeuticIndicationEnum.GENERAL]: 'Full body',
  [TherapeuticIndicationEnum.FACE]: 'Face',
  [TherapeuticIndicationEnum.HEAD]: 'Head',
  [TherapeuticIndicationEnum.NECK_FONT]: 'Front neck',
  [TherapeuticIndicationEnum.NECK_BACK]: 'Back of the neck',
  [TherapeuticIndicationEnum.CHEST]: 'Chest',
  [TherapeuticIndicationEnum.SHOULDER]: 'Shoulder',
  [TherapeuticIndicationEnum.ARM_FRONT_LEFT]: 'Front of the left arm',
  [TherapeuticIndicationEnum.ARM_FRONT_RIGHT]: 'Front of the right arm',
  [TherapeuticIndicationEnum.ARM_BACK_LEFT]: 'Back of the left arm',
  [TherapeuticIndicationEnum.ARM_BACK_RIGHT]: 'Back of the right arm',
  [TherapeuticIndicationEnum.HAND_FRONT_LEFT]: 'Back of the left hand',
  [TherapeuticIndicationEnum.HAND_FRONT_RIGHT]: 'Back of the right hand',
  [TherapeuticIndicationEnum.HAND_BACK_LEFT]: 'Back of the left hand',
  [TherapeuticIndicationEnum.HAND_BACK_RIGHT]: 'Back of the right hand',
  [TherapeuticIndicationEnum.BELLY]: 'Belly',
  [TherapeuticIndicationEnum.BACK]: 'Back',
  [TherapeuticIndicationEnum.THIGH_FRONT_LEFT]: 'Front of the left thigh',
  [TherapeuticIndicationEnum.THIGH_FRONT_RIGHT]: 'Front of the right thigh',
  [TherapeuticIndicationEnum.THIGH_BACK_LEFT]: 'Back of the left thigh',
  [TherapeuticIndicationEnum.THIGH_BACK_RIGHT]: 'Back of the right thigh',
  [TherapeuticIndicationEnum.LEG_FRONT_LEFT]: 'Front of the left lower leg',
  [TherapeuticIndicationEnum.LEG_FRONT_RIGHT]: 'Front of the right lower leg',
  [TherapeuticIndicationEnum.LEG_BACK_LEFT]: 'Back of the left lower leg',
  [TherapeuticIndicationEnum.LEG_BACK_RIGHT]: 'Back of the right lower leg',
  [TherapeuticIndicationEnum.FOOT_FRONT_LEFT]: 'Front of the left foot',
  [TherapeuticIndicationEnum.FOOT_FRONT_RIGHT]: 'Front of the right foot',
  [TherapeuticIndicationEnum.FOOT_BACK_LEFT]: 'Back of the left foot',
  [TherapeuticIndicationEnum.FOOT_BACK_RIGHT]: 'Back of the right foot'
};
