import { ICustomerCatsBody, ICustomerCatsRequest } from 'src/interfaces/master-data/customer-cats';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListCustomerCatsDTO extends DTO {
  public param: object | undefined;
  public query: ICustomerCatsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.CUSTOMER_CATS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: ICustomerCatsRequest) {
    super();
    this.query = query;
  }
}

export class CreateCustomerCatsDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: ICustomerCatsBody;
  public url: string = ENDPOINT.CUSTOMER_CATS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: ICustomerCatsBody) {
    super();
    this.body = body;
  }
}

export class DetailCustomerCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.CUSTOMER_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateCustomerCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: ICustomerCatsBody;
  public url: string = ENDPOINT.CUSTOMER_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: ICustomerCatsBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DeleteCustomerCatsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: unknown;
  public url: string = ENDPOINT.CUSTOMER_CATS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
