import { IRevenueReportRequest } from 'src/interfaces/reports/revenue';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListRevenueReportsDTO extends DTO {
  public param: object | undefined;
  public query: IRevenueReportRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.REVENUES_REPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType?: ResponseType = 'json';
  constructor(query?: IRevenueReportRequest) {
    super();
    this.query = query;
  }
}
