import { IBookingBody, IBookingRequest } from 'src/interfaces/master-data/booking';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { AppoinmentServiceAction } from 'src/constants/booking.constant';

export class GetListBookingDTO extends DTO {
  public param: object | undefined;
  public query: IBookingRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.APPOINTMENTS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IBookingRequest) {
    super();
    this.query = query;
  }
}

export class CreateBookingDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IBookingBody;
  public url: string = ENDPOINT.APPOINTMENTS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBookingBody) {
    super();
    this.body = body;
  }
}

export class DetailBookingDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.APPOINTMENTS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: number }) {
    super();
    this.param = param;
  }
}

export class UpdateBookingDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: IBookingBody;
  public url: string = ENDPOINT.APPOINTMENTS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBookingBody, param: { id: number }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class UpdateAppointmentServiceStatusDTO extends DTO {
  public param: { appointmentId: number; serviceInstanceId: number };
  public query: undefined;
  public body: { status: AppoinmentServiceAction };
  public url: string = ENDPOINT.APPOINTMENTS_SERVICE_INSTANCE_UPDATE;
  public method: HTTP_METHOD = HTTP_METHOD.PATCH;
  public readonly responseType: ResponseType = 'json';
  constructor(
    body: { status: AppoinmentServiceAction },
    param: { appointmentId: number; serviceInstanceId: number }
  ) {
    super();
    this.body = body;
    this.param = param;
  }
}
