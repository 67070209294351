export enum PromotionTypeEnum {
  SERVICE = 'SERVICE',
  GIFTVOUCHER = 'GIFT-VOUCHER'
}

export enum PromotionDiscountCatEnum {
  PERCENTAGE_BASED = 'PERCENTAGE-BASED',
  FIXED_VALUE = 'FIXED-VALUE',
  FREE_SERVICE = 'FREE-SERVICE',
  FREE_SERVICE_BASED_CUSTOMER_COUNT = 'FREE-SERVICE-BASED-CUSTOMER-COUNT',
  FREE_SERVICE_BASED_SERVICE_COUNT = 'FREE-SERVICE-BASED-SERVICE-COUNT'
}

export const PROMOTION_VALUE = {
  [PromotionDiscountCatEnum.FIXED_VALUE]: 'Giảm cố định theo Giá Trị Khuyến Mãi',
  [PromotionDiscountCatEnum.PERCENTAGE_BASED]: 'Giảm theo phần trăm Giá Trị Khuyến Mãi',
  [PromotionDiscountCatEnum.FREE_SERVICE]: 'Miễn phí 1 DV đã chỉ định ở Giá Trị Khuyến Mãi',
  [PromotionDiscountCatEnum.FREE_SERVICE_BASED_CUSTOMER_COUNT]: 'Miễn phí 1 DV khi đi nhiều người',
  [PromotionDiscountCatEnum.FREE_SERVICE_BASED_SERVICE_COUNT]: 'Miễn phí 1 DV đã chỉ định khi đạt được Số lượng DV tối thiểu'
};

export enum PROMOTION_TYPE_CODE {
  PERCENTAGE_BASED = 1,
  FIXED_VALUE = 2,
  FREE_SERVICE = 3,
  FREE_SERVICE_BASED_CUSTOMER_COUNT = 4,
  FREE_SERVICE_BASED_SERVICE_COUNT = 5
}

export enum LIMIT_VALUE {
  PERCENTAGE_BASED = 100
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum Weekday {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}


