import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateBranchDTO,
  DetailBranchDTO,
  GetListBranchBedDTO,
  GetListBranchDTO,
  GetListBranchServiceEmployeeDTO,
  RoomAvailablilityBranchDTO,
  GetListBranchServiceTherapistDTO,
  GetListCustomerPromotionDTO,
  RoomBedBranchDTO,
  UpdateBranchDTO,
  WorkSchedulesTherapistsBranchDTO
} from 'src/dto/master-data/branch.dto';
import { IPaginationResponse } from 'src/interfaces';
import { IEmployeeItem } from 'src/interfaces/employee/employee';
import {
  IBedItem,
  IBranchBody,
  IBranchItem,
  IBranchRequest,
  IRoomAvailablility,
  ICustomerPromotionRequest,
  IRoomBed,
  IWorkSchedulesTherapists
} from 'src/interfaces/master-data/branch';
import { IPromotionsItem } from 'src/interfaces/promotion';
import { IHttpService } from 'src/services/http.service';
import { IUserStore } from '../user.store';
import { RoleName } from 'src/constants/permission';

export interface IBranchStore {
  fetched: boolean;
  listBranch: IBranchItem[];
  listBed: IBedItem[];
  fetchList(request?: IBranchRequest): Promise<void>;
  create(body: IBranchBody): Promise<ResponseDTO<IBranchItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IBranchItem>>;
  getRoomBed(param: { id: number }): Promise<ResponseDTO<IRoomBed[]>>;
  getBranchBeds(param: { id: number }): Promise<IBedItem[]>;
  getCustomerPromotions(
    param: { branchId: number },
    request: ICustomerPromotionRequest
  ): Promise<ResponseDTO<IPaginationResponse<IPromotionsItem>>>;
  getServiceEmployees(param: { branchId: number; serviceId: number }): Promise<IEmployeeItem[]>;
  getWorkSchedulesTherapists(param: {
    id?: number | null;
  }): Promise<ResponseDTO<IWorkSchedulesTherapists>>;
  getRoomAvailablility(param: { id?: number | null }): Promise<ResponseDTO<IRoomAvailablility[]>>;
  getServiceTherapists(param: { branchId: number; serviceId: number }): Promise<IEmployeeItem[]>;
  update(body: IBranchBody, param: { id: string }): Promise<ResponseDTO<IBranchItem>>;
  destroyStoreWhenLogout(): void;
  reset(): void;
}

export class BranchStore implements IBranchStore {
  fetched = false;
  listBranch: IBranchItem[] = [];
  listBed: IBedItem[] = [];

  constructor(private http: IHttpService, private userStore: IUserStore) {
    makeAutoObservable(this, {
      listBranch: observable.ref,
      fetched: observable.ref,
      reset: action.bound,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IBranchRequest): Promise<void> {
    const requestDTO = new GetListBranchDTO(request);
    const result: ResponseDTO<IBranchItem[]> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listBranch =
          ([RoleName.ADMIN, RoleName.SUPER_ADMIN].includes(
            this.userStore.userInfo?.role.name as RoleName
          )
            ? result.data
            : result.data?.filter((branch: IBranchItem) =>
                this.userStore.userBranchIds.includes(branch.id)
              )) ?? [];
        this.fetched = true;
      });
    }
  }

  public async getCustomerPromotions(
    query: { branchId: number },
    request: ICustomerPromotionRequest
  ): Promise<ResponseDTO<IPaginationResponse<IPromotionsItem>>> {
    const requestDTO = new GetListCustomerPromotionDTO(query, request);
    const result: ResponseDTO<IPaginationResponse<IPromotionsItem>> = await this.http.request(
      requestDTO
    );

    return result;
  }

  public async create(body: IBranchBody) {
    const createDTO = new CreateBranchDTO(body);
    const res: ResponseDTO<IBranchItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailBranchDTO(query);
    const res: ResponseDTO<IBranchItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IBranchBody, param: { id: string }) {
    const updateStoreDTO = new UpdateBranchDTO(body, param);
    const res: ResponseDTO<IBranchItem> = await this.http.request(updateStoreDTO);
    this.fetchList();
    return res;
  }

  public async getRoomBed(param: { id: number }) {
    const roomBedDTO = new RoomBedBranchDTO(param);
    const res: ResponseDTO<IRoomBed[]> = await this.http.request(roomBedDTO);
    return res;
  }

  public async getServiceEmployees(param: { branchId: number; serviceId: number }) {
    const requestBranchServiceEmployeeDTO = new GetListBranchServiceEmployeeDTO(param);
    const result: ResponseDTO<IEmployeeItem[]> = await this.http.request(
      requestBranchServiceEmployeeDTO
    );
    return result?.data || [];
  }

  public async getBranchBeds(param: { id: number }) {
    const requestBedDTO = new GetListBranchBedDTO(param);
    const result: ResponseDTO<IBedItem[]> = await this.http.request(requestBedDTO);
    runInAction(() => {
      this.listBed = result.data as IBedItem[];
    });
    return result?.data || [];
  }

  public async getWorkSchedulesTherapists(param: { id: number }) {
    const workSchedulesTherapistsDTO = new WorkSchedulesTherapistsBranchDTO(param);
    const res: ResponseDTO<IWorkSchedulesTherapists> = await this.http.request(
      workSchedulesTherapistsDTO
    );
    return res;
  }

  public async getRoomAvailablility(param: { id: number }) {
    const roomAvailablilityDTO = new RoomAvailablilityBranchDTO(param);
    const res: ResponseDTO<IRoomAvailablility[]> = await this.http.request(roomAvailablilityDTO);
    return res;
  }

  public async getServiceTherapists(param: { branchId: number; serviceId: number }) {
    const requestServiceTherapistDTO = new GetListBranchServiceTherapistDTO(param);
    const result: ResponseDTO<IEmployeeItem[]> = await this.http.request(
      requestServiceTherapistDTO
    );
    return result?.data || [];
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listBranch = [];
    });
  }

  public reset() {
    this.fetched = false;
    this.listBed = [];
    this.listBranch = [];
  }
}
