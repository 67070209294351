export const messageResponse = {
  existingName: 'name-existed',
  existingEmail: 'email-existed',
  existingAccount: 'account-existed',
  existingArea: 'name-existed',
  existingDate: 'date-is-unique',
  existingNameDate: 'name-is-unique',
  invalidEmailOrPassword: 'Invalid Email or Password.',
  inactiveAccountNotification: 'user-inactive',
  usernameNotFound: 'user-not-found',
  passwordDifferentFromCurrentErr: 'same-old-password',
  invalidPassword: 'invalid-username-or-password',
  userNotFound: 'user-not-found',
  Unauthorized: 'Unauthorized',
  activationLinkExpired: 'jwt expired',
  messageUserNotFound: 'User not found',

  //login
  userInvalid: 'user-invalid',
  userInactive: 'user-inactive',
  passwordInvalid: 'password-invalid',
  //user
  existingUser: 'username-existed',
  employeeIDExisted: 'employeeID-existed',

  //roles
  existingRole: 'role-existed',

  serviceCategoryNameExisted: 'service-category-name-existed',
  branchNameCodeExisted: 'branch-name-or-code-existed',
  customerCategoriesNameExisted: 'customer-category-name-existed',
  productCategoriesNameExisted: 'product-category-name-existed',
  productContainerExisted: 'product-container-existed',

  customerPhoneExists: 'customer-phone-exists',
  roomCategoriesNameExisted: 'room-category-name-existed',
  productCodeDuplicated: 'product-code-duplicated',
  membershipNotFound: 'membership-not-found',
  membershipNameExists: 'membership-name-exists',
  userExisted: 'user-existed',
  oneOrMoreServicesNotFound: 'one-or-more-services-not-found',

  //package
  packageExists: 'package-name-existed',

  // services
  serviceCategoryCodeExisted: 'service-category-code-existed',
  depositCategoryRankExist: 'deposit-category-rank-exist',

  // deposit-cats
  minOrMaxAmountsInvaild: 'min-or-max-amounts-invalid',

  // room
  roomNameExist: 'room-name-existed',

  //git-voucher
  prefixCodesExists: 'prefix-codes-exists',

  // employee
  therapistCodeExists: 'therapist-code-exists',

  //promotion
  promotionNameExisted: 'promotion-name-existed',

  //booking
  invalidStatusUpdate: 'invalid-status-update',
  serviceAlreadyStarted: 'service-already-started',
  serviceAlreadyStopped: 'service-already-stopped',
  serviceNotStarted: 'service-not-started',
  anotherServiceIsRunning: 'another-service-is-running',
  bedNotAvailable: 'bed-not-available',
  timeBeforeNow: 'time-before-now',
  employeeNotAvailable: 'employee-not-available',
  employeeNotIdle: 'employee-not-idle',

  // work-schedule
  workScheduleDoNotEnterPasteDates: 'work-schedules-do-not-enter-past-dates',
  therapistIsCheckedInWithOtherBranch: 'therapist-is-checked-in-with-other-branch',

  // warehouse history
  giftVoucherNotEnoughQuantity: 'gift-voucher-not-enough-quantity',
  giftVoucherCodeWithSeriFromStartNumberToEndNumberIsExisted:
    'gift-voucher-code-with-seri-from-startNumber-to-endNumber-is-existed',

  // warehouse export
  productNotEnoughQuantity: 'product-not-enough-quantity',
  startNumberGreaterThanEndNumber: 'start-number-greater-than-end-number',
  someGiftVoucherCodeWithSeriFromStartNumberToEndNumber:
    'some-gift-voucher-code-with-seri-from-startNumber-to-endNumber-is-not-existed',
  giftVoucherCodeNotEnoughQuantity: 'gift-voucher-code-not-enough-quantity',
  productIdNotEnoughQuantity: 'product-id-not-enough-quantity',
  someGiftVoucherCodeWithSeriFromStartNumberToEndNumberIsNotExistedInWarehouseFrom:
    'some-gift-voucher-code-with-seri-from-startNumber-to-endNumber-is-not-existed-in-warehouse-from',

  // order
  productIdNotExist: 'product-does-not-exist',
  productIdNotExistInWarehouse: 'product-does-not-exist-in-warehouse',
  prefixCodeNotEnoughQuantity: 'prefix-code-not-enough-quantity',
  giftVoucherCodeNotExist: 'gift-voucher-does-not-exist',
  serviceIdNotExist: 'service-does-not-exist',
  promotionIdNotExist: 'promotion-does-not-exist',
  customerIdNotExist: 'customer-does-not-exist',
  giftVoucherDiscountNotExist: 'gif-voucher-discount-does-not-exist',
  giftVoucherDiscountIsUsed: 'gif-voucher-discount-is-used',
  giftVoucherDiscountIsExpired: 'gif-voucher-discount-is-expired',
  giftVoucherDiscountCannotAppliedToday: 'gif-voucher-discount-cannot-applied-today',
  giftVoucherDiscountCannotAppliedHoliday: 'gif-voucher-discount-cannot-applied-holiday',
  giftVoucherInvalidToSell: 'gif-voucher-invalid-to-sell',
  noServicesUsedAreEligibleForTheVoucherDiscount:
    'no-services-used-are-eligible-for-the-voucher-discount',
  customerNotFound: 'customer-not-found',
  branchNotFound: 'branch-not-found',
  oneOrMoreServiceNotFoundInBranch: 'one-or-more-service-not-found-in-branch',
  requireAtLeastOneItem: 'require-at-least-one-item',
  oneOrMoreProductNotFoundInWarehouse: 'one-or-more-product-not-found-in-warehouse',
  oneOrMoreGiftVoucherNotFoundInWarehouse: 'one-or-more-gift-voucher-not-found-in-warehouse',
  promotionNotFound: 'promotion-not-found',
  promotionNotFoundInBranch: 'promotion-not-found-in-branch',
  promotionCatNameNotFound: 'promotion-cat-name-found',
  todayIsOutsideThePromotion: 'today-is-outside-the-promotion',
  dayOfWeekNotBeAppliedThePromotion: 'day-of-week-not-applied-the-promotion',
  currentTimeIsNotWithinThePromotion: 'current-time-is-not-within-the-promotion',
  numberOfCustomersNotEnoughToApplyPromotion: 'number-of-customers-not-enough-to-apply-promotion',
  numberOfServicesNotEnoughToApplyPromotion: 'number-of-services-not-enough-to-apply-promotion',
  totalItemsLessThanMinItemsInInvoiceOfPromotion:
    'total-items-less-than-min-items-in-invoice-of-promotion',
  totalItemsGreaterThanMaxItemsInInvoiceOfPromotion:
    'total-items-greater-than-max-items-in-invoice-of-promotion',
  invoiceValueLessThanMinInvoiceValueOfPromotion:
    'invoice-value-less-than-min-invoice-value-of-promotion',
  requiredAtLeastPaymentTransaction: 'required-at-least-payment-transaction',
  someItemsInvalid: 'some-items-invalid',
  invoiceNotFound: 'invoice-not-found',
  notAllowedUpdatePaidInvoice: 'not-allowed-update-paid-invoice',
  currencyCodeNotFound: 'currency-code-not-found',
  noPayableServiceInFreeList: 'no-payable-service-in-free-list',
  cannotApplyThisPromotion: 'cannot-apply-this-promotion',
  giftVoucherCodesCannotEmpty: 'gift-voucher-codes-cannot-empty',
  discountSourceTypeNotFound: 'discount-source-type-not-found',
  giftVoucherIsUsed: 'gift-voucher-is-used',
  giftVoucherIsSold: 'gift-voucher-is-sold',
  cannotApplyForThisBranch: 'cannot-apply-for-this-branch',
  cannotApplyOnWeekend: 'cannot-apply-on-weekend',
  cannotApplyToHoliday: 'cannot-apply-to-holiday',
  giftVoucherNotFound: 'gift-voucher-not-found',
  invoiceNotIncludeGiftVoucherServicesDiscounts:
    'invoice-not-include-gift-voucher-services-discounts',
  giftVoucherIsExpired: 'gift-voucher-is-expired',
  giftVoucherHasNotBeenPurchased: 'gift-voucher-has-not-been-purchased',
  lockerNoCanNotBeLargerThan20Characters: 'locker-no-can-not-be-larger-than-20-characters',

  // membership
  membershipAssociatedWithExistingCustomers: 'membership-associated-with-existing-customers',
  membershipAssociatedWithExistingPromotions: 'membership-associated-with-existing-promotions',

  // service category
  serviceCatAssociatedWithExistingServices: 'service-category-associated-with-existing-services',

  // product category
  productCatAssociatedWithExistingProducts: 'product-category-associated-with-existing-products',

  // room category
  roomCatAssociatedWithExistingRooms: 'room-category-associated-with-existing-rooms',

  // prefix code
  prefixCodeAssociatedWithExistingGiftVouchers: 'prefix-code-associated-with-existing-gift-vouchers'
};
