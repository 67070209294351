const CustomerCats = {
  title: 'Danh sách Nhóm khách hàng',
  form: {
    titleCreate: 'Thêm mới Nhóm khách hàng',
    titleUpdate: 'Chỉnh sửa Nhóm khách hàng',
  },
  customerCatsNameAlreadyExists: 'Đã tồn tại tên nhóm khách hàng!',
  placeholder: {
    search: 'Tìm kiếm theo Nhóm KH'
  }
};

export default CustomerCats;
