const notifications = {
  title: 'Notifications',
  table: {
    content: 'Content',
    time: 'Time'
  },

  button: {
    markAllAsRead: 'Mark all as read',
    markAsRead: 'Mark as read',
    seeAll: 'See all'
  },

  type: {
    alert: 'Alert',
    report: 'Report'
  },

  emptyText: 'There are no announcements'
};

export default notifications;
