import { messageResponse } from 'src/constants/message-response';

const workSchedules = {
  workSchedulesPage: 'Danh sách Lịch làm việc',
  scheduleName: 'Ca {{number}}',
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  noCheckInOut: 'Không check in/out',
  month: 'Tháng',

  button: {
    add: '+ Thêm',
    import: 'Nhập file',
    export: 'Xuất file'
  },

  modal: {
    title: 'Thêm nhân viên ca {{shiftNumber}}'
  },

  errorMessage: {
    [messageResponse.workScheduleDoNotEnterPasteDates]:
      'Không được phép cập nhật dữ liệu lịch làm việc ở quá khứ.',
    [messageResponse.therapistIsCheckedInWithOtherBranch]: 'KTV này đang check-in ở chi nhánh khác.'
  }
};

export default workSchedules;
