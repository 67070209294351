import httpClient, { HttpService } from 'src/services/http.service';
import ConfigurationStore, { IConfiguration } from './configuration.store';
import { IUserStore, UserStore } from './user.store';
import { IPermissionStore, PermissionStore } from './administration/user-permission.store';
import { BranchStore, IBranchStore } from './master-data/branch.store';
import { IServiceGroupStore, ServiceGroupStore } from './master-data/service-group.store';
import { IWorkSchedulesStore, workSchedulesStore } from './work-schedules.store';
import { IServicesStore, ServicesStore } from './services.store';
import { EmployeeStore, IEmployeeStore } from './employee/employee.store';
import { CustomerCatsStore, ICustomerCatsStore } from './master-data/customer-cats.store';
import { IProductCatsStore, ProductCatsStore } from './master-data/product-cats.store';
import { CustomerStore, ICustomerStore } from './customer.store';
import { CountriesStore, ICountriesStore } from './master-data/countries.store';
import { IRoomCatsStore, RoomCatsStore } from './master-data/room-cats.store';
import { IRoomsStore, RoomsStore } from './master-data/rooms.store';
import { IMembershipStore, MembershipStore } from './master-data/membership.store';
import { IProductsStore, ProductsStore } from './master-data/products.store';
import { DiscountCatsStore, IDiscountCatsStore } from './discount-cats.store';
import { GiftVoucherStore, IGiftVoucherStore } from './product-group/gift-voucher.store';
import { DepositCatsStore, IDepositCatsStore } from './master-data/deposit-cats.store';
import { BookingStore, IBookingStore } from './master-data/booking.store';
import {
  ITherapeuticIndicationStore,
  TherapeuticIndicationStore
} from './master-data/therapeutic-indication.store';
import {
  IProductContainersStore,
  ProductContainersStore
} from './master-data/product-containers.store';
import {
  IInventoryHistoryStore,
  InventoryHistoryStore
} from './master-data/inventory-history.store';
import { IPromotionsStore, PromotionsStore } from './promotion.store';
import { IInventoryStore, InventoryStore } from './master-data/inventory.store';
import { INotificationStore, NotificationStore } from './notification/notification.store';
import { IOrderStore, OrderStore } from './master-data/order.store';
import { IReportsStore, ReportsStore } from './reports/reports.store';
import { IUserPermissionStore, UserPermissionStore } from './user-permission.store';
import { IPackageStore, PackageStore } from './package.store';

export interface IRootStore {
  configuration: IConfiguration;
  httpClient: HttpService;
  userStore: IUserStore;
  permissionManagementStore: IPermissionStore;
  branchStore: IBranchStore;
  serviceGroupStore: IServiceGroupStore;
  workSchedulesStore: IWorkSchedulesStore;
  servicesStore: IServicesStore;
  employeeStore: IEmployeeStore;
  customerCatsStore: ICustomerCatsStore;
  productCatsStore: IProductCatsStore;
  customerStore: ICustomerStore;
  countriesStore: ICountriesStore;
  productsStore: IProductsStore;
  promotionsStore: IPromotionsStore;
  roomCatsStore: IRoomCatsStore;
  roomsStore: IRoomsStore;
  packageStore: IPackageStore;
  membershipStore: IMembershipStore;
  productContainersStore: IProductContainersStore;
  discountCatsStore: IDiscountCatsStore;
  giftVoucherStore: IGiftVoucherStore;
  depositCatsStore: IDepositCatsStore;
  bookingStore: IBookingStore;
  therapeuticIndicationStore: ITherapeuticIndicationStore;
  inventoryHistoryStore: IInventoryHistoryStore;
  inventoryStore: IInventoryStore;
  notificationStore: INotificationStore;
  orderStore: IOrderStore;
  reportsStore: IReportsStore;
  userPermissionStore: IUserPermissionStore;
}
export type StoreChildKeyType = keyof IRootStore;

export class RootStore implements IRootStore {
  configuration: IConfiguration;
  httpClient: HttpService;
  userStore: IUserStore;
  permissionManagementStore: IPermissionStore;
  branchStore: IBranchStore;
  serviceGroupStore: IServiceGroupStore;
  workSchedulesStore: IWorkSchedulesStore;
  servicesStore: IServicesStore;
  employeeStore: IEmployeeStore;
  customerCatsStore: ICustomerCatsStore;
  productCatsStore: IProductCatsStore;
  customerStore: ICustomerStore;
  countriesStore: ICountriesStore;
  productsStore: IProductsStore;
  promotionsStore: IPromotionsStore;
  roomCatsStore: IRoomCatsStore;
  roomsStore: IRoomsStore;
  packageStore: IPackageStore;

  membershipStore: IMembershipStore;
  productContainersStore: IProductContainersStore;
  discountCatsStore: IDiscountCatsStore;
  giftVoucherStore: IGiftVoucherStore;
  depositCatsStore: IDepositCatsStore;
  bookingStore: IBookingStore;
  therapeuticIndicationStore: ITherapeuticIndicationStore;
  inventoryHistoryStore: IInventoryHistoryStore;
  inventoryStore: IInventoryStore;
  notificationStore: INotificationStore;
  orderStore: IOrderStore;
  reportsStore: IReportsStore;
  userPermissionStore: IUserPermissionStore;

  constructor() {
    this.httpClient = httpClient;
    this.configuration = new ConfigurationStore();
    this.userStore = new UserStore();
    this.permissionManagementStore = new PermissionStore(this.httpClient);
    this.branchStore = new BranchStore(this.httpClient, this.userStore);
    this.serviceGroupStore = new ServiceGroupStore(this.httpClient);
    this.workSchedulesStore = new workSchedulesStore(this.httpClient);
    this.servicesStore = new ServicesStore(this.httpClient);
    this.employeeStore = new EmployeeStore(this.httpClient);
    this.customerCatsStore = new CustomerCatsStore(this.httpClient);
    this.productCatsStore = new ProductCatsStore(this.httpClient);
    this.customerStore = new CustomerStore(this.httpClient);
    this.countriesStore = new CountriesStore(this.httpClient);
    this.productsStore = new ProductsStore(this.httpClient);
    this.promotionsStore = new PromotionsStore(this.httpClient);
    this.roomCatsStore = new RoomCatsStore(this.httpClient);
    this.roomsStore = new RoomsStore(this.httpClient);
    this.packageStore = new PackageStore(this.httpClient);
    this.membershipStore = new MembershipStore(this.httpClient);
    this.productContainersStore = new ProductContainersStore(this.httpClient);
    this.discountCatsStore = new DiscountCatsStore(this.httpClient);
    this.giftVoucherStore = new GiftVoucherStore(this.httpClient);
    this.depositCatsStore = new DepositCatsStore(this.httpClient);
    this.bookingStore = new BookingStore(this.httpClient);
    this.therapeuticIndicationStore = new TherapeuticIndicationStore(this.httpClient);
    this.inventoryHistoryStore = new InventoryHistoryStore(this.httpClient);
    this.inventoryStore = new InventoryStore(this.httpClient);
    this.notificationStore = new NotificationStore(this.httpClient);
    this.orderStore = new OrderStore(this.httpClient);
    this.reportsStore = new ReportsStore(this.httpClient);
    this.userPermissionStore = new UserPermissionStore(this.httpClient);
  }
}

const rootStore = new RootStore();
export default rootStore;
