import { GiftVoucherCatEnum } from 'src/constants/gift-voucher-category';
import { messageResponse } from 'src/constants/message-response';
import {
  DiscountSourceTypeEnum,
  ItemCatEnum,
  OrderStatusEnum,
  PaymentMethodEnum
} from 'src/constants/order.constant';

const order = {
  title: {
    create: 'Thêm mới Đơn hàng',
    update: 'Cập nhật Đơn hàng'
  },

  placeholder: {
    depositAmount: '50,000'
  },

  button: {
    addTherapist: 'Thêm KTV',
    addBooking: 'Thêm Booking'
  },

  table: {
    column: {
      name: 'Tên',
      time: 'Thời gian đặt'
    }
  },

  discountType: {
    [DiscountSourceTypeEnum.MEMBERSHIP_LEVEL]: 'Giảm giá theo Hạng Thành Viên',
    [DiscountSourceTypeEnum.GIFT_VOUCHER]: 'Gift Voucher',
    [DiscountSourceTypeEnum.PROMOTION]: 'Giảm giá',
    [GiftVoucherCatEnum.DISCOUNT_WITH_SERVICES]: 'Giảm giá dịch vụ',
    [GiftVoucherCatEnum.SERVICE_SPECIFIC_DISCOUNT]: 'Miễn phí dịch vụ'
  },

  status: {
    [OrderStatusEnum.PAID]: 'Đã thanh toán',
    [OrderStatusEnum.UNPAID]: 'Chưa thanh toán'
  },

  paymentMethod: {
    [PaymentMethodEnum.NON_VIA_POS]: 'Tiền mặt',
    [PaymentMethodEnum.VIA_POS]: 'Visa',
    [PaymentMethodEnum.OTHER]: 'Khác'
  },

  text: {
    minutes: 'phút',
    expiry: 'Hạn sử dụng',
    currentStock: 'Tồn kho',
    tip: 'Tip',
    subTotal: 'Tổng tiền sản phẩm',
    vat: 'VAT',
    usePoint: 'Sử dụng tiền đã Nạp',
    balance: 'Số dư',
    freeService: 'Miễn phí dịch vụ',
    promotionPeriod: 'Thời gian áp dụng',
    notApplyDiscount: 'Không áp dụng giảm giá',
    totalBillDiscount: 'Giảm trên tổng bill',
    roomDiscount: 'Giảm phí phòng',
    amountTendered: 'Số tiền khách đưa',
    changeOwed: 'Số tiền phải trả lại',
    amountDue: 'Số tiền khách còn thiếu'
  },

  tab: {
    [ItemCatEnum.SERVICE]: 'Dịch vụ',
    [ItemCatEnum.PRODUCT]: 'Sản phẩm',
    [ItemCatEnum.GIFT_VOUCHER]: 'Gift Voucher',
    [ItemCatEnum.DEPOSIT]: 'Nạp tiền',
    [ItemCatEnum.PACKAGE]: 'Gói dịch vụ'
  },

  resMsg: {
    paidSuccess: 'Thanh toán thành công',
    unpaidSuccess: 'Lưu thành công',
    paidFailed: 'Thanh toán thất bại',
    unpaidFailed: 'Lưu thất bại',
    invalidGiftVoucher: 'Gift Voucher không hợp lệ!',
    [messageResponse.productIdNotExist]: 'Sản phẩm không tồn tại',
    [messageResponse.productIdNotExistInWarehouse]: 'Sản phẩm không có ở kho',
    [messageResponse.productNotEnoughQuantity]: 'Sản phẩm không đủ để bán',
    [messageResponse.prefixCodeNotEnoughQuantity]: 'Không đủ số lượng mã quà tặng',
    [messageResponse.giftVoucherCodeNotExist]: 'Mã quà tặng không tồn tại',
    [messageResponse.serviceIdNotExist]: 'Dịch vụ không tồn tại',
    [messageResponse.promotionIdNotExist]: 'Khuyến mãi không tồn tại',
    [messageResponse.customerIdNotExist]: 'Khách hàng không tồn tại',
    [messageResponse.giftVoucherDiscountNotExist]: 'Giảm giá quà tặng không tồn tại',
    [messageResponse.giftVoucherDiscountIsUsed]: 'Giảm giá quà tặng đã dùng',
    [messageResponse.giftVoucherDiscountIsExpired]: 'Giảm giá quà tặng đã hết hạn',
    [messageResponse.giftVoucherDiscountCannotAppliedToday]:
      'Giảm giá quà tặng không áp dụng hôm nay',
    [messageResponse.giftVoucherDiscountCannotAppliedHoliday]:
      'Giảm giá quà tặng không áp dụng ngày lễ',
    [messageResponse.giftVoucherInvalidToSell]: 'Quà tặng không hợp lệ để bán',
    [messageResponse.noServicesUsedAreEligibleForTheVoucherDiscount]:
      'Không có dịch vụ hợp lệ để giảm giá quà tặng',
    [messageResponse.customerNotFound]: 'Không tìm thấy khách hàng',
    [messageResponse.branchNotFound]: 'Không tìm thấy chi nhánh',
    [messageResponse.oneOrMoreServiceNotFoundInBranch]: 'Không tìm thấy dịch vụ ở chi nhánh',
    [messageResponse.requireAtLeastOneItem]: 'Cần ít nhất một sản phẩm',
    [messageResponse.oneOrMoreProductNotFoundInWarehouse]: 'Không tìm thấy sản phẩm trong kho',
    [messageResponse.oneOrMoreGiftVoucherNotFoundInWarehouse]:
      'Không tìm thấy mã quà tặng trong kho',
    [messageResponse.promotionNotFound]: 'Không tìm thấy khuyến mãi',
    [messageResponse.promotionNotFoundInBranch]: 'Không tìm thấy khuyến mãi ở chi nhánh',
    [messageResponse.promotionCatNameNotFound]: 'Không tìm thấy tên loại khuyến mãi',
    [messageResponse.todayIsOutsideThePromotion]: 'Hôm nay không nằm trong khuyến mãi',
    [messageResponse.dayOfWeekNotBeAppliedThePromotion]: 'Ngày trong tuần không áp dụng khuyến mãi',
    [messageResponse.currentTimeIsNotWithinThePromotion]:
      'Thời gian hiện tại không trong khuyến mãi',
    [messageResponse.numberOfCustomersNotEnoughToApplyPromotion]:
      'Không đủ khách hàng để áp dụng khuyến mãi',
    [messageResponse.numberOfServicesNotEnoughToApplyPromotion]:
      'Không đủ dịch vụ để áp dụng khuyến mãi',
    [messageResponse.totalItemsLessThanMinItemsInInvoiceOfPromotion]:
      'Tổng số hàng ít hơn số tối thiểu trong hóa đơn khuyến mãi',
    [messageResponse.totalItemsGreaterThanMaxItemsInInvoiceOfPromotion]:
      'Tổng số hàng lớn hơn số tối đa trong hóa đơn khuyến mãi',
    [messageResponse.invoiceValueLessThanMinInvoiceValueOfPromotion]:
      'Giá trị hóa đơn ít hơn giá trị tối thiểu trong khuyến mãi',
    [messageResponse.requiredAtLeastPaymentTransaction]: 'Cần ít nhất một giao dịch thanh toán',
    [messageResponse.someItemsInvalid]: 'Một số sản phẩm không hợp lệ',
    [messageResponse.invoiceNotFound]: 'Không tìm thấy hóa đơn',
    [messageResponse.notAllowedUpdatePaidInvoice]: 'Không được phép cập nhật hóa đơn đã thanh toán',
    [messageResponse.currencyCodeNotFound]: 'Không tìm thấy mã tiền tệ',
    [messageResponse.noPayableServiceInFreeList]:
      'Không có dịch vụ trả phí trong danh sách miễn phí',
    [messageResponse.cannotApplyThisPromotion]: 'Không thể áp dụng khuyến mãi này',
    [messageResponse.giftVoucherCodesCannotEmpty]: 'Mã quà tặng không được trống',
    [messageResponse.discountSourceTypeNotFound]: 'Không tìm thấy loại giảm giá',
    [messageResponse.giftVoucherIsUsed]: 'Voucher đã dùng',
    [messageResponse.giftVoucherIsSold]: 'Voucher đã bán',
    [messageResponse.cannotApplyForThisBranch]: 'Không áp dụng cho chi nhánh này',
    [messageResponse.cannotApplyOnWeekend]: 'Không áp dụng cuối tuần',
    [messageResponse.cannotApplyToHoliday]: 'Không áp dụng ngày lễ',
    [messageResponse.giftVoucherNotFound]: 'Không tìm thấy voucher',
    [messageResponse.invoiceNotIncludeGiftVoucherServicesDiscounts]:
      'Hóa đơn không bao gồm DV được giảm giá',
    [messageResponse.giftVoucherIsExpired]: 'Voucher đã hết hạn',
    [messageResponse.giftVoucherHasNotBeenPurchased]: 'Gift voucher không khả dụng',
    [messageResponse.lockerNoCanNotBeLargerThan20Characters]: 'Khoá tủ không được quá 20 ký tự'
  }
};

export default order;
