import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { ENDPOINT, HTTP_METHOD, HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import { IPaginationResponse } from 'src/interfaces';

import { GetListTherapistReportsDTO } from 'src/dto/reports/therapist.dto';
import { ITherapistReportsItem, ITherapistReportsRequest } from 'src/interfaces/reports/therapist';

import { IRoomReportsItem, IRoomReportsRequest } from 'src/interfaces/reports/roomBed';
import { GetListRoomReportsDTO } from 'src/dto/reports/roomBed.dto';

import { GetListRevenueReportsDTO } from 'src/dto/reports/revenue.dto';
import { IRevenueReportsItem, IRevenueReportRequest } from 'src/interfaces/reports/revenue';

import { GetListPromotionCampaignsReportsDTO } from 'src/dto/reports/promotionCampaign.dto';
import {
  IPromotionCampaignsReportsItem,
  IPromotionCampaignsReportsRequest
} from 'src/interfaces/reports/promotionCampaign';

import { GetListCustomerReportsDTO } from 'src/dto/reports/customer.dto';
import { ICustomerReportsItem, ICustomerReportsRequest } from 'src/interfaces/reports/customer';

import { GetListProductReportsDTO } from 'src/dto/reports/products.dto';
import { IProductReportsItem, IProductReportsRequest } from 'src/interfaces/reports/products';

import { IHttpService } from 'src/services/http.service';
import {
  IGiftVoucherReportsItem,
  IGiftVoucherReportsRequest
} from 'src/interfaces/reports/giftVoucher';
import { GetListGiftVoucherReportsDTO } from 'src/dto/reports/giftVoucher.dto';
import { uniqueKey } from 'src/helpers/string.utils';
import { isEmpty } from 'lodash';
import qs from 'qs';

export interface IReportsStore {
  listTherapistReports: ITherapistReportsItem[];
  listRoomBedReports: IRoomReportsItem[];
  listRevenueReports: IRevenueReportsItem[];
  listPromotionCampaignsReports: IPromotionCampaignsReportsItem[];
  listCustomerReports: ICustomerReportsItem[];
  listGiftVoucherReports: IGiftVoucherReportsItem[];
  listProductReports: IProductReportsItem[];
  fetchListTherapistReports(request?: ITherapistReportsRequest): Promise<void>;
  fetchListRoomBedReports(request?: IRoomReportsRequest): Promise<void>;
  fetchListRevenueReports(request?: IRevenueReportRequest): Promise<void>;
  fetchListPromotionCampaignsReports(request?: IPromotionCampaignsReportsRequest): Promise<void>;
  fetchListCustomerReports(request?: ICustomerReportsRequest): Promise<void>;
  fetchListGiftVoucherReports(request?: IGiftVoucherReportsRequest): Promise<void>;
  fetchListProductReports(request?: IProductReportsRequest): Promise<void>;

  exportDailyTherapistReport(request: ITherapistReportsRequest, fileName: string): Promise<void>;
  exportDailyRoomReport(request: IRoomReportsRequest, fileName: string): Promise<void>;
  exportDailyRevenueReport(request: IRevenueReportRequest, fileName: string): Promise<void>;
  exportDailyCustomerReport(request: ICustomerReportsRequest, fileName: string): Promise<void>;
  exportDailyProductReport(request: IProductReportsRequest, fileName: string): Promise<void>;
  exportDailyGiftVoucherReport(
    request: IGiftVoucherReportsRequest,
    fileName: string
  ): Promise<void>;
  exportDailyPromotionReport(
    request: IPromotionCampaignsReportsRequest,
    fileName: string
  ): Promise<void>;
}

export class ReportsStore implements IReportsStore {
  listTherapistReports: ITherapistReportsItem[] = [];
  listRoomBedReports: IRoomReportsItem[] = [];
  listRevenueReports: IRevenueReportsItem[] = [];
  listPromotionCampaignsReports: IPromotionCampaignsReportsItem[] = [];
  listCustomerReports: ICustomerReportsItem[] = [];
  listGiftVoucherReports: IGiftVoucherReportsItem[] = [];
  listProductReports: IProductReportsItem[] = [];

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listTherapistReports: observable.ref,
      fetchListTherapistReports: action.bound,

      listRoomBedReports: observable.ref,
      fetchListRoomBedReports: action.bound,

      listRevenueReports: observable.ref,
      fetchListRevenueReports: action.bound,

      listPromotionCampaignsReports: observable.ref,
      fetchListPromotionCampaignsReports: action.bound,

      listCustomerReports: observable.ref,
      fetchListCustomerReports: action.bound,

      listGiftVoucherReports: observable.ref,
      fetchListGiftVoucherReports: action.bound,

      listProductReports: observable.ref,
      fetchListProductReports: action.bound
    });
  }

  public async fetchListTherapistReports(request?: ITherapistReportsRequest) {
    const requestDTO = new GetListTherapistReportsDTO(request);
    const result: ResponseDTO<IPaginationResponse<ITherapistReportsItem>> = await this.http.request(
      requestDTO
    );
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listTherapistReports = result.data?.paginatedResults ?? [];
      });
    }
  }

  public async fetchListRoomBedReports(request?: IRoomReportsRequest) {
    const requestDTO = new GetListRoomReportsDTO(request);
    const result: ResponseDTO<IPaginationResponse<IRoomReportsItem>> = await this.http.request(
      requestDTO
    );
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listRoomBedReports = result.data?.paginatedResults ?? [];
      });
    }
  }

  public async fetchListRevenueReports(request?: IRevenueReportRequest) {
    const requestDTO = new GetListRevenueReportsDTO(request);
    const result: ResponseDTO<IPaginationResponse<IRevenueReportsItem>> = await this.http.request(
      requestDTO
    );
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listRevenueReports = result.data?.paginatedResults ?? [];
        this.listRevenueReports = this.listRevenueReports.map((report) => {
          return {
            ...report,
            id: uniqueKey(10, 'revenueReportItem')
          };
        });
      });
    }
  }

  public async fetchListPromotionCampaignsReports(request?: IPromotionCampaignsReportsRequest) {
    const requestDTO = new GetListPromotionCampaignsReportsDTO(request);
    const result: ResponseDTO<IPaginationResponse<IPromotionCampaignsReportsItem>> =
      await this.http.request(requestDTO);
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listPromotionCampaignsReports = result.data?.paginatedResults ?? [];
      });
    }
  }

  public async fetchListCustomerReports(request?: ICustomerReportsRequest) {
    const requestDTO = new GetListCustomerReportsDTO(request);
    const result: ResponseDTO<IPaginationResponse<ICustomerReportsItem>> = await this.http.request(
      requestDTO
    );
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listCustomerReports = result.data?.paginatedResults ?? [];
      });
    }
  }

  public async fetchListGiftVoucherReports(request?: IGiftVoucherReportsRequest) {
    const requestDTO = new GetListGiftVoucherReportsDTO(request);
    const result: ResponseDTO<IPaginationResponse<IGiftVoucherReportsItem>> =
      await this.http.request(requestDTO);
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listGiftVoucherReports = result.data?.paginatedResults ?? [];
      });
    }
  }

  public async fetchListProductReports(request?: IProductReportsRequest) {
    const requestDTO = new GetListProductReportsDTO(request);
    const result: ResponseDTO<IPaginationResponse<IProductReportsItem>> = await this.http.request(
      requestDTO
    );
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listProductReports = result.data?.paginatedResults ?? [];
      });
    }
  }

  /**
   * Expots report
   */
  public async exportDailyTherapistReport(request: ITherapistReportsRequest, name: string) {
    const searchParams = !isEmpty(request)
      ? qs.stringify(request, {
          allowDots: true
        })
      : '';
    await this.http.downloadFile(
      `${ENDPOINT.EXPORT_THERAPIST_REPORT}?${searchParams}`,
      {},
      HTTP_METHOD.GET,
      { name }
    );
  }

  public async exportDailyRevenueReport(request: IRevenueReportRequest, name: string) {
    const searchParams = !isEmpty(request)
      ? qs.stringify(request, {
          allowDots: true
        })
      : '';
    await this.http.downloadFile(
      `${ENDPOINT.EXPORT_REVENUES_REPORT}?${searchParams}`,
      {},
      HTTP_METHOD.GET,
      { name }
    );
  }

  public async exportDailyRoomReport(request: IRoomReportsRequest, name: string) {
    const searchParams = !isEmpty(request)
      ? qs.stringify(request, {
          allowDots: true
        })
      : '';
    await this.http.downloadFile(
      `${ENDPOINT.EXPORT_ROOM_BED_REPORT}?${searchParams}`,
      {},
      HTTP_METHOD.GET,
      { name }
    );
  }

  public async exportDailyCustomerReport(request: ICustomerReportsRequest, name: string) {
    const searchParams = !isEmpty(request)
      ? qs.stringify(request, {
          allowDots: true
        })
      : '';
    await this.http.downloadFile(
      `${ENDPOINT.EXPORT_CUSTOMER_REPORT}?${searchParams}`,
      {},
      HTTP_METHOD.GET,
      { name }
    );
  }

  public async exportDailyProductReport(request: IProductReportsRequest, name: string) {
    const searchParams = !isEmpty(request)
      ? qs.stringify(request, {
          allowDots: true
        })
      : '';
    await this.http.downloadFile(
      `${ENDPOINT.EXPORT_PRODUCT_REPORT}?${searchParams}`,
      {},
      HTTP_METHOD.GET,
      { name }
    );
  }

  public async exportDailyGiftVoucherReport(request: IGiftVoucherReportsRequest, name: string) {
    const searchParams = !isEmpty(request)
      ? qs.stringify(request, {
          allowDots: true
        })
      : '';
    await this.http.downloadFile(
      `${ENDPOINT.EXPORT_GIFT_VOUCHER_REPORT}?${searchParams}`,
      {},
      HTTP_METHOD.GET,
      { name }
    );
  }

  public async exportDailyPromotionReport(
    request: IPromotionCampaignsReportsRequest,
    name: string
  ) {
    const searchParams = !isEmpty(request)
      ? qs.stringify(request, {
          allowDots: true
        })
      : '';
    await this.http.downloadFile(
      `${ENDPOINT.EXPORT_PROMOTION_CAMPAIGN_REPORT}?${searchParams}`,
      {},
      HTTP_METHOD.GET,
      { name }
    );
  }
}
