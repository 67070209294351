export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export enum HTTP_STATUS_CODE {
  SUCCESS = '200',
  BAD_REQUEST = '400',
  NOT_FOUND = '404',
  AUTHORIZATION = '401',
  FORBIDDEN = '403',
  UNKNOWN = '520'
}

export enum HTTP_STATUS_RESPONSE_KEY {
  SUCCESS = '000200',
  BAD_REQUEST = '000400',
  NOT_FOUND = '000404',
  AUTHORIZATION = '000401',
  FORBIDDEN = '000403',
  UNKNOWN = '000500'
}

export enum ENDPOINT {
  ROOT = '/api',
  LOGIN = '/auth/login',
  LOGOUT = '/auth/logout',
  REFRESH_TOKEN = '/auth/refresh-token',

  //roles
  ROLES = '/roles',
  ROLES_DETAIL = '/roles/:id',

  PRODUCT = '/products',
  PRODUCT_DETAIL = '/products/:id',
  PRODUCT_EXPORT = '/products/export',
  PRODUCT_IMPORT = '/products/import',

  //Promotions
  PROMOTIONS = '/promotions',
  PROMOTIONS_DETAIL = '/promotions/:id',

  //Promotion-discount-categories
  GET_LIST_PROMOTION_DISCOUNT_CATS = '/promotion-discount-cats',
  GET_LIST_PROMOTION_TYPE = '/promotion-types',

  //Rooms
  ROOMS = '/rooms',
  ROOMS_DETAIL = '/rooms/:id',
  ROOMS_BED_APPOINMENT = '/rooms/:roomId/beds/:bedId/appointments',

  //employees
  EMPLOYEES = '/employees',
  EMPLOYEES_DETAIL = '/employees/:id',
  EMPLOYEES_PRIORITIZED_THERAPISTS = '/employees/prioritized-therapists',
  EMPLOYEES_PRIORITIZED_THERAPISTS_AVAILABLE = '/employees/prioritized-therapists/available',
  EMPLOYEES_EXPORT = '/employees/export',
  EMPLOYEES_IMPORT = '/employees/import',

  //discount cats
  DISCOUNT_CATS = '/discount-cats',
  DISCOUNT_CATS_DETAIL = '/discount-cats/:id',

  //deposit cats
  DEPOSIT_CATS = '/deposit-cats',
  DEPOSIT_CATS_DETAIL = '/deposit-cats/:id',

  //services
  SERVICES = '/services',
  SERVICES_WITH_PACKAGES = '/services/valid-packages-services',
  SERVICES_DETAIL = '/services/:id',
  SERVICES_EXPORT = '/services/export',
  SERVICES_IMPORT = '/services/import',

  //packeges
  PACKAGES = '/packages',
  PACKAGES_DETAIL = '/packages/:id',

  //branches
  BRANCHES = '/branches',
  BRANCHES_DETAIL = '/branches/:id',
  BRANCHES_ROOMS_BEDS = '/branches/:id/rooms/beds',
  BRANCHES_SERVICES_EMPLOYEES = '/branches/:branchId/services/:serviceId/employees',
  BRANCHES_SERVICES_THERAPISTS = '/branches/:branchId/services/:serviceId/therapists',
  BRANCHES_BEDS = '/branches/:id/beds',
  BRANCHES_WORK_SCHEDULES_THERAPISTS = '/branches/:id/work-schedules/therapists',
  BRANCHES_ROOM_AVAILABILITY = '/branches/:id/room-availability',
  BRANCHES_PROMOTIONS = '/branches/:branchId/promotions',

  //work-schedules
  WORK_SCHEDULES = '/work-schedules',
  WORK_SCHEDULES_IMPORT = '/work-schedules/import',
  WORK_SCHEDULES_EXPORT = '/work-schedules/export',
  WORK_SCHEDULES_SHIFT = '/work-schedules/shift',
  WORK_SCHEDULES_EMPLOYEES = '/work-schedules/employees',

  //customer cats
  CUSTOMER_CATS = '/customer-cats',
  CUSTOMER_CATS_DETAIL = '/customer-cats/:id',

  //product cats
  PRODUCT_CATS = '/product-cats',
  PRODUCT_CATS_DETAIL = '/product-cats/:id',

  //room cats
  ROOM_CATS = '/room-cats',
  ROOM_CATS_DETAIL = '/room-cats/:id',

  //membership
  MEMBERSHIP = '/memberships',
  MEMBERSHIP_DETAIL = '/memberships/:id',

  //product-containers
  PRODUCT_CONTAINERS = '/product-containers',
  PRODUCT_CONTAINERS_DETAIL = '/product-containers/:id',

  //services group
  SERVICES_GROUP = '/service-cats',
  SERVICES_GROUP_DETAIL = '/service-cats/:id',

  //profile
  PROFILE = '/my-profile',
  RESET_PASSWORD = '/my-profile/password',

  //permission
  PERMISSIONS = '/role-permissions',

  SEND_MAIL_RESET_PASSWORD = '/mails/password-reset-link',
  MAIL_RESET_PASSWORD = '/auth/reset-password',

  //customer
  CUSTOMER = '/customers',
  CUSTOMER_ID = '/customers/:id',
  CUSTOMER_DEPOSIT_TXNS_ID = '/customers/:id/deposit-txns',
  CUSTOMER_EXPORT = '/customers/export',
  CUSTOMER_IMPORT = '/customers/import',

  //countries
  COUNTRIES = '/countries',
  COUNTRIES_ID = '/countries/:id',

  // gift voucher
  GET_PREFIX_CODE_LIST = '/prefix-codes',
  GET_LIST_GIFT_VOUCHERS = '/prefix-codes/:prefixCode/gift-vouchers',
  GET_PREFIX_CODE_DETAILS = '/prefix-codes/:prefixCode',
  GET_GIFT_VOUCHER_DETAILS = '/prefix-codes/:prefixCode/gift-vouchers/:code',
  GET_LIST_GIFT_VOUCHER_CATS = '/gift-voucher-cats',
  GET_DISCOUNT_GIFT_VOUCHER = '/prefix-codes/gift-voucher-discount/:code',
  GET_LIST_GIFT_VOUCHERS_BY_WAREHOUSE_ID = '/prefix-codes/gift-vouchers/:warehouseId',
  UPDATE_GIFT_VOUCHER_CODE = '/prefix-codes/gift-vouchers/:code',
  GET_LIST_GIFT_VOUCHER_VALID = '/gift-vouchers/valid-codes',

  // bookings
  APPOINTMENTS = '/appointments',
  APPOINTMENTS_DETAIL = '/appointments/:id',
  APPOINTMENTS_SERVICE_INSTANCE_UPDATE = '/appointments/:appointmentId/services-instance/:serviceInstanceId',

  // theupeutic indications
  THERAPEUTIC_INDICATIONS = '/therapeutic-indications',

  // warehouses
  WAREHOUSES = '/warehouses',
  WAREHOUSES_DETAIL = '/warehouses/:id',
  WAREHOUSES_PREFIX_CODES = '/warehouses/:warehouseId/prefix-codes',
  WAREHOUSES_PRODUCTS = '/warehouses/:warehouseId/products',
  WAREHOUSES_IMPORT = '/warehouses/import-txns',
  WAREHOUSES_EXPORT = '/warehouses/export-txns',
  WAREHOUSES_TRANSACTION_CATEGORIES = '/warehouses/transaction-categories',

  // inventory-txns
  INVENTORY_TXNS = '/inventory-txns',
  INVENTORY_TXNS_DETAIL = '/inventory-txns/:id',
  INVENTORY_HISTORIES_DETAIL = '/inventory-txns/histories/:id',

  // inventories
  INVENTORIES = '/inventories',

  // notifications
  NOTIFICATIONS = '/notifications',
  READ_NOTIFICATIONS_BY_ID = '/notifications/:id/read',
  READ_ALL_NOTIFICATIONS = '/notifications/read',
  COUNT_NOTIFICATIONS = '/notifications/count',

  // orders
  INVOICES = '/invoices',
  INVOICES_DETAIL = '/invoices/:id',
  INVOICES_CALCULATE = '/invoices/calculate',
  GET_EXIST_APPOINTMENT_INVOICES = '/invoices/exist-appointments',

  //Reports
  //Therapist
  THERAPIST_REPORT = '/reports/therapist-services',
  EXPORT_THERAPIST_REPORT = '/reports/therapist-services/export',

  //RoomService
  ROOM_BED_REPORT = '/reports/room-services',
  EXPORT_ROOM_BED_REPORT = '/reports/room-services/export',

  //revenue
  REVENUES_REPORT = '/reports/revenues',
  EXPORT_REVENUES_REPORT = '/reports/revenues/export',

  //promotion Campaign
  PROMOTION_CAMPAIGN_REPORT = '/reports/promotion-campaigns',
  EXPORT_PROMOTION_CAMPAIGN_REPORT = '/reports/promotion-campaigns/export',

  EXPORT_CUSTOMER_REPORT = '/reports/customers/export',
  EXPORT_PRODUCT_REPORT = '/reports/products/export',
  EXPORT_GIFT_VOUCHER_REPORT = '/reports/gift-vouchers/export',

  // permission
  GET_USER_PERMISSION = '/role-permissions',

  //customer
  CUSTOMER_REPORT = '/reports/customers',

  //gift voucher
  GIFT_VOUCHER_REPORT = '/reports/gift-vouchers',

  //products
  PRODUCT_REPORT = '/reports/products'
}
