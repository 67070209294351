import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PAGE_ROUTE } from 'src/constants/route';
import BareLayout from 'src/layouts/bare/bare.layout';
import ProtectedRoute from './ProtectedRoute';
import MainLayout from 'src/layouts/main/main.layout';
import { uniqueKey } from 'src/helpers/string.utils';
import Loader from 'src/components/loader';

const NotFoundPage = lazy(() => import('src/pages/404/404'));

type RouteType = {
  index?: boolean;
  path?: string;
  element: React.LazyExoticComponent<React.FC>;
  children?: RouteType[];
};

const publicRoutes: RouteType[] = [
  {
    path: PAGE_ROUTE.LOGIN,
    element: lazy(() => import('src/pages/base.page')),
    children: [
      {
        index: true,
        element: lazy(() => import('src/pages/login/login'))
      }
    ]
  },
  {
    path: PAGE_ROUTE.RESET_PASSWORD,
    element: lazy(() => import('src/pages/base.page')),
    children: [
      {
        index: true,
        element: lazy(() => import('src/pages/reset-password'))
      }
    ]
  }
];

const privateRoutes: RouteType[] = [
  {
    path: PAGE_ROUTE.DASHBOARD,
    element: lazy(() => import('src/pages/dashboard/dashboard'))
  },
  {
    path: PAGE_ROUTE.PROFILE,
    element: lazy(() => import('src/pages/profile/my-profile'))
  },

  //Room & bed
  {
    path: PAGE_ROUTE.ROOM_BED,
    element: lazy(() => import('src/pages/pos/room-bed/room-bed'))
  },
  {
    path: PAGE_ROUTE.ROOM_BED_ID,
    element: lazy(() => import('src/pages/pos/room-bed/room-bed'))
  },
  //Order
  {
    path: PAGE_ROUTE.INVOICES,
    element: lazy(() => import('src/pages/pos/order/order'))
  },
  {
    path: PAGE_ROUTE.INVOICE_CREATE,
    element: lazy(() => import('src/pages/pos/order/order-form/order-form'))
  },
  {
    path: PAGE_ROUTE.INVOICE_UPDATE,
    element: lazy(() => import('src/pages/pos/order/order-form/order-form'))
  },

  /* MASTER-DATA */

  //Services
  {
    path: PAGE_ROUTE.SERVICES,
    element: lazy(() => import('src/pages/services/services'))
  },
  {
    path: PAGE_ROUTE.SERVICES_CREATE,
    element: lazy(() => import('src/pages/services/services-form/services-form'))
  },
  {
    path: PAGE_ROUTE.SERVICES_UPDATE,
    element: lazy(() => import('src/pages/services/services-form/services-form'))
  },

  /* MASTER-DATA */
  //Products
  {
    path: PAGE_ROUTE.PRODUCTS,
    element: lazy(() => import('src/pages/master-data/product/product'))
  },
  {
    path: PAGE_ROUTE.PRODUCT_CREATE,
    element: lazy(() => import('src/pages/master-data/product/product-form/product-form'))
  },
  {
    path: PAGE_ROUTE.PRODUCT_UPDATE,
    element: lazy(() => import('src/pages/master-data/product/product-form/product-form'))
  },

  //Rooms
  {
    path: PAGE_ROUTE.ROOMS,
    element: lazy(() => import('src/pages/master-data/rooms/rooms'))
  },
  {
    path: PAGE_ROUTE.ROOM_CREATE,
    element: lazy(() => import('src/pages/master-data/rooms/rooms-form/rooms-form'))
  },
  {
    path: PAGE_ROUTE.ROOM_UPDATE,
    element: lazy(() => import('src/pages/master-data/rooms/rooms-form/rooms-form'))
  },

  //services group
  {
    path: PAGE_ROUTE.SERVICE_GROUPS,
    element: lazy(() => import('src/pages/master-data/service-group/service-group'))
  },
  {
    path: PAGE_ROUTE.SERVICE_GROUP_CREATE,
    element: lazy(
      () => import('src/pages/master-data/service-group/service-group-form/service-group-form')
    )
  },
  {
    path: PAGE_ROUTE.SERVICE_GROUP_UPDATE,
    element: lazy(
      () => import('src/pages/master-data/service-group/service-group-form/service-group-form')
    )
  },

  //Branches
  {
    path: PAGE_ROUTE.BRANCHES,
    element: lazy(() => import('src/pages/master-data/branch/branch'))
  },
  {
    path: PAGE_ROUTE.BRANCH_CREATE,
    element: lazy(() => import('src/pages/master-data/branch/branch-form/branch-form'))
  },
  {
    path: PAGE_ROUTE.BRANCH_UPDATE,
    element: lazy(() => import('src/pages/master-data/branch/branch-form/branch-form'))
  },

  //Employees management
  {
    path: PAGE_ROUTE.EMPLOYEES,
    element: lazy(() => import('src/pages/employees/employees'))
  },
  {
    path: PAGE_ROUTE.EMPLOYEES_CREATE,
    element: lazy(() => import('src/pages/employees/employees-form/employees-form'))
  },
  {
    path: PAGE_ROUTE.EMPLOYEES_UPDATE,
    element: lazy(() => import('src/pages/employees/employees-form/employees-form'))
  },

  //Arrange Tour
  {
    path: PAGE_ROUTE.ARRANGE_TOUR,
    element: lazy(() => import('src/pages/arrange-tour/arrange-tour'))
  },

  //packages
  {
    path: PAGE_ROUTE.PACKAGES,
    element: lazy(() => import('src/pages/packages/packages'))
  },
  {
    path: PAGE_ROUTE.PACKAGES_CREATE,
    element: lazy(() => import('src/pages/packages/package-form/package-form'))
  },
  {
    path: PAGE_ROUTE.PACKAGES_UPDATE,
    element: lazy(() => import('src/pages/packages/package-form/package-form'))
  },

  //work schedules
  {
    path: PAGE_ROUTE.WORK_SCHEDULUES,
    element: lazy(() => import('src/pages/work-schedules/work-schedules'))
  },
  {
    path: PAGE_ROUTE.WORK_SCHEDULUES_EDIT,
    element: lazy(() => import('src/pages/work-schedules/work-schedules-edit/work-schedules-edit'))
  },

  // Customer cats
  {
    path: PAGE_ROUTE.CUSTOMER_CATS,
    element: lazy(() => import('src/pages/master-data/customer-cats'))
  },
  {
    path: PAGE_ROUTE.CUSTOMER_CATS_CREATE,
    element: lazy(() => import('src/pages/master-data/customer-cats/customer-cats-form'))
  },
  {
    path: PAGE_ROUTE.CUSTOMER_CATS_UPDATE,
    element: lazy(() => import('src/pages/master-data/customer-cats/customer-cats-form'))
  },

  // Product cats
  {
    path: PAGE_ROUTE.PRODUCT_CATS,
    element: lazy(() => import('src/pages/master-data/product-cats'))
  },
  {
    path: PAGE_ROUTE.PRODUCT_CATS_CREATE,
    element: lazy(() => import('src/pages/master-data/product-cats/product-cats-form'))
  },
  {
    path: PAGE_ROUTE.PRODUCT_CATS_UPDATE,
    element: lazy(() => import('src/pages/master-data/product-cats/product-cats-form'))
  },

  //Customer
  {
    path: PAGE_ROUTE.CUSTOMER,
    element: lazy(() => import('src/pages/customer/customer'))
  },
  {
    path: PAGE_ROUTE.CUSTOMER_CREATE,
    element: lazy(() => import('src/pages/customer/create/create'))
  },
  {
    path: PAGE_ROUTE.CUSTOMER_DETAIL,
    element: lazy(() => import('src/pages/customer/detail/detail'))
  },
  {
    path: PAGE_ROUTE.CUSTOMER_EDIT,
    element: lazy(() => import('src/pages/customer/create/create'))
  },
  // Room cats
  {
    path: PAGE_ROUTE.ROOM_CATS,
    element: lazy(() => import('src/pages/master-data/room-cats'))
  },
  {
    path: PAGE_ROUTE.ROOM_CATS_CREATE,
    element: lazy(() => import('src/pages/master-data/room-cats/room-cats-form'))
  },
  {
    path: PAGE_ROUTE.ROOM_CATS_UPDATE,
    element: lazy(() => import('src/pages/master-data/room-cats/room-cats-form'))
  },

  // Membership
  {
    path: PAGE_ROUTE.MEMBERSHIPS,
    element: lazy(() => import('src/pages/master-data/membership'))
  },
  {
    path: PAGE_ROUTE.MEMBERSHIP_CREATE,
    element: lazy(() => import('src/pages/master-data/membership/membership-form'))
  },
  {
    path: PAGE_ROUTE.MEMBERSHIP_UPDATE,
    element: lazy(() => import('src/pages/master-data/membership/membership-form'))
  },

  // Customer cats
  {
    path: PAGE_ROUTE.PRODUCT_CONTAINERS,
    element: lazy(() => import('src/pages/master-data/product-containers'))
  },
  {
    path: PAGE_ROUTE.PRODUCT_CONTAINER_CREATE,
    element: lazy(() => import('src/pages/master-data/product-containers/product-containers-form'))
  },
  {
    path: PAGE_ROUTE.PRODUCT_CONTAINER_UPDATE,
    element: lazy(() => import('src/pages/master-data/product-containers/product-containers-form'))
  },

  // Promotions
  {
    path: PAGE_ROUTE.PROMOTIONS,
    element: lazy(() => import('src/pages/promotion/promotion'))
  },
  {
    path: PAGE_ROUTE.PROMOTION_CREATE,
    element: lazy(() => import('src/pages/promotion/promotion-form/promotion-form'))
  },
  {
    path: PAGE_ROUTE.PROMOTION_UPDATE,
    element: lazy(() => import('src/pages/promotion/promotion-form/promotion-form'))
  },

  // Gift voucher groups
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_GROUPS,
    element: lazy(() => import('src/pages/master-data/gift-voucher-groups/gift-voucher-groups'))
  },
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_GROUP_CREATE,
    element: lazy(
      () =>
        import(
          'src/pages/master-data/gift-voucher-groups/gift-voucher-groups-form/gift-voucher-groups-form'
        )
    )
  },
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_GROUP_UPDATE,
    element: lazy(
      () =>
        import(
          'src/pages/master-data/gift-voucher-groups/gift-voucher-groups-form/gift-voucher-groups-form'
        )
    )
  },
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_GROUP_DETAILS,
    element: lazy(
      () =>
        import(
          'src/pages/master-data/gift-voucher-groups/gift-voucher-groups-detail/gift-voucher-groups-detail'
        )
    )
  },

  // Deposit category
  {
    path: PAGE_ROUTE.DEPOSIT_CATS,
    element: lazy(() => import('src/pages/master-data/deposit-cats/deposit-cats'))
  },
  {
    path: PAGE_ROUTE.DEPOSIT_CAT_CREATE,
    element: lazy(
      () => import('src/pages/master-data/deposit-cats/deposit-cats-form/deposit-cats-form')
    )
  },
  {
    path: PAGE_ROUTE.DEPOSIT_CAT_UPDATE,
    element: lazy(
      () => import('src/pages/master-data/deposit-cats/deposit-cats-form/deposit-cats-form')
    )
  },

  // Gift voucher
  {
    path: PAGE_ROUTE.GIFT_VOUCHERS,
    element: lazy(() => import('src/pages/product-group/gift-voucher'))
  },
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_CREATE,
    element: lazy(() => import('src/pages/product-group/gift-voucher/gift-voucher-form'))
  },
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_UPDATE,
    element: lazy(() => import('src/pages/product-group/gift-voucher/gift-voucher-form'))
  },
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_DETAILS,
    element: lazy(() => import('src/pages/product-group/gift-voucher/gift-voucher-details'))
  },

  // Warehouse history
  {
    path: PAGE_ROUTE.WAREHOUSE_HISTORY,
    element: lazy(() => import('src/pages/product-group/warehouse-history'))
  },
  {
    path: PAGE_ROUTE.WAREHOUSE_HISTORY_ACTION,
    element: lazy(() => import('src/pages/product-group/warehouse-history/warehouse-history-form'))
  },
  {
    path: PAGE_ROUTE.WAREHOUSE_HISTORY_DETAIL,
    element: lazy(
      () =>
        import('src/pages/product-group/warehouse-history/transaction-detail/transaction-detail')
    )
  },
  // Booking
  {
    path: PAGE_ROUTE.BOOKING,
    element: lazy(() => import('src/pages/booking/booking'))
  },
  {
    path: PAGE_ROUTE.BOOKING_CREATE,
    element: lazy(() => import('src/pages/booking/booking-form/booking-form'))
  },
  {
    path: PAGE_ROUTE.BOOKING_UPDATE,
    element: lazy(() => import('src/pages/booking/booking-form/booking-form'))
  },
  // Inventory
  {
    path: PAGE_ROUTE.INVENTORY,
    element: lazy(() => import('src/pages/product-group/inventory/inventory'))
  },
  {
    path: PAGE_ROUTE.INVENTORY_HISTORIES_DETAIL,
    element: lazy(
      () => import('src/pages/product-group/inventory/inventory-detail/inventory-detail')
    )
  },
  {
    path: PAGE_ROUTE.THERAPIST_REPORTS,
    element: lazy(() => import('src/pages/report/therapist/therapist-report'))
  },
  // Revenue
  {
    path: PAGE_ROUTE.REVENUE_REPORTS,
    element: lazy(() => import('src/pages/report/revenue/revenue-report'))
  },
  {
    path: PAGE_ROUTE.PRODUCT_REPORTS,
    element: lazy(() => import('src/pages/report/product/product-report'))
  },
  {
    path: PAGE_ROUTE.GIFT_VOUCHER_REPORTS,
    element: lazy(() => import('src/pages/report/gift-voucher/gift-voucher-report'))
  },
  {
    path: PAGE_ROUTE.CUSTOMER_REPORTS,
    element: lazy(() => import('src/pages/report/customer-report/customer-report'))
  },
  {
    path: PAGE_ROUTE.BED_ROOM_PERFORMANCE_REPORTS,
    element: lazy(() => import('src/pages/report/bed-room-performance/bed-room-performance'))
  },
  {
    path: PAGE_ROUTE.PROMOTION_CAMPAIGN_REPORTS,
    element: lazy(() => import('src/pages/report/promotion-campaign/promotion-campaign-report'))
  },
  //PERMISSIONS
  {
    path: PAGE_ROUTE.PERMISSIONS,
    element: lazy(() => import('src/pages/system-management/users-permissions/users-permissions'))
  }
];

const renderRoute = (routes: RouteType[]) =>
  routes.map((r) => {
    const routeOptions = r.index ? { index: true } : { path: r.path };
    const Element = r.element;
    return (
      <Route
        key={uniqueKey(10)}
        path={routeOptions.path}
        element={
          <Suspense fallback={<Loader />}>
            <Element />
          </Suspense>
        }>
        {r.children?.map(({ element: ChildElement, ...rest }) => {
          return rest.index ? (
            <Route
              key={uniqueKey(10)}
              index
              element={
                <Suspense fallback={<Loader />}>
                  <ChildElement />
                </Suspense>
              }
            />
          ) : (
            <Route
              key={uniqueKey(10)}
              element={
                <Suspense fallback={<Loader />}>
                  <ChildElement />
                </Suspense>
              }>
              {rest.children && renderRoute(rest.children)}
            </Route>
          );
        })}
      </Route>
    );
  });

const AppRouters: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<BareLayout />}>{renderRoute(publicRoutes)}</Route>
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }>
          {renderRoute(privateRoutes)}
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <NotFoundPage />
            </Suspense>
          }></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouters;
