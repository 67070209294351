import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { DISCOUNT_CATS, DISCOUNT_CATS_VALUE } from 'src/constants';

export const DATE_FORMAT_1 = 'yyyy-MM-DD';
export const DD_MM_YYYY_HH_mm_ss = 'DD-MM-YYYY HH:mm:ss';
export const DD_MM_YYYY = 'DD-MM-YYYY';
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const HH_mm_ss = 'HH:mm:ss';

export function normalizeFormatDate(value?: string) {
  return moment(value).format('DD-MMM-YYYY HH:mm:ss');
}

export function normalizeFormatDateDDMMYYYYHHmm(value?: string) {
  return moment(value).format('DD-MM-YYYY HH:mm');
}

export function normalizeFormatDateDDMMYYYY(value?: string) {
  return moment(value).format('DD-MM-YYYY');
}

export function normalizeFormatDateDDMMMYYYY(value?: string) {
  return moment(value).format('DD-MMM-YYYY');
}

export function normalizeFormatDateDD_MM_YYYY(value?: string) {
  return moment(value).format('DD/MM/YYYY');
}

export function normalizeFormatDateTime(value?: string | Dayjs) {
  return dayjs(value).format('HH:mm');
}

export function normalizeFormatDateDDMM(value?: string) {
  return value !== '' ? moment(value).format('DD/MM') : '';
}

export function convertNumberTime(value: number) {
  return value < 10 ? `0${value}` : value;
}

export function countDates(from?: string, to?: string) {
  const dateFrom = moment(from);
  const dateTo = moment(to);
  return dateTo.diff(dateFrom, 'days') + 1;
}

export function normalizeDateWithFormat(value?: string, format?: string) {
  return moment(value).format(format);
}

export function getYearsFromCurrent() {
  const years = [];
  const year = moment().year();
  for (let i = 0; i < 10; i++) {
    years.push({
      label: `${year + i}`,
      value: `${year + i}`,
      key: `${year + i}`
    });
  }
  return years;
}

export function preventHeadWhiteSpace(value: string) {
  return value.trimStart();
}

export const blockCharacterWhiteSpace = (value: string) => {
  return value.replace(/[^\d.]+$/, '').trimStart();
};

export function getBase64(file: any) {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = '';
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

export const formattedNumber = (number: number) => {
  return number
    ? Number(number)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0;
};

export const formattedSerieNumber = (serie: number) => {
  return serie.toString().padStart(6, '0');
};

export const setMinutes = (minutes: number) => {
  return moment().minutes(minutes);
};

export const getMinutes = (timepicker: any) => {
  return timepicker.format('mm');
};

export const formatHourMinutes = (date: any) => {
  return moment(date).format('HH:mm');
};

export const currencyFormatted = (
  num: number,
  type: DISCOUNT_CATS,
  symbolPosition?: 'prefix' | 'suffix'
) => {
  let numFormatted = formattedNumber(num);

  if (symbolPosition === 'prefix') {
    numFormatted = `${DISCOUNT_CATS_VALUE[type]} ${numFormatted}`;
  } else {
    numFormatted += ` ${DISCOUNT_CATS_VALUE[type]}`;
  }

  return numFormatted;
};

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function checkAndParseJSON(value: any) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

export function percentage(value: number) {
  return value / 100;
}

export function permille(value: number) {
  return value / 1000;
}

export const createQueryString = (queryObject: any = {}) => {
  const queryString = Object.keys(queryObject)
    .filter(
      (key) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length)
    )
    .map((key) => {
      return Array.isArray(queryObject[key])
        ? queryObject[key]
            .map((item: any) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
            .join('&')
        : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
    })
    .join('&');
  return queryString ? `?${queryString}` : '';
};
