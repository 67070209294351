import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import {
  IEmployeeBody,
  IEmployeeRequest,
  IEmployeesPrioritizedTherapistsAvailableRequest,
  IEmployeesPrioritizedTherapistsRequest,
  IExportEmployeeRequest
} from 'src/interfaces/employee/employee';

export class GetListRoleDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.ROLES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
  }
}

export class GetListEmployeesDTO extends DTO {
  public param: object | undefined;
  public query: IEmployeeRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.EMPLOYEES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IEmployeeRequest) {
    super();
    this.query = query;
  }
}

export class GetListEmployeesPrioritizedTherapistsDTO extends DTO {
  public param: object | undefined;
  public query: IEmployeesPrioritizedTherapistsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.EMPLOYEES_PRIORITIZED_THERAPISTS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IEmployeesPrioritizedTherapistsRequest) {
    super();
    this.query = query;
  }
}

export class GetListEmployeesPrioritizedTherapistsAvailableDTO extends DTO {
  public param: object | undefined;
  public query: IEmployeesPrioritizedTherapistsAvailableRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.EMPLOYEES_PRIORITIZED_THERAPISTS_AVAILABLE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IEmployeesPrioritizedTherapistsAvailableRequest) {
    super();
    this.query = query;
  }
}
export class CreateEmployeesDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IEmployeeBody;
  public url: string = ENDPOINT.EMPLOYEES;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IEmployeeBody) {
    super();
    this.body = body;
  }
}

export class DetailEmployeesDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.EMPLOYEES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateEmployeesDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IEmployeeBody;
  public url: string = ENDPOINT.EMPLOYEES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IEmployeeBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class ExportEmployeesDTO extends DTO {
  public param: undefined;
  public query: IExportEmployeeRequest;
  public body: undefined;
  public url: string = ENDPOINT.EMPLOYEES_EXPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query: IExportEmployeeRequest) {
    super();
    this.query = query;
  }
}

export class ImportEmployeesDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.EMPLOYEES_IMPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}
