const Report = {
  reportType: {
    bedRoomPerformance: 'Room/Bed Performance',
    giftVoucher: 'Gift Voucher',
    product: 'Product',
    customer: 'Customer',
    promotionCampaign: 'Monthly Program',
    therapist: 'Therapist',
    revenue: 'Revenue'
  },

  revenue: {
    estimatedRevenue: 'Revenue',
    tourFee: 'Tour Fee',
    discount: 'Discounts',
    discountCat: {
      membershipLevel: 'Membership Level',
      promotionCampaign: 'Campaign',
      giftVoucher: 'Gift Voucher'
    },
    viaDeposit: 'Payment using funds from MH Spa account',
    finalizedRevenue: 'Actual Revenue',
    nonViaPOS: 'Payment by Visa',
    viaPOS: 'Payment in Cash'
  },

  therapist: {
    employeeCode: 'Employee Code',
    name: 'Therapist Name',
    alias: 'Therapist Code',
    branch: 'Branch',
    serviceQty: 'Number of Services Performed',
    serviceCat: 'Service Category',
    serviceDetails: 'Service Details',
    totalExtraFees: 'Requested Amount',
    totalCommissionFees: 'Commission Fees',
    TIPS: 'Tips',
    package: 'Combo package',
    packageDetails: 'Package Details'
  },

  product: {
    code: 'Product Code',
    name: 'Product Name',
    importTransactionQty: 'Import Quantity',
    paymentTransactionQty: 'Payment Quantity',
    exportTransactionQty: 'Quantity Transferred',
    inventoryQty: 'Inventory Quantity'
  },

  giftVoucher: {
    prefixCode: 'Gift Voucher Code',
    importQty: 'Import Quantity',
    exportQty: 'Payment Quantity',
    paymentQty: 'Quantity Transferred',
    stockQty: 'Stock Quantity',
    redeemedQty: 'Quantity of gifts returned',
    notRedeemedQty: 'Quantity of gifts not yet returned',
    expiredButNotRedeemedQty: 'Quantity expired'
  },

  bedRoomPerformance: {
    bedQty: 'Số lượng Giường',
    noOfServicesPerformed: 'Số lượng DV đã thực hiện',
    totalBedHours: 'Tổng số giờ sử dụng các Giường'
  },

  customer: {
    groupName: 'Group Name',
    name: 'Name',
    totalAmountBeforeDiscount: 'Total Revenue (excluding discount + VAT)',
    discountBySourceType: 'Total discount by type',
    finalizedAmount: 'Actual revenue of the customer',
    finalizedAmountByPaymentMethod: 'Total payment by each payment method',
    beforeDeposit: 'Opening balance of the account',
    depositValue: 'Total deposit amount into the account',
    totalPaidByDeposit: 'Total payment made from the account balance',
    afterDeposit: 'Remaining balance in the account'
  },

  promotionCampaign: {
    promotionName: 'Program Name',
    refLink: 'Program details link',
    finalizedRevenue: 'Actual revenue from invoices that apply the program',
    depositUsedAmount: 'Revenue from deposit money used in invoices that apply the program',
    totalDiscount: 'Total discount when applying the program',
    customersByCat: 'Total number of Customer Category/Customers applying the program'
  },

  export: {
    therapistReport: 'Daily Therapist Report',
    roomReport: 'Daily Room Report',
    customerReport: 'Daily Customer Report',
    revenueReport: 'Daily Revenue Report',
    productReport: 'Daily Product Report',
    giftVoucherReport: 'Daily Gift Voucher Report',
    promotionReport: 'Daily Promotion Campaign Report'
  }
};

export default Report;
