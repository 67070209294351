const depositCats = {
    title: 'Danh sách Phân hạng nạp tiền',
    createTitle: 'Thêm mới Phân hạng nạp tiền',
    updateTitle: 'Chỉnh sửa Phân hạng nạp tiền',
    table: {
        depositAmountFrom: 'Số tiền nạp từ (VNĐ)',
        from: 'Đến (VNĐ)',
    }
}

export default depositCats;