import { IProductReportsRequest } from 'src/interfaces/reports/products';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListProductReportsDTO extends DTO {
  public param: object | undefined;
  public query: IProductReportsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.PRODUCT_REPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType?: ResponseType = 'json';
  constructor(query?: IProductReportsRequest) {
    super();
    this.query = query;
  }
}
