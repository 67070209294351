export enum OrderStatusEnum {
  UNPAID = 'UNPAID',
  PAID = 'PAID'
}

export enum PaymentMethodEnum {
  VIA_POS = 'VIA-POS',
  NON_VIA_POS = 'NON-VIA-POS',
  OTHER = 'OTHER'
}

export enum CurrencyCodeEnum {
  VND = 'VND',
  USD = 'USD'
}

export enum FeeCatEnum {
  PERCENTAGE_BASED = 'PERCENTAGE-BASED',
  FIXED_VALUE = 'FIXED-VALUE'
}

export enum DiscountSourceTypeEnum {
  MEMBERSHIP_LEVEL = 'MEMBERSHIP',
  PROMOTION = 'PROMOTION',
  GIFT_VOUCHER = 'GIFT-VOUCHER',
  NO_DISCOUNT = 'NO_DISCOUNT'
}

export enum ItemCatEnum {
  SERVICE = 'SERVICE',
  GIFT_VOUCHER = 'GIFT-VOUCHER',
  DEPOSIT = 'DEPOSIT',
  PRODUCT = 'PRODUCT',
  PACKAGE = 'PACKAGE',
}

export type RenderPaymentMethodType = {
  paymentMethod: PaymentMethodEnum;
  conversionRate: number;
  currencies: {
    code: CurrencyCodeEnum;
    originalAmount: number;
  }[];
};

export enum OperatorEnum {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT'
}

export const DEFAULT_SERVICE_EXTRAFEE = 10;
export const DEFAULT_THERAPIST_EXTRAFEE = 30000;
export const DEFAULT_CONVERSION_RATE = 23000;
