import { messageResponse } from 'src/constants/message-response';

const rooms = {
  managementRoom: 'Room Management ',
  createRoom: 'Create Room',
  updateRoom: 'Update Room',

  table: {
    quantity: 'Bed Quantity'
  },

  placeholder: {
    search: 'Search by Room Name'
  },

  toastMessage: {
    [messageResponse.roomNameExist]: 'Room name already exists in the same branch'
  }
};

export default rooms;
