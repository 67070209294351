import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import { DetailDiscountCatsDTO, GetListDiscountCatsDTO } from 'src/dto/discount-cats.dto';
import { IDiscountCats, IDiscountCatsItem } from 'src/interfaces/discount-cats';
import { IHttpService } from 'src/services/http.service';

export interface IDiscountCatsStore {
  listDiscountCats: IDiscountCatsItem[];
  fetchList(): Promise<void>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IDiscountCatsItem>>;
}

export class DiscountCatsStore implements IDiscountCatsStore {
  listDiscountCats: IDiscountCatsItem[] = [];

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listDiscountCats: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(): Promise<void> {
    const requestDTO = new GetListDiscountCatsDTO();
    const result: ResponseDTO<IDiscountCats> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listDiscountCats = result.data?.paginatedResults ?? [];
      });
    }
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailDiscountCatsDTO(query);
    const res: ResponseDTO<IDiscountCatsItem> = await this.http.request(detailDTO);
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listDiscountCats = [];
    });
  }
}
