import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { ResponseDTO } from 'src/dto/base.dto';
import { GetListTherapeuticIndicationDTO } from 'src/dto/master-data/theupeutic-indication';
import { IPaginationResponse } from 'src/interfaces';
import {
  ITherapeuticIndication,
  ITherapeuticIndicationRequest
} from 'src/interfaces/master-data/therapeutic-indication';
import { IHttpService } from 'src/services/http.service';

export interface ITherapeuticIndicationStore {
  loading: boolean;
  fetched: boolean;
  listTherapeuticIdications: ITherapeuticIndication[];
  fetchList(request?: ITherapeuticIndicationRequest): Promise<void>;
  destroyStoreWhenLogout(): void;
}

export class TherapeuticIndicationStore implements ITherapeuticIndicationStore {
  listTherapeuticIdications: ITherapeuticIndication[] = [];
  loading = false;
  fetched = false;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listTherapeuticIdications: observable.ref,
      fetched: observable.ref,
      loading: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: ITherapeuticIndicationRequest): Promise<void> {
    this.loading = true;
    const requestDTO = new GetListTherapeuticIndicationDTO(request);
    const result: ResponseDTO<
      IPaginationResponse<ITherapeuticIndication> & ITherapeuticIndication[]
    > = await this.http.request(requestDTO);
    runInAction(() => {
      this.listTherapeuticIdications = result?.data?.paginatedResults || result?.data || [];
      this.loading = false;
      this.fetched = true;
    });
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listTherapeuticIdications = [];
      this.fetched = false;
      this.loading = true;
    });
  }
}
