const GiftVoucherGroups = {
  managementGiftVoucherGroups: 'Quản lý Nhóm Gift voucher',
  createGiftVoucherGroups: 'Thêm mới nhóm Gift voucher',
  updateGiftVoucherGroups: 'Chỉnh sửa nhóm Gift voucher',

  form: {
    typeOfGiftVoucherGroups: 'Loại Gift Voucher',
    includeServiceExtraFee: 'Bao gồm phí dịch vụ',
    discountOnOrder: 'Giảm giá khi có dịch vụ trong đơn hàng',
    discountOnService: 'Giảm giá đối với dịch vụ cụ thể'
  },

  placeholder: {
    search: 'Tìm kiếm theo Ký hiệu',
    giftVoucherGroupsInput: 'Chọn loại Gift Voucher',
  },

  details: {
    title: 'Chi tiết Nhóm Gift Voucher',
  },
};

export default GiftVoucherGroups;
