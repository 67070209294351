import { IHttpService } from './http.service';
import { ResponseDTO } from 'src/dto/base.dto';
import { IUserInfo } from 'src/interfaces/user';
import { IAuthenticationService } from './authentication.service';
import {
  ChangePasswordDTO,
  GetProfileDTO,
  UpdateProfileDTO,
  UpdateProfileResponse,
  SendMailResetPasswordDTO,
  SendMailResetPasswordResponse,
  ChangePasswordFromMail,
  IChangePasswordFromMail
} from 'src/dto/profile.dto';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';

export interface IUserService {
  getUserProfile(): Promise<ResponseDTO<IUserInfo>>;
  updateUserProfile(body: FormData): Promise<ResponseDTO<UpdateProfileResponse>>;
  resetPassword(body: FormData): Promise<ResponseDTO<UpdateProfileResponse>>;
  resetPasswordFromMail(body: IChangePasswordFromMail): Promise<ResponseDTO<UpdateProfileResponse>>;
  sendMailResetPassword(body: FormData): Promise<ResponseDTO<SendMailResetPasswordResponse>>;
}
export class UserService implements IUserService {
  constructor(
    private readonly httpService: IHttpService,
    private readonly authService: IAuthenticationService
  ) {}

  public async getUserProfile(): Promise<ResponseDTO<IUserInfo>> {
    const getProfileDTO = new GetProfileDTO();

    const res = await this.httpService.request<GetProfileDTO, IUserInfo>(getProfileDTO);

    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      this.authService.setListPermission(res.data?.permissions ?? []);
    }
    return res;
  }
  public async updateUserProfile(body: FormData): Promise<ResponseDTO<UpdateProfileResponse>> {
    const updateProfileDTO = new UpdateProfileDTO(body);
    const result = await this.httpService.request<UpdateProfileDTO, UpdateProfileResponse>(
      updateProfileDTO
    );
    return result;
  }

  public async resetPassword(body: FormData): Promise<ResponseDTO<UpdateProfileResponse>> {
    const changePasswordDTO = new ChangePasswordDTO(body);
    const result = await this.httpService.request<ChangePasswordDTO, UpdateProfileResponse>(
      changePasswordDTO
    );
    return result;
  }

  public async resetPasswordFromMail(
    body: IChangePasswordFromMail
  ): Promise<ResponseDTO<UpdateProfileResponse>> {
    const changePasswordDTO = new ChangePasswordFromMail(body);
    const result = await this.httpService.request<ChangePasswordFromMail, UpdateProfileResponse>(
      changePasswordDTO
    );
    return result;
  }

  public async sendMailResetPassword(body: FormData): Promise<ResponseDTO<UpdateProfileResponse>> {
    const formBody = new SendMailResetPasswordDTO(body);
    const result = await this.httpService.request<
      SendMailResetPasswordDTO,
      SendMailResetPasswordResponse
    >(formBody);
    return result;
  }
}
