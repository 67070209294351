import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import {
  TransactionCategoryEnum,
  WarehouseCategoryEnum
} from 'src/constants/inventory-history.constant';
import { IBranchItem } from 'src/interfaces/master-data/branch';
import { IProductsItem } from 'src/interfaces/master-data/products';
import { IGiftVoucherItem } from '../product-group/gift-voucher.dto';
import dayjs from 'dayjs';
import { GiftVoucherDiscountCatEnum } from 'src/constants/gift-voucher-category';
export interface ITransactionCategory {
  id: number;
  name: string;
  desc?: string;
}

export interface IWarehouseCategory {
  id: number;
  name: string;
  desc?: string;
}

export interface IWarehouse {
  id: number;
  name: WarehouseCategoryEnum;
  warehouseCategory: IWarehouseCategory;
  branch?: IBranchItem;
}

export interface IInventoryHistory {
  id: number;
  createdAt?: string;
  createdBy?: {
    id: number;
    fullName: string;
  };
  desc?: string;
  warehouseTo?: IWarehouse;
  warehouseFrom?: IWarehouse;
  importProductDetails?: IProductDetails[];
  importGiftVoucherDetails?: IVoucherDetails[];
  distributor: string;
  warehouseIdTo: number;
  catId: number;
  exportProductDetails?: IProductDetails[];
  exportGiftVoucherDetails?: IVoucherDetails[];
  transactionCategory: ITransactionCategory;
}

export interface IVoucherDetails {
  quantity: number;
  startNumber: number;
  endNumber: number;
  unitPrice: number;
  code: string;
  ttl: number;
  serviceId?: number;
  packageId?: number;
  category?: GiftVoucherDiscountCatEnum;
  giftVoucher?: IGiftVoucherItem;
  isIncludeServiceCharge: boolean;
  isNotWeekendApplicable: boolean;
  isNotHolidayApplicable: boolean;
  prefixCode?: string;
  branchIds?: number[];
  currentStock?: number;
}

export interface IProductDetails extends IVoucherDetails {
  currentStock?: number;
  productId?: number;
  quantity: number;
  unitPrice: number;
  id?: number;
  name?: string;
  product?: IProductsItem;
}

export interface IInventoryHistoryRequest {
  keyword?: string;
  type?: TransactionCategoryEnum;
  warehouseFromId?: number;
  warehouseToId?: number;
  date?: string;
  page?: number;
  limit?: number;
  createdAt?: dayjs.Dayjs[];
  fromDate?: string;
  toDate?: string;
}

export interface IWarehouseRequest {
  keyword?: string;
  branchId?: number;
  date?: string;
}

export interface IWarehouseProductRequest {
  warehouseId: number;
  page: number;
  limit: number;
  isActive?: boolean;
  catId?: number;
  keyword?: string;
  isNotIncludeFreeItem?: boolean;
}

export interface IWarehousePrefixCodeRequest {
  warehouseId: number;
  page: number;
  limit: number;
  isActive?: boolean;
  keyword?: string;
}
export interface IImportInventoryBody {
  desc: string;
  distributor: string;
  warehouseIdTo: number;
  catId?: number;
  productDetails: IProductDetails[];
  giftVoucherDetails?: IVoucherDetails[];
}

export interface IExportInventoryBody {
  desc: string;
  warehouseIdFrom: string;
  warehouseIdTo: number;
  catId?: number;
  productDetails?: IProductDetails[];
  giftVoucherDetails?: IVoucherDetails[];
}

export class GetListInventoryHistoryDTO extends DTO {
  public param: object | undefined;
  public query: IInventoryHistoryRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.INVENTORY_TXNS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IInventoryHistoryRequest) {
    super();
    this.query = query;
  }
}

export class GetListWarehouseDTO extends DTO {
  public param: object | undefined;
  public query: IWarehouseRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.WAREHOUSES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IWarehouseRequest) {
    super();
    this.query = query;
  }
}

export class GetListWarehouseProductDTO extends DTO {
  public param: { warehouseId: number };
  public query: { page: number; limit: number; catId?: number; isNotIncludeFreeItem?: boolean };
  public body: undefined;
  public url: string = ENDPOINT.WAREHOUSES_PRODUCTS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(
    param: { warehouseId: number },
    query: { page: number; limit: number; catId?: number; isNotIncludeFreeItem?: boolean }
  ) {
    super();
    this.param = param;
    this.query = query;
  }
}

export class GetListWarehousePrefixCodeDTO extends DTO {
  public param: { warehouseId: number };
  public query: { page: number; limit: number };
  public body: undefined;
  public url: string = ENDPOINT.WAREHOUSES_PREFIX_CODES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { warehouseId: number }, query: { page: number; limit: number }) {
    super();
    this.param = param;
    this.query = query;
  }
}

export class GetListTransactionCategoryDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: undefined;
  public url: string = ENDPOINT.WAREHOUSES_TRANSACTION_CATEGORIES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
  }
}

export class ImportInventoryDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IImportInventoryBody;
  public url: string = ENDPOINT.WAREHOUSES_IMPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IImportInventoryBody) {
    super();
    this.body = body;
  }
}

export class ExportInventoryDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IExportInventoryBody;
  public url: string = ENDPOINT.WAREHOUSES_EXPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IExportInventoryBody) {
    super();
    this.body = body;
  }
}

export class DetailInventoryTransactionDTO extends DTO {
  public param: { id: string };
  public query: { type: TransactionCategoryEnum };
  public body: undefined;
  public url: string = ENDPOINT.INVENTORY_TXNS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }, query: { type: TransactionCategoryEnum }) {
    super();
    this.param = param;
    this.query = query;
  }
}
