import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { API_HOST } from 'src/environments/environment';
import qs from 'qs';

export abstract class DTO {
  public abstract param: object | undefined;
  public abstract query: unknown;
  public abstract body: unknown;
  public abstract readonly url: string;
  public abstract readonly method: HTTP_METHOD;
  public responseType?: ResponseType;
  public headers?: object;
  public refreshToken?: string | null;
  public interpolatedUrl(): string {
    let newUrl = API_HOST + ENDPOINT.ROOT + this.url;
    Object.entries(this.param ?? {}).forEach(([key, value]) => {
      newUrl = newUrl.replace(':' + key.toString(), value.toString());
    });

    return (
      newUrl +
      '?' +
      qs.stringify(this.query, {
        arrayFormat: 'repeat',
        encode: false,
        allowDots: true
      })
    );
  }
}

export abstract class ResponseDTO<T> {
  public readonly responseCode?: string;
  public readonly timestamp?: string;
  public readonly message?: string;
  public readonly errorDetails?: any;
  public abstract readonly data: T | undefined;
}

export interface IBasePaginationRequest {
  pageNumber?: number;
  pageSize?: number;
}

export abstract class PaginationResponseDTO<T> extends ResponseDTO<T> {
  public readonly totalRecords?: number;
  public readonly totalPages?: number;
  public readonly pageNumber?: number;
  public readonly pageSize?: number;
}
