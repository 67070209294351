import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { USER_REFRESH_TOKEN } from 'src/constants/app';
import { DTO } from './base.dto';

export interface ILoginForm {
  username: string;
  code: string;
  password: string;
  rememberMe: boolean;
}

export interface ILogoutForm {
  token: string;
}

export interface ILoginResponse {
  id: string;
  name: string;
  accessToken: string;
  email: string;
  code: string;
  refreshToken: string;
  username: string;
}

export class LogInDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: ILoginForm;
  public url: string = ENDPOINT.LOGIN;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: ILoginForm) {
    super();
    this.body = body;
    this.headers = { Authorization: undefined };
  }
}

export class LogoutDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: ILogoutForm;
  public url: string = ENDPOINT.LOGOUT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
    this.refreshToken = localStorage.getItem(USER_REFRESH_TOKEN);
    this.body = {
      token: this.refreshToken ?? ''
    };
  }
}
