import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { RoleName } from 'src/constants/permission';
import { IBranchItem } from 'src/interfaces/master-data/branch';
import { IUserInfo } from 'src/interfaces/user';

export interface IUserStore {
  isLoggedIn: boolean;
  rememberLoginSession: boolean;
  userInfo: IUserInfo | null;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  logout(): void;
  updateUserInfo(userInfo: IUserInfo): void;

  get userBranchIds(): number[];
}

export class UserStore implements IUserStore {
  isLoggedIn = false;
  rememberLoginSession = false;
  userInfo: IUserInfo | null = null;
  isSuperAdmin = false;
  isAdmin = false;

  constructor() {
    makeAutoObservable(this, {
      isLoggedIn: observable,
      userInfo: observable.ref,
      rememberLoginSession: observable,
      logout: action.bound,
      updateUserInfo: action.bound
    });
  }

  public updateUserInfo(userInfo: IUserInfo): void {
    runInAction(() => {
      this.userInfo = { ...userInfo };
      this.isSuperAdmin = userInfo?.role.name === RoleName.SUPER_ADMIN;
      this.isAdmin = userInfo?.role.name === RoleName.ADMIN;
    });
  }

  public logout(): void {
    this.isLoggedIn = false;
    this.rememberLoginSession = false;
    this.userInfo = null;
  }

  public get userBranchIds(): number[] {
    return (this.userInfo?.branches || []).map((branch: IBranchItem) => branch.id);
  }
}
