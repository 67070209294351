import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateEmployeesDTO,
  DetailEmployeesDTO,
  ExportEmployeesDTO,
  GetListEmployeesDTO,
  GetListEmployeesPrioritizedTherapistsAvailableDTO,
  GetListEmployeesPrioritizedTherapistsDTO,
  GetListRoleDTO,
  ImportEmployeesDTO,
  UpdateEmployeesDTO
} from 'src/dto/employee/employee.dto';
import { IPaginationResponse, IRole } from 'src/interfaces';
import {
  IEmployee,
  IEmployeeBody,
  IEmployeeItem,
  IEmployeePrioritizedTherapists,
  IEmployeePrioritizedTherapistsAvailable,
  IEmployeeRequest,
  IEmployeesPrioritizedTherapistsAvailableRequest,
  IExportEmployeeRequest
} from 'src/interfaces/employee/employee';
import { IHttpService } from 'src/services/http.service';

export interface IEmployeeStore {
  listEmployee: IEmployeeItem[];
  listRoles: IRole[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  totalUnread: number;
  fetchList(request?: IEmployeeRequest): Promise<void>;
  search(request?: IEmployeeRequest): Promise<IPaginationResponse<IEmployeeItem> | undefined>;
  create(body: IEmployeeBody): Promise<ResponseDTO<IEmployeeItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IEmployeeItem>>;
  update(body: IEmployeeBody, param: { id: string }): Promise<ResponseDTO<IEmployeeItem>>;
  destroyStoreWhenLogout(): void;
  fetchListRole(): Promise<void>;
  getEmployeesPrioritizedTherapists(param: {
    branchId: number;
    serviceId: number;
    date: string;
  }): Promise<IEmployeePrioritizedTherapists[]>;
  getEmployeesPrioritizedTherapistsAvailable(
    request: IEmployeesPrioritizedTherapistsAvailableRequest
  ): Promise<IEmployeePrioritizedTherapistsAvailable[]>;
  //get all
  fetchListSelect(request?: IEmployeeRequest): Promise<IEmployeeItem[]>;
  export(request: IExportEmployeeRequest): Promise<boolean>;
  import(body: FormData): Promise<ResponseDTO<IEmployeeItem[]>>;
}

export class EmployeeStore implements IEmployeeStore {
  listEmployee: IEmployeeItem[] = [];
  listRoles: IRole[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  totalUnread = 0;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listEmployee: observable.ref,
      pageSize: observable,
      totalPages: observable,
      totalUnread: observable,
      fetchListRole: action.bound
    });
  }

  public async fetchListRole(): Promise<void> {
    const requestDTO = new GetListRoleDTO();
    const result: ResponseDTO<IRole[]> = await this.http.request(requestDTO);
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listRoles = result.data ?? [];
      });
    }
  }

  public async fetchList(request: IEmployeeRequest): Promise<void> {
    const requestDTO = new GetListEmployeesDTO(request);
    const result: ResponseDTO<IEmployee> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listEmployee = result.data?.paginatedResults ?? [];
        this.totalPages = result.data?.total ?? 0;
        this.totalRecords = result.data?.total ?? 0;
        this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  public async search(
    request: IEmployeeRequest
  ): Promise<IPaginationResponse<IEmployeeItem> | undefined> {
    const requestDTO = new GetListEmployeesDTO(request);
    const result: ResponseDTO<IPaginationResponse<IEmployeeItem> | undefined> =
      await this.http.request(requestDTO);
    return result.data;
  }

  public async create(body: IEmployeeBody) {
    const createDTO = new CreateEmployeesDTO(body);
    const res: ResponseDTO<IEmployeeItem> = await this.http.request(createDTO);
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailEmployeesDTO(query);
    const res: ResponseDTO<IEmployeeItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IEmployeeBody, param: { id: string }) {
    const updateStoreDTO = new UpdateEmployeesDTO(body, param);
    const res: ResponseDTO<IEmployeeItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async fetchListSelect(request?: IEmployeeRequest): Promise<IEmployeeItem[]> {
    const requestDTO = new GetListEmployeesDTO(request);
    const result: ResponseDTO<IEmployee> = await this.http.request(requestDTO);
    return result.data?.paginatedResults as IEmployeeItem[];
  }

  public async getEmployeesPrioritizedTherapists(param: {
    branchId: number;
    serviceId: number;
    date: string;
  }) {
    const requestEmployeesPrioritizedTherapistsDTO = new GetListEmployeesPrioritizedTherapistsDTO(
      param
    );
    const result: ResponseDTO<IEmployeePrioritizedTherapists[]> = await this.http.request(
      requestEmployeesPrioritizedTherapistsDTO
    );
    return result?.data ?? [];
  }

  public async getEmployeesPrioritizedTherapistsAvailable(
    request: IEmployeesPrioritizedTherapistsAvailableRequest
  ) {
    const requestEmployeesPrioritizedTherapistsDTO =
      new GetListEmployeesPrioritizedTherapistsAvailableDTO(request);
    const result: ResponseDTO<IEmployeePrioritizedTherapistsAvailable[]> = await this.http.request(
      requestEmployeesPrioritizedTherapistsDTO
    );
    return result?.data ?? [];
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.pageSize = 1;
      this.totalPages = 0;
      this.totalUnread = 0;
      this.listEmployee = [];
    });
  }

  public async export(request: IExportEmployeeRequest): Promise<boolean> {
    const exportProductsDTO = new ExportEmployeesDTO(request);
    return this.http.downloadFile(
      exportProductsDTO.interpolatedUrl(),
      undefined,
      exportProductsDTO.method
    );
  }

  public async import(body: FormData): Promise<ResponseDTO<IEmployeeItem[]>> {
    const importProductsDTO = new ImportEmployeesDTO(body);
    const res: ResponseDTO<IEmployeeItem[]> = await this.http.request(importProductsDTO);
    return res;
  }
}
