import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateCustomerCatsDTO,
  DeleteCustomerCatsDTO,
  DetailCustomerCatsDTO,
  GetListCustomerCatsDTO,
  UpdateCustomerCatsDTO
} from 'src/dto/master-data/customer-cats.dto';
import {
  ICustomerCatsBody,
  ICustomerCatsItem,
  ICustomerCatsRequest
} from 'src/interfaces/master-data/customer-cats';
import { IHttpService } from 'src/services/http.service';

export interface ICustomerCatsStore {
  listCustomerCats: ICustomerCatsItem[];

  fetchList(request?: ICustomerCatsRequest): Promise<void>;
  create(body: ICustomerCatsBody): Promise<ResponseDTO<ICustomerCatsItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<ICustomerCatsItem>>;
  update(body: ICustomerCatsBody, param: { id: string }): Promise<ResponseDTO<ICustomerCatsItem>>;
  delete(param: { id: string }): Promise<ResponseDTO<ICustomerCatsItem>>;
  destroyStoreWhenLogout(): void;
}

export class CustomerCatsStore implements ICustomerCatsStore {
  listCustomerCats: ICustomerCatsItem[] = [];

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listCustomerCats: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: ICustomerCatsRequest): Promise<void> {
    const requestDTO = new GetListCustomerCatsDTO(request);
    const result: ResponseDTO<ICustomerCatsItem[]> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listCustomerCats = result?.data ?? [];
      });
    }
  }

  public async create(body: ICustomerCatsBody) {
    const createDTO = new CreateCustomerCatsDTO(body);
    const res: ResponseDTO<ICustomerCatsItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailCustomerCatsDTO(query);
    const res: ResponseDTO<ICustomerCatsItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: ICustomerCatsBody, param: { id: string }) {
    const updateStoreDTO = new UpdateCustomerCatsDTO(body, param);
    const res: ResponseDTO<ICustomerCatsItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async delete(param: { id: string }) {
    const deleteStoreDTO = new DeleteCustomerCatsDTO(param);
    const res: ResponseDTO<ICustomerCatsItem> = await this.http.request(deleteStoreDTO);
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listCustomerCats = [];
    });
  }
}
