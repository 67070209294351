import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from '../base.dto';
import { PermissionType } from 'src/constants/permission';
export enum StatusPermission {
  NORMAL = 'normal',
  DISABLE = 'disable',
  CHECKED_DISABLE = 'checked-disable'
}
export type StatusPermissionType =
  | StatusPermission.NORMAL
  | StatusPermission.DISABLE
  | StatusPermission.CHECKED_DISABLE;

export interface IPermisstionItem {
  id: number;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  name: PermissionType;
  order?: number;
  roleId?: number;
  status?: StatusPermissionType;
  key?: number;
  children?: IPermisstionItem[];
}

export interface IPermission {
  id: number;
  name: string;
}

export interface IGetListPermissionItem {
  role: {
    id: number;
    createdAt: string;
    createdBy: number;
    updatedAt: string;
    updatedBy: number;
    deletedAt: string;
    name: string;
  }[];
  resourcePermission: {
    id: number;
    createdAt: string;
    createdBy: number;
    updatedAt: string;
    updatedBy: number;
    deletedAt: string;
    name: string;
    order: number;
    permissions: IPermisstionItem[];
  }[];
  rolePermission: {
    roleName: string;
    permissions: IPermisstionItem[];
  }[];
  rolePermissionDisable: {
    roleName: string;
    permissions: IPermisstionItem[];
  }[];
}

export interface IBodyUpdatePermission {
  rolePermissions: {
    roleId: number;
    permissionIds: number[];
  }[];
}

export interface ResponseRBACDTO {
  responseCode: string;
}

export class GetListPermissionDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.PERMISSIONS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
}

export class UpdateListPermissionDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodyUpdatePermission;
  public url: string = ENDPOINT.PERMISSIONS;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodyUpdatePermission) {
    super();
    this.body = body;
  }
}
