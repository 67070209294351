import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from '../base.dto';
import { WorkSchedulesRequest } from './work-schedules.dto';

export class WorkSchedulesExportDTO extends DTO {
  public param: undefined;
  public query: WorkSchedulesRequest;
  public body: undefined;
  public url: string = ENDPOINT.WORK_SCHEDULES_EXPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(query: WorkSchedulesRequest) {
    super();
    this.query = query;
  }
}
