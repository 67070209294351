import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { IPromotionsBody, IPromotionsRequest } from 'src/interfaces/promotion';
import { DTO } from './base.dto';
 
export class GetListPromotionsDTO extends DTO {
    public param: object | undefined;
    public query: IPromotionsRequest | undefined;
    public body: undefined;
    public url: string = ENDPOINT.PROMOTIONS;
    public method: HTTP_METHOD = HTTP_METHOD.GET;
    public readonly responseType: ResponseType = 'json';
    constructor(query?: IPromotionsRequest) {
        super();
        this.query = query;
    }
}
 
export class CreatePromotionsDTO extends DTO {
    public param: object | undefined;
    public query: undefined;
    public body: IPromotionsBody;
    public url: string = ENDPOINT.PROMOTIONS;
    public method: HTTP_METHOD = HTTP_METHOD.POST;
    public readonly responseType: ResponseType = 'json';
    constructor(body: IPromotionsBody) {
        super();
        this.body = body;
    }
}
 
export class DetailPromotionsDTO extends DTO {
    public param: { id: string };
    public query: undefined;
    public body: undefined;
    public url: string = ENDPOINT.PROMOTIONS_DETAIL;
    public method: HTTP_METHOD = HTTP_METHOD.GET;
    public readonly responseType: ResponseType = 'json';
    constructor(param: { id: string }) {
        super();
        this.param = param;
    }
}
 
export class UpdatePromotionsDTO extends DTO {
    public param: { id: string };
    public query: undefined;
    public body: IPromotionsBody;
    public url: string = ENDPOINT.PROMOTIONS_DETAIL;
    public method: HTTP_METHOD = HTTP_METHOD.PUT;
    public readonly responseType: ResponseType = 'json';
    constructor(body: IPromotionsBody, param: { id: string }) {
        super();
        this.body = body;
        this.param = param;
    }
}
 
export class GetListPromotionDiscountcategoriesDTO extends DTO {
    public param: undefined;
    public query: undefined;
    public body: undefined;
    public url: string = ENDPOINT.GET_LIST_PROMOTION_DISCOUNT_CATS;
    public method: HTTP_METHOD = HTTP_METHOD.GET;
    public readonly responseType: ResponseType = 'json';
  }
 
  export class GetListPromotionTypeDTO extends DTO {
    public param: undefined;
    public query: undefined;
    public body: undefined;
    public url: string = ENDPOINT.GET_LIST_PROMOTION_TYPE;
    public method: HTTP_METHOD = HTTP_METHOD.GET;
    public readonly responseType: ResponseType = 'json';
  }