import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from './base.dto';
import { IRole } from 'src/interfaces';
import { IResourcePermission, IRolePermission } from 'src/interfaces/permission';
import { IBodyUpdatePermission } from './administration/user-permission.dto';

export interface IUserPermissionResponse {
  role: IRole[];
  rolePermission: IRolePermission[];
  resourcePermission: IResourcePermission[];
}

export class GetUserPermissionDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_USER_PERMISSION;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
  }
}

export class UpdateListPermissionDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodyUpdatePermission;
  public url: string = ENDPOINT.GET_USER_PERMISSION;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodyUpdatePermission) {
    super();
    this.body = body;
  }
}
