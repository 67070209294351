import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateProductsDTO,
  DetailProductsDTO,
  ExportProductsDTO,
  GetListProductsDTO,
  ImportProductsDTO,
  IProductsBody,
  IProductsRequest,
  IProductUpdateBody,
  UpdateProductsDTO
} from 'src/dto/master-data/products.dto';
import { IProducts, IProductsItem } from 'src/interfaces/master-data/products';
import { IHttpService } from 'src/services/http.service';

export interface IProductsStore {
  fetchList(request?: IProductsRequest): Promise<ResponseDTO<IProducts>>;
  create(body: IProductsBody): Promise<ResponseDTO<IProductsItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IProductsItem>>;
  update(body: IProductUpdateBody, param: { id: string }): Promise<ResponseDTO<IProductsItem>>;
  export(): Promise<boolean>;
  import(body: FormData): Promise<ResponseDTO<IProductsItem[]>>;
}

export class ProductsStore implements IProductsStore {
  constructor(private http: IHttpService) {}

  public async fetchList(request?: IProductsRequest) {
    const requestDTO = new GetListProductsDTO(request);
    const result: ResponseDTO<IProducts> = await this.http.request(requestDTO);
    return result;
  }

  public async create(body: IProductsBody) {
    const createDTO = new CreateProductsDTO(body);
    const res: ResponseDTO<IProductsItem> = await this.http.request(createDTO);
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailProductsDTO(query);
    const res: ResponseDTO<IProductsItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IProductUpdateBody, param: { id: string }) {
    const updateStoreDTO = new UpdateProductsDTO(body, param);
    const res: ResponseDTO<IProductsItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async export(): Promise<boolean> {
    const exportProductsDTO = new ExportProductsDTO();
    return this.http.downloadFile(
      exportProductsDTO.interpolatedUrl(),
      undefined,
      exportProductsDTO.method,
      {
        name: 'products.xlsx'
      }
    );
  }

  public async import(body: FormData): Promise<ResponseDTO<IProductsItem[]>> {
    const importProductsDTO = new ImportProductsDTO(body);
    const res: ResponseDTO<IProductsItem[]> = await this.http.request(importProductsDTO);
    return res;
  }
}
