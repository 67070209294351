import { action, makeObservable, observable } from 'mobx';
import { ResponseDTO } from 'src/dto/base.dto';
import { CountriesDTO, CountriesRequest } from 'src/dto/countries.dto';
import { Countries } from 'src/interfaces/countries';
import { IHttpService } from 'src/services/http.service';

export interface ICountriesStore {
  listCountries: Countries[];
  fetched: boolean;
  fetchList(request?: CountriesRequest): Promise<void>;
}

export class CountriesStore implements ICountriesStore {
  listCountries: Countries[] = [];
  fetched = false;

  constructor(private http: IHttpService) {
    makeObservable(this, {
      listCountries: observable.ref,
      fetched: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: CountriesRequest): Promise<void> {
    const requestDTO = new CountriesDTO(request);
    const result: ResponseDTO<Countries[]> = await this.http.request(requestDTO);
    this.listCountries = result.data || [];
    this.fetched = true;
  }
}
