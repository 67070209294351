import { isUndefined } from 'lodash';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import { CreatePromotionsDTO, DetailPromotionsDTO, GetListPromotionDiscountcategoriesDTO, GetListPromotionTypeDTO, GetListPromotionsDTO, UpdatePromotionsDTO } from 'src/dto/promotion.dto';
import { IPromotionDiscountCatsItem, IPromotionTypesItem, IPromotions, IPromotionsBody, IPromotionsItem, IPromotionsRequest } from 'src/interfaces/promotion';
import { IHttpService } from 'src/services/http.service';
 
export interface IPromotionsStore {
  listPromotions: IPromotionsItem[];
  listPromotionDiscountCats: IPromotionDiscountCatsItem[];
  listPromotionType: IPromotionTypesItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  totalUnread: number;
  fetchList(
    request?: IPromotionsRequest,
    isFetchPromotions?: boolean
  ): Promise<ResponseDTO<IPromotions> | void>;
  search(request?: IPromotionsRequest): Promise<IPromotions | undefined>;
  create(body: IPromotionsBody): Promise<ResponseDTO<IPromotionsItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IPromotionsItem>>;
  update(body: IPromotionsBody, param: { id: string }): Promise<ResponseDTO<IPromotionsItem>>;
  destroyStoreWhenLogout(): void;
  fetchListPromotionDiscountCats(): void;
  fetchListPromotionType(): void;
}
 
export class PromotionsStore implements IPromotionsStore {
  listPromotions: IPromotionsItem[] = [];
  listPromotionDiscountCats: IPromotionDiscountCatsItem[] = [];
  listPromotionType: IPromotionTypesItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  totalUnread = 0;
 
  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listPromotions: observable.ref,
      pageSize: observable,
      totalPages: observable,
      totalUnread: observable,
      fetchList: action.bound,
      fetchListPromotionDiscountCats: action.bound,
      fetchListPromotionType: action.bound,
      listPromotionDiscountCats: observable.ref,
      listPromotionType: observable.ref
    });
  }
 
  public async fetchList(
    request?: IPromotionsRequest,
    isStoreData?: boolean
  ): Promise<ResponseDTO<IPromotions> | void> {
    const requestDTO = new GetListPromotionsDTO(request);
    const result: ResponseDTO<IPromotions> = await this.http.request(requestDTO);
 
    if (isUndefined(isStoreData) || isStoreData) {
      if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
        runInAction(() => {
          this.listPromotions = result.data?.paginatedResults ?? [];
          this.totalPages = result.data?.total ?? 0;
          this.totalRecords = result.data?.total ?? 0;
          this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
          this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;
        });
      }
    } else {
      return Promise.resolve(result);
    }
  }
 
  public async search(request?: IPromotionsRequest): Promise<IPromotions | undefined> {
    const requestDTO = new GetListPromotionsDTO(request);
    const result: ResponseDTO<IPromotions> = await this.http.request(requestDTO);
 
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      return result.data;
    }
    return undefined;
  }
 
  public async create(body: IPromotionsBody) {
    const createDTO = new CreatePromotionsDTO(body);
    const res: ResponseDTO<IPromotionsItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }
 
  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailPromotionsDTO(query);
    const res: ResponseDTO<IPromotionsItem> = await this.http.request(detailDTO);
    return res;
  }
 
  public async update(body: IPromotionsBody, param: { id: string }) {
    const updateStoreDTO = new UpdatePromotionsDTO(body, param);
    const res: ResponseDTO<IPromotionsItem> = await this.http.request(updateStoreDTO);
    this.fetchList();
    return res;
  }
 
  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.pageSize = 1;
      this.totalPages = 0;
      this.totalUnread = 0;
      this.listPromotions = [];
    });
  }
 
  public async fetchListPromotionDiscountCats() {
    const requestDTO = new GetListPromotionDiscountcategoriesDTO();
    const result: ResponseDTO<IPromotionDiscountCatsItem[]> = await this.http.request(requestDTO);
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listPromotionDiscountCats = result?.data ?? [];
      });
    }
  }
 
  public async fetchListPromotionType() {
    const requestDTO = new GetListPromotionTypeDTO();
    const result: ResponseDTO<IPromotionTypesItem[]> = await this.http.request(requestDTO);
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listPromotionType = result?.data ?? [];
      });
    }
  }
}