import { Gender, Weekday } from 'src/constants/promotion';

const promotion = {
  code: 'Promotion Code',
  name: 'Promotion Name',
  nameDiscount: 'Discount Campaign Name',
  applicableObject: 'Applicable Object',
  customersApply: 'Customers Apply',
  conditionsApply: 'Conditions Apply',
  promotionType: 'Promotion Type',
  minimumQuantity: 'Minimum quantity of items',
  maximumQuantity: 'Maximum quantity of items',
  minServicesInInvoice: 'Minimum quantity of services',
  minCustomerCount: 'Minimum number of customers',
  minimumBill: 'Minimum bill amount',
  discount: 'Discount',
  isActive: 'Active',
  [Gender.FEMALE]: 'Female',
  [Gender.MALE]: 'Male',
  weekdays: {
    [Weekday.MONDAY]: 'Monday',
    [Weekday.TUESDAY]: 'Tuesday',
    [Weekday.WEDNESDAY]: 'Wednesday',
    [Weekday.THURSDAY]: 'Thursday',
    [Weekday.FRIDAY]: 'Friday',
    [Weekday.SATURDAY]: 'Saturday',
    [Weekday.SUNDAY]: 'Sunday'
  },

  placeholder: {
    startDate: 'Select start date',
    endDate: 'Select end date',
    startTime: 'Select start time',
    endTime: 'Select start time',
    applicableObject: 'Applicable Object',
    membership: 'Select membership Level',
    search: 'Search by campaign name'
  },

  criteria: {
    hasServiceCharge: 'Service charge included?',
    usedOnWeekends: 'Can be used on weekends?',
    usedOnHolidays: 'Can be used on holidays?',
    date: 'Applicable date',
    time: 'Applicable time',
    hours: 'Applicable hours'
  },

  edit: {
    title: 'Update discount campaign'
  }
};

export default promotion;
