import { TherapeuticIndicationEnum } from 'src/interfaces/master-data/therapeutic-indication';

export default {
  [TherapeuticIndicationEnum.GENERAL]: 'Toàn thân',
  [TherapeuticIndicationEnum.FACE]: 'Mặt',
  [TherapeuticIndicationEnum.HEAD]: 'Đầu',
  [TherapeuticIndicationEnum.NECK_FONT]: 'Cổ trước',
  [TherapeuticIndicationEnum.NECK_BACK]: 'Cổ Sau',
  [TherapeuticIndicationEnum.CHEST]: 'Ngực',
  [TherapeuticIndicationEnum.SHOULDER]: 'Vai',
  [TherapeuticIndicationEnum.ARM_FRONT_LEFT]: 'Mặt trước cánh tay trái',
  [TherapeuticIndicationEnum.ARM_FRONT_RIGHT]: 'Mặt trước cánh tay phải',
  [TherapeuticIndicationEnum.ARM_BACK_LEFT]: 'Mặt sau cánh tay trái',
  [TherapeuticIndicationEnum.ARM_BACK_RIGHT]: 'Mặt sau cánh tay phải',
  [TherapeuticIndicationEnum.HAND_FRONT_LEFT]: 'Mặt sau bàn tay trái',
  [TherapeuticIndicationEnum.HAND_FRONT_RIGHT]: 'Mặt sau bàn tay phải',
  [TherapeuticIndicationEnum.HAND_BACK_LEFT]: 'Mặt sau bàn tay trái',
  [TherapeuticIndicationEnum.HAND_BACK_RIGHT]: 'Mặt sau bàn tay phải',
  [TherapeuticIndicationEnum.BELLY]: 'Bụng',
  [TherapeuticIndicationEnum.BACK]: 'Lưng',
  [TherapeuticIndicationEnum.THIGH_FRONT_LEFT]: 'Mặt trước đùi trái',
  [TherapeuticIndicationEnum.THIGH_FRONT_RIGHT]: 'Mặt trước đùi phải',
  [TherapeuticIndicationEnum.THIGH_BACK_LEFT]: 'Mặt sau đùi trái',
  [TherapeuticIndicationEnum.THIGH_BACK_RIGHT]: 'Mặt sau đùi phải',
  [TherapeuticIndicationEnum.LEG_FRONT_LEFT]: 'Mặt trước cẳng chân trái',
  [TherapeuticIndicationEnum.LEG_FRONT_RIGHT]: 'Mặt trước cẳng chân phải',
  [TherapeuticIndicationEnum.LEG_BACK_LEFT]: 'Mặt sau cẳng chân trái',
  [TherapeuticIndicationEnum.LEG_BACK_RIGHT]: 'Mặt sau cẳng chân phải',
  [TherapeuticIndicationEnum.FOOT_FRONT_LEFT]: 'Mặt trước bàn chân trái',
  [TherapeuticIndicationEnum.FOOT_FRONT_RIGHT]: 'Mặt trước bàn chân phải',
  [TherapeuticIndicationEnum.FOOT_BACK_LEFT]: 'Mặt sau bàn chân trái',
  [TherapeuticIndicationEnum.FOOT_BACK_RIGHT]: 'Mặt sau bàn chân phải'
};