export interface MessageBody {
  channel: string;
  data?: unknown;
}

export interface MessageNotification extends MessageBody {
  channel: 'in-app-notifications';
}

export enum NOTIFICATION_FILTER_TYPE {
  ALL = 'all',
  UNREAD = 'unread'
}

export const NotificationFilterOptions = [
  {
    value: NOTIFICATION_FILTER_TYPE.ALL,
    label: 'Tất cả'
  },
  {
    value: NOTIFICATION_FILTER_TYPE.UNREAD,
    label: 'Chưa đọc'
  }
];

export enum NotificationTypes {
  ALERT = 'ALERT',
  REPORT = 'REPORT'
}
