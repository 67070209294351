import {
  IBranchBody,
  IBranchRequest,
  ICustomerPromotionRequest
} from 'src/interfaces/master-data/branch';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListBranchDTO extends DTO {
  public param: object | undefined;
  public query: IBranchRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IBranchRequest) {
    super();
    this.query = query;
  }
}

export class CreateBranchDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IBranchBody;
  public url: string = ENDPOINT.BRANCHES;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBranchBody) {
    super();
    this.body = body;
  }
}

export class DetailBranchDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateBranchDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IBranchBody;
  public url: string = ENDPOINT.BRANCHES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBranchBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class RoomBedBranchDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_ROOMS_BEDS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: number }) {
    super();
    this.param = param;
  }
}

export class GetListBranchServiceEmployeeDTO extends DTO {
  public param: { branchId: number; serviceId: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_SERVICES_EMPLOYEES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { branchId: number; serviceId: number }) {
    super();
    this.param = param;
  }
}

export class GetListBranchServiceTherapistDTO extends DTO {
  public param: { branchId: number; serviceId: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_SERVICES_THERAPISTS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { branchId: number; serviceId: number }) {
    super();
    this.param = param;
  }
}

export class GetListBranchBedDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_BEDS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: number }) {
    super();
    this.param = param;
  }
}

export class WorkSchedulesTherapistsBranchDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_WORK_SCHEDULES_THERAPISTS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: number }) {
    super();
    this.param = param;
  }
}

export class RoomAvailablilityBranchDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_ROOM_AVAILABILITY;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: number }) {
    super();
    this.param = param;
  }
}

export class GetListCustomerPromotionDTO extends DTO {
  public param: { branchId: number };
  public query: ICustomerPromotionRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.BRANCHES_PROMOTIONS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { branchId: number }, query?: ICustomerPromotionRequest) {
    super();
    this.param = param;
    this.query = query;
  }
}
