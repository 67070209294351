import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import { ICustomerDepositDetail } from 'src/interfaces/customer';

export class CustomerDetailParam {
  id!: string;
}

export class ICustomerResponse {
  paginatedResults?: ICustomerDepositDetail[];
  current?: number;
  page?: number;
  limit?: number;
  total?: number;
}

export class CustomerDetailDTO extends DTO {
  public param: CustomerDetailParam;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.CUSTOMER_ID;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: CustomerDetailParam) {
    super();
    this.param = param;
  }
}

export class CustomerDepositDetailDTO extends DTO {
  public param: CustomerDetailParam;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.CUSTOMER_DEPOSIT_TXNS_ID;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: CustomerDetailParam) {
    super();
    this.param = param;
  }
}
