import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';

export enum MessageUpdateProfile {
  failed = 'update-profile-fail',
  success = 'update-profile-success'
}

export interface UpdateProfileResponse {
  msg: MessageUpdateProfile;
}

export interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  email: string;
}

export interface IChangePasswordFromMail {
  token: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface SendMailResetPasswordResponse {
  msg: MessageUpdateProfile;
}

export class UpdateProfileDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.PROFILE;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}

export class ChangePasswordDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.RESET_PASSWORD;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}

export class ChangePasswordFromMail extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IChangePasswordFromMail;
  public url: string = ENDPOINT.MAIL_RESET_PASSWORD;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IChangePasswordFromMail) {
    super();
    this.body = body;
  }
}

export class GetProfileDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.PROFILE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
}

export class SendMailResetPasswordDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.SEND_MAIL_RESET_PASSWORD;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}
