const productContainers = {
    form: {
      titleCreate: 'Thêm mới Đơn vị SP',
      titleUpdate: 'Chỉnh sửa Đơn vị SP',
    },

    errMsg: {
      existingUnit: 'Đã tồn tại tên đơn vị!'
    }
  };
  
  export default productContainers;
  