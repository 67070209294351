import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, List, Popover, Row, Segmented, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { normalizeFormatDateDDMMYYYYHHmm } from 'src/helpers/common.utils';
import { uniqueKey } from 'src/helpers/string.utils';
import { i18nKey } from 'src/locales/i18n';
import styles from './notications-popover.module.less';
import { observer } from 'mobx-react-lite';
import { INotificationStore } from 'src/store/notification/notification.store';
import useStore from 'src/hooks/use-store';
import { NOTIFICATION_FILTER_TYPE, NotificationFilterOptions } from 'src/constants/notification';
import { useNavigate } from 'react-router-dom';
import { SegmentedValue } from 'antd/es/segmented';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { INotificationRequest } from 'src/dto/notification/notification.dto';
import OneSignal from 'react-onesignal';

interface IProps {
  open: boolean;
  handleSetNotify(value: boolean): void;
  children?: React.ReactNode;
  handleSetSeeAll(value: boolean): void;
}

const NotificationsPopover: React.FC<IProps> = ({
  open,
  handleSetNotify,
  handleSetSeeAll,
  children
}: IProps) => {
  const noticationStore: INotificationStore = useStore('notificationStore');
  const [state, setState] = useState<NOTIFICATION_FILTER_TYPE>(NOTIFICATION_FILTER_TYPE.ALL);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [openMarkAsRead, setOpenMarkAsRead] = useState<Map<string, boolean>>(new Map());

  const loadMoreData = async () => {
    if (loading) return;

    try {
      setLoading(true);
      await noticationStore.fetchList({
        page: noticationStore.pageNumber + 1,
        state
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkReadAll = async () => {
    await noticationStore.readAll();
    await getUnreadNotificationCount();
  };

  const onRead = async (id: number) => {
    await noticationStore.readById(id);
    await getUnreadNotificationCount();
  };

  const onChangeFilter = async (value: SegmentedValue) => {
    setState(value as NOTIFICATION_FILTER_TYPE);
  };

  const fetchNoti = async (request?: INotificationRequest) => {
    setLoading(true);
    await noticationStore.fetchList({
      ...request,
      state: state as NOTIFICATION_FILTER_TYPE
    });
    setLoading(false);
  };

  const getUnreadNotificationCount = async () => {
    await noticationStore.getCount({
      state: NOTIFICATION_FILTER_TYPE.UNREAD
    });
  };

  const oneSignalOnForegroundWillDisplay = () => {
    if (state !== NOTIFICATION_FILTER_TYPE.ALL) {
      setState(NOTIFICATION_FILTER_TYPE.ALL);
    } else {
      Promise.all([
        fetchNoti({
          page: PAGINATION_CONFIGURATION.DEFAULT_PAGE,
          limit: PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE
        }),
        getUnreadNotificationCount()
      ]);
    }
  };

  useEffect(() => {
    Promise.all([
      fetchNoti({
        page: PAGINATION_CONFIGURATION.DEFAULT_PAGE,
        limit: PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE
      }),
      getUnreadNotificationCount()
    ]);
  }, [state]);

  useEffect(() => {
    OneSignal.Notifications.addEventListener(
      'foregroundWillDisplay',
      oneSignalOnForegroundWillDisplay
    );

    return () => {
      OneSignal.Notifications.removeEventListener(
        'foregroundWillDisplay',
        oneSignalOnForegroundWillDisplay
      );
    };
  }, []);

  const renderContent = (maxHeight: string) => (
    <div
      className={styles.container}
      style={{
        maxHeight
      }}>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreData}
        hasMore={Boolean(noticationStore.currentList.length)}
        loader={
          noticationStore.list.length ? (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          ) : (
            <></>
          )
        }
        useWindow={false}>
        <List
          style={{ width: '100%', minWidth: '375px' }}
          itemLayout="horizontal"
          loading={loading}
          dataSource={noticationStore.list}
          locale={{ emptyText: `${t(i18nKey.notifications.emptyText)}` }}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              style={{
                background: item.readAt ? '' : '#F0F3FA',
                borderBottom: '1px solid #ccc',
                position: 'relative',
                cursor: 'pointer'
              }}>
              <List.Item.Meta
                title={
                  <Row
                    style={{ position: 'absolute', top: '10%', right: '3%' }}
                    wrap={false}
                    justify={'space-between'}>
                    {!item.readAt && (
                      <Col style={{ cursor: 'pointer' }}>
                        <Popover
                          placement="bottomLeft"
                          open={openMarkAsRead.get(item.id.toString()) ?? false}
                          onOpenChange={(openMark) =>
                            setOpenMarkAsRead((prev) => {
                              const tempMap = new Map(prev);
                              tempMap.set(item.id.toString(), openMark);
                              return tempMap;
                            })
                          }
                          overlayInnerStyle={{ padding: 0 }}
                          arrow={false}
                          content={
                            <Button onClick={() => onRead(item.id)}>
                              {t(i18nKey.notifications.button.markAsRead)}
                            </Button>
                          }>
                          <EllipsisOutlined key={uniqueKey(10)} />
                        </Popover>
                      </Col>
                    )}
                  </Row>
                }
                description={
                  <div>
                    <b>
                      {t(
                        i18nKey.notifications.type[
                          item.type.name.toLowerCase() as unknown as keyof typeof i18nKey.notifications.type
                        ]
                      )}
                    </b>
                    <div dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                    <Row justify={'space-between'} align={'middle'}>
                      <Col className={styles.time}>
                        {normalizeFormatDateDDMMYYYYHHmm(item.createdAt as string)}
                      </Col>
                      {!item.readAt && <span className={styles.dot}></span>}
                    </Row>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );

  return (
    <div>
      <Popover
        trigger={'click'}
        align={{ offset: [-5, 10] }}
        overlayClassName={styles.popoverContainer}
        onOpenChange={(e) => handleSetNotify(e)}
        open={open}
        title={
          <>
            <Row justify={'space-between'}>
              <Col className={styles.textTitle}>{t(i18nKey.notifications.title)}</Col>
              <Col>
                <span
                  className={styles.textMarkAll}
                  onClick={handleMarkReadAll}
                  onKeyDown={handleMarkReadAll}>
                  {t(i18nKey.notifications.button.markAllAsRead)}
                </span>
              </Col>
            </Row>
            <Segmented
              defaultValue={NOTIFICATION_FILTER_TYPE.ALL}
              options={NotificationFilterOptions}
              onChange={onChangeFilter}
              className={styles.switchFilter}
            />
          </>
        }
        style={{ background: 'red' }}
        placement="bottomRight"
        content={<div>{renderContent('400px')}</div>}>
        {children}
      </Popover>
    </div>
  );
};

export default observer(NotificationsPopover);
