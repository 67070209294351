import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from './base.dto';
import { Countries } from 'src/interfaces/countries';

export class CountriesRequest {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
  keyword?: number;
}

export class CountriesDTO extends DTO {
  public param: undefined;
  public query: CountriesRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.COUNTRIES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: CountriesRequest) {
    super();
    this.query = query;
  }
}

export class CountriesDetailParam {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
  keyword?: number;
}

export class CountriesDetailResponse {
  data!: Countries;
}

export class CountriesDetailDTO extends DTO {
  public param: CountriesDetailParam;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.COUNTRIES_ID;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: CountriesDetailParam) {
    super();
    this.param = param;
  }
}
