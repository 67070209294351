import { DTO } from './base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { IExportServicesRequest, IServicesBody, IServicesRequest } from 'src/interfaces/services';

export class GetListServicesDTO extends DTO {
  public param: object | undefined;
  public query: IServicesRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.SERVICES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IServicesRequest) {
    super();
    this.query = {
      sortBy: 'displayOrder',
      sortOrder: 'ASC',
      ...query
    };
  }
}

export class GetListServicesWithPackagesDTO extends DTO {
  public param: object | undefined;
  public query: IServicesRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.SERVICES_WITH_PACKAGES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IServicesRequest) {
    super();
    this.query = query;
  }
}

export class CreateServicesDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IServicesBody;
  public url: string = ENDPOINT.SERVICES;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IServicesBody) {
    super();
    this.body = body;
  }
}

export class DetailServicesDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.SERVICES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateServicesDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IServicesBody;
  public url: string = ENDPOINT.SERVICES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IServicesBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class ExportServicesDTO extends DTO {
  public param: undefined;
  public query: IExportServicesRequest;
  public body: undefined;
  public url: string = ENDPOINT.SERVICES_EXPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query: IExportServicesRequest) {
    super();
    this.query = query;
  }
}

export class ImportServicesDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.SERVICES_IMPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}
