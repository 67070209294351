import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from '../base.dto';

export interface WorkSchedulesEmployeeBody {
  branchId: number;
  data: {
    id: number | null;
    employeeId: number;
    shiftId: number;
  }[];
}

export class WorkScheduleEmployeeDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: WorkSchedulesEmployeeBody;
  public url: string = ENDPOINT.WORK_SCHEDULES_EMPLOYEES;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: WorkSchedulesEmployeeBody) {
    super();
    this.body = body;
  }
}
