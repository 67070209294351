import { ICustomerReportsRequest } from 'src/interfaces/reports/customer';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListCustomerReportsDTO extends DTO {
  public param: object | undefined;
  public query: ICustomerReportsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.CUSTOMER_REPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType?: ResponseType = 'json';
  constructor(query?: ICustomerReportsRequest) {
    super();
    this.query = query;
  }
}
