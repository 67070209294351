import account from './administration/account';
import rbac from './administration/rbac';
import notifications from './notifications';
import validation from './validation';
import menu from './menu';
import employee from './employee';
import branch from './master-data/branch';
import validationRules from './validationRules';
import serviceGroup from './master-data/groupServices';
import workSchedules from '../en/work-schedules';
import customerCats from './master-data/customer-cats';
import customerPage from './customer';
import productCats from './master-data/product-cats';
import products from './master-data/products';
import roomCats from './master-data/room-cats';
import rooms from './master-data/rooms';
import roomBed from './pos/roomBed';
import booking from './booking';
import giftVoucher from './product-group/gift-voucher';
import depositCats from './master-data/deposit-cats';
import order from './pos/order';
import theupeuticIndication from './therapeutic-indication';
import warehouseHistory from './product-group/warehouse-history';
import productContainers from './master-data/product-containers';
import promotion from './promotion';
import giftVoucherGroups from './master-data/gift-voucher-groups';
import reports from './reports';

const en = {
  account,
  booking,
  location,
  rbac,
  validation,
  notifications,
  menu,
  employee,
  customerPage,
  branch,
  validationRules,
  serviceGroup,
  workSchedules,
  customerCats,
  productCats,
  products,
  roomCats,
  rooms,
  productContainers,
  promotion,
  roomBed,
  order,
  giftVoucher,
  depositCats,
  theupeuticIndication,
  warehouseHistory,
  giftVoucherGroups,
  reports,

  httpResponseMessage: {
    _400_BadRequest: 'The server cannot process the request due to a client error.',
    _401_Unauthorized_Access_Denided:
      'Access denied. You do not have the required permissions to access this feature or data. Please contact your administrator for assistance.',
    _401_Unauthorized_Session_Expired:
      'Your session has expired. Please log in again to continue accessing the application.',
    _403_Forbidden: 'The server understands the request but refuses to authorize it.',
    _404_Not_Found: '404 Page Not Found',
    _500_Internal_Server_Error:
      'The server encountered an unexpected condition that prevented it from fulfilling the request.',
    _502_Bad_Gateway:
      'The server acting as a gateway received an invalid response from the upstream server.',
    _503_Service_Unavailable: 'The server is currently unavailable due to overload or maintenance.',
    _504_GatewayTimeout:
      'The server acting as a gateway did not receive a timely response from the upstream server.'
  },

  commonMsg: {
    networkErr: 'Looks like you lost connection. Please check it and try again.',
    requiredField: 'Please fill out this field.',
    numericalValidation: 'Please enter only numerical characters.'
  },

  page: {
    packageList: 'Combo package Management',
    package: 'Combo package',
    addPackage: 'Add a new Combo Package',
    updatePackage: 'Update a Combo Package',
    serviceMgmt: 'Service Management',
    addService: 'Add a new Service',
    updateService: 'Update Service',
    appointment: 'Appointment Management',
    inventory: 'Inventory Management',
    membershipMgmt: 'Membership Management',
    addMembership: 'Add a new Membership',
    updateMembership: 'Update Membership',
    productTransactionHistory: 'Product Transaction History',
    productUnit: 'Product Unit Management',
    promotionCampaign: 'Promotion Campaign Management',
    addPromotionCampaign: 'Add a new Promotion Campaign',
    invoice: 'Invoice Management',
    invoiceDetails: 'Invoice Details',
    therapistReport: 'Therapist Report',
    revenueReport: 'Revenue Report',
    bedRoomPerformanceReport: 'Bed/Room Performance Report',
    productReport: 'Product Report',
    giftVoucherReport: 'Gift Voucher Report',
    customerReport: 'Customer Report',
    promotionCampaignReport: 'Promotion Campaign Report',
    rolesAndPermissions: 'Roles and Permissions'
  },

  placeholder: {
    searchBy: {
      default: 'Enter your search term',
      product: 'Search by Product Code, Product Name',
      unit: 'Search by Unit',
      package: 'Search by Package name',
      customerORtherapist: 'Search by Customer Name/Phone Number, Therapist Code',
      customerORinvoice: 'Search by Customer Name/Phone Number',
      nameORphoneORmail: 'Search by Name, Phone Number, Email',
      service: 'Search by Service Code, Service Name',
      therapist: 'Search by Therapist Name/Code',
      employee: 'Search by Name/Employee ID',
      promotionCampaign: 'Search by Promotion Campaign Name',
      appointmentCode: 'Search by Booking Code',
      giftVoucherCode: 'Search by Gift Voucher Code',
      membershipLevel: 'Search by Membership Level',
      customerName: 'Search by Customer Name'
    },

    select: {
      default: 'Select',
      service: 'Select Service(s)',
      serviceGroup: 'Select Service Group',
      unit: 'Select Unit',
      bed: 'Select Bed'
    },

    enter: {
      numericValue: 'Enter a numeric value',
      monetaryValue: 'Enter the monetary value',
      giftVoucherCode: 'Enter the Gift Voucher Code',
      lockerNo: 'Enter Locker No'
    },

    selectTime: 'Select Time',
    selectDate: 'Select Date',
    service: 'Select service',
    enterTtl: 'Enter TTL',
    selectProductCat: 'Select Product Group',
    selectBranch: 'Select Branch',
    selectRole: 'Select Role',
    selectTherapist: 'Select Therapist',
    selectRequestTherapist: 'Select Request Therapist',
    selectDefaultTherapist: 'Select Default Therapist',
    selectCountry: 'Select Country',
    selectService: 'Select Service',
    enterEmail: 'Enter Email',
    enterFullname: 'Enter Fullname',
    filterWarehouse: 'Filter Warehouse',
    therapistIsBooked: 'Has been booked',
    therapistIsOnService: 'On service'
  },

  confirmationPopup: {
    cancel: 'Are you sure you want to cancel this action?',
    create: 'Are you sure you want to create this record?',
    update: 'Are you sure you want to update this record?',
    delete: 'Are you sure you want to delete this record?',
    remove: 'Are you sure you want to remove this record?',
    logout: 'Are you sure you want to log out?'
  },

  toastMessages: {
    createSuccess: 'Record has been successfully updated!',
    createFail: 'Failed to create the record. Please try again!',
    updateSuccess: 'Record has been successfully updated!',
    updateFail: 'Failed to update the record. Please try again!',
    deleteSuccess: 'Record has been successfully deleted!',
    deleteFail: 'Failed to delete the record. Please try again!',
    syncFail: 'Failed to sync data. Please try again!',
    updatePasswordSuccess: 'Update password successfully!',
    updatePasswordFailed: 'Update password failed!',
    loadFailed: 'Load failed. Please try again!'
  },

  button: {
    clearFilter: 'Clear Filter',
    login: 'Login',
    cancel: 'Cancel',
    back: 'Back',
    update: 'Update',
    operation: 'Operation',
    ok: 'OK',
    confirm: 'Confirm',
    details: 'Details',
    totalEntries: 'Total {{totalEntries}} entries',
    delete: 'Delete',
    add: 'Add',
    editTotalWarehouse: 'Unable to set usage conditions for gift vouchers in the total warehouse',
    logout: 'Logout',
    reset: 'Reset',
    create: 'Add new',
    save: 'Save',
    apply: 'Apply',
    send: 'Send',
    edit: 'Edit',
    close: 'Close',
    addEmployee: 'Add/Remove employee',
    print: 'Print temporarily',
    pay: 'Pay',
    tempPrinting: 'Temporarily Printing',
    warehouseImport: 'Warehouse Import',
    warehouseExport: 'Warehouse Export',
    isExpiredProduct: 'is expired product?',
    cancelBooking: 'Cancel booking',
    changePassword: 'Change password',
    bookingDetails: 'View Booking Details',
    customerDetails: 'View Customer Details',
    editPersonalInfo: 'Edit My Profile',
    backToLoginPage: 'Back to the Login page',
    export: 'Export excel',
    settingCondition: 'Setting condition',
    seriesList: 'Series list'
  },

  tooltip: {
    alreadySetCondition: 'Cannot be edited, Gift voucher group has already set conditions',
    invalidPackageService: 'Service is not available at some branches you choose'
  },

  label: {
    timeInMinutes: 'Time (Mins)',
    masterData: 'Master Data',
    packageName: 'Package name',
    package: 'Package',
    all: 'All',
    password: 'Password',
    reports: 'Reports',
    list: 'List',
    role: 'Role',
    abbreviatedName: 'Abbreviated Name',
    service: 'Service',
    serviceCode: 'Service Code',
    serviceName: 'Service Name',
    serviceGroup: 'Service Group',
    serviceCharge: 'Service Charge',
    product: 'Product',
    productCode: 'Product Code',
    productName: 'Product Name',
    productCat: 'Product Category',
    productContainer: 'Product Units',
    importQty: 'Import Quantity',
    exportQty: 'Export Quantity',
    currentStockQty: 'Current Stock Quantity',
    historyWarehouse: 'History Warehouse',
    existence: 'Inventory ',
    status: 'Status',
    type: 'Type',
    cabinetKeyNo: 'Cabinet Key No.',
    nationality: 'Nationality',
    address: 'Address',
    branch: 'Branch',
    branchCode: 'Branch Code',
    branchName: 'Branch Name',
    roomBed: 'Bed',
    roomName: 'Room Name',
    roomType: 'Room Type',
    roomRating: 'Room Rating',
    identityCard: 'Identity Card No.',
    dob: 'DoB',
    email: 'Email',
    basicInfo: 'Basic Information',
    customer: 'Customer',
    customerName: 'Customer Name',
    customerGroup: 'Customer Group',
    customerGroupName: 'Customer Group Name',
    customerQty: 'Customer Quantity',
    lockerNo: 'Locker No',
    membershipLevel: 'Membership Level',
    membershipName: 'Membership Name',
    membershipBenefits: 'Membership Benefits',
    minSpendingAmount: 'Min Spending Amount (VNĐ)',
    minAccumulateSpending: 'Min Accumulate Spending (VNĐ)',
    membershipLevelReviewCycle: 'Membership Level Review Cycle (days)',
    levelUpConditions: 'Level up conditions',
    levelAdjustment: 'Level Adjustment',
    fullName: 'Full Name',
    phone: 'Phone',
    gender: 'Gender',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    workSchedules: 'Work Schedules',
    employeeCode: 'Employee Code',
    therapist: 'Therapist',
    selectTherapist: 'Select Therapist',
    commissionFee: 'Commission Fee',
    therapistCode: 'Therapist Code',
    serviceRequestNumber: 'Service Request Number',
    servicesAccordingToTour: 'Service according to Tour',
    therapistFee: 'Therapist Fee',
    defaultTherapist: 'Default Therapist',
    requestedTherapist: 'Requested Therapist',
    personalInfo: 'Personal Information',
    prefixCode: 'Prefix Code',
    displayOrder: 'Display Order',
    unitPrice: 'Unit Price (VNĐ)',
    exitPrice: 'Exit Price (VNĐ)',
    value: 'Value',
    deposit: 'Deposit',
    depositAmount: 'Deposit Amount (VNĐ)',
    availableDeposit: 'Available Deposit',
    totalAmount: 'Total Amount (VNĐ)',
    giftVoucher: 'Gift Voucher',
    giftVoucherGroup: 'Gift Voucher Group',
    giftVoucherGroupName: 'Gift Voucher Group Name',
    promotionalValue: 'Promotional Value',
    discountCategory: 'Discount Category',
    discount: 'Discount',
    discountOnOrder: 'Discount on Order',
    discountOnService: 'Discount on Service',
    appointmentList: 'Appointment List',
    appointmentTime: 'Appointment Time',
    appointmentCode: 'Appointment Code',
    notes: 'Notes',
    note1: 'Note 1',
    note2: 'Note 2',
    date: 'Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    createdAt: 'Created At',
    createdBy: 'Created By',
    purchasedAt: 'Purchased At',
    redeemedAt: 'Redeemed At',
    usagePlace: 'Places',
    paidAt: 'Paid At',
    ttl: 'Expiration Date (Days)',
    moreInfo: 'More Info',
    quantity: 'Quantity',
    currentStock: 'Current Stock',
    serialNo: 'Serial No.',
    serialList: 'Serial List',
    warehouse: 'Warehouse',
    giftVoucherCats: 'Gift Voucher Type',
    order: 'Order',
    invoiceId: 'Invoice ID',
    paymentMethod: 'Payment Method',
    offers: 'Offers',
    offersPercentage: 'Offers (%)',
    depositHistory: 'Deposit History',
    printedAt: 'Printed At',
    itemList: 'Service/Product',
    finalizedAmount: 'Finalized Amount',
    tempCalculated: 'Temporarily Calculated',
    paymentInformation: 'Payment Information',
    total: 'Total',
    other: 'Other',
    tempCalculationSlip: 'Temp Calculation Slip',
    giftVoucherHasNotBeenSetUpCondition: 'Gift voucher usage conditions have not been set up',
    tipIncludes: 'Tip includes',
    depositPrice: 'Deposit Price',
    hasNotStartedServices: 'Service not started',
    processingServices: 'Service in progress',
    moreThanOneTherapistHasBeenSelected: 'More than one Therapist has been selected',
    page: 'page',
    payByDeposit: 'Pay by deposit',
    discountOn: 'Discount on',
    exportEmployees: 'Export Employees List',
    exportServices: 'Export Services List'
  },

  layout: {
    title: 'Moc Huong Spa'
  },

  pagination: {
    totalEntries: 'Showing {{range0}} - {{range1}} of {{total}} entries'
  },

  feeType: {
    TIPS: 'TIPS',
    extra: 'Extra Fee (%)',
    commission: 'Commission Fee (VNĐ)',
    requestedTherapist: 'Therapist Request Fee',
    room: 'Room Fee',
    unitPrice: 'Unit Price'
  },

  discountCategory: {
    totalBillDiscount: 'Total Bill Discount',
    roomDiscount: 'Room Discount',
    roomDiscountDetails: 'Discount on rooms {{room}}',
    productDiscount: 'Discount on all products',
    serviceFeeDiscount: 'Discount on the total service fees'
  },

  noData: {
    title: 'The data list will be displayed here',
    desc: 'You have no data yet. Please add new data now!'
  },

  contact: 'Hotline, Viber, Zalo support: <strong>0935 666 158</strong>',
  copyright: 'Copyright © 2024 Mộc Hương Spa',
  forgotPassword: 'Forgot password?',

  setting: {
    logout: 'Logout',
    profile: 'My Profile',
    languageEN: 'English',
    languageVI: 'Vietnamese'
  },

  noResult: 'No results found!',
  pleaseTryAgain: 'Please try again',

  status: {
    active: 'Active',
    inactive: 'Inactive',
    read: 'Read',
    unRead: 'UnRead'
  },

  gender: {
    MALE: 'Male',
    FEMALE: 'Female'
  },

  text: {
    or: 'or'
  },

  messages: {
    loadPageError: 'An error occurred while loading the page',
    exportFailed: 'File export failed',
    importFailed: 'File import failed',
    importSuccess: 'File import successful',
    recordAssociatedWithExistingData:
      'Cannot delete this record because it is associated with other data.'
  },
  description: {
    warningDeleteAction:
      'This will permanently delete the record. You won’t be able to undo this action.'
  },

  importExcelError: {
    duplicatedCode: 'Duplicate code',
    duplicatedPhoneNumber: 'Duplicate phone number',
    codeIsNotEmpty: 'Code is empty',
    phoneIsNotEmpty: 'Phone number is empty',
    dataError: 'Data error',
    lines: 'Line',
    emailInvalid: 'Email invalid',
    duplicatedServiceExcelData: 'Duplicate Service Name',
    serviceExcelDataIsNotEmpty: 'Service Cat/Service Name/Abbreviation/Unit Price/Duration is empty'
  }
};

export default en;
