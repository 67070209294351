import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateServiceGroupDTO,
  DeleteServiceGroupDTO,
  DetailServiceGroupDTO,
  ListServiceGroupDTO,
  UpdateServiceGroupDTO
} from 'src/dto/master-data/service-group.dto';
import {
  IServiceGroup,
  IServiceGroupBody,
  IServiceGroupItem,
  IServiceGroupRequest
} from 'src/interfaces/master-data/service-group';
import { IHttpService } from 'src/services/http.service';

export interface IServiceGroupStore {
  listServices: IServiceGroupItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  totalUnread: number;
  fetched: boolean;
  requestParams?: IServiceGroupRequest;
  fetchList(request?: IServiceGroupRequest): Promise<void>;
  create(body: IServiceGroupBody): Promise<ResponseDTO<IServiceGroupItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IServiceGroupItem>>;
  update(body: IServiceGroupBody, param: { id: string }): Promise<ResponseDTO<IServiceGroupItem>>;
  delete(param: { id: string }): Promise<ResponseDTO<string>>;
  destroyStoreWhenLogout(): void;
}

export class ServiceGroupStore implements IServiceGroupStore {
  listServices: IServiceGroupItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  totalUnread = 0;
  fetched = false;
  requestParams?: IServiceGroupRequest;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listServices: observable.ref,
      requestParams: observable.ref,
      fetched: observable,
      pageSize: observable,
      totalPages: observable,
      totalUnread: observable,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IServiceGroupRequest): Promise<void> {
    runInAction(() => {
      this.requestParams = request;
    });
    const requestDTO = new ListServiceGroupDTO(request);
    const result: ResponseDTO<IServiceGroup> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.fetched = true;
        this.listServices = result.data?.paginatedResults ?? [];
        this.totalPages = result.data?.total ?? 0;
        this.totalRecords = result.data?.total ?? 0;
        this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  public async create(body: IServiceGroupBody) {
    const createDTO = new CreateServiceGroupDTO(body);
    const res: ResponseDTO<IServiceGroupItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailServiceGroupDTO(query);
    const res: ResponseDTO<IServiceGroupItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IServiceGroupBody, param: { id: string }) {
    const updateStoreDTO = new UpdateServiceGroupDTO(body, param);
    const res: ResponseDTO<IServiceGroupItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async delete(param: { id: string }) {
    const deleteStoreDTO = new DeleteServiceGroupDTO(param);
    const res: ResponseDTO<string> = await this.http.request(deleteStoreDTO);
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.pageSize = 1;
      this.totalPages = 0;
      this.totalUnread = 0;
      this.listServices = [];
    });
  }
}
