import account from './administration/account';
import rbac from './administration/rbac';
import notifications from './notifications';
import validation from './validation';
import validationRules from './validationRules';
import menu from './menu';
import employee from './employee';
import branch from './master-data/branch';
import serviceGroup from './master-data/groupServices';
import workSchedules from './work-schedules';
import customerCats from './master-data/customer-cats';
import customerPage from './customer';
import productCats from './master-data/product-cats';
import products from './master-data/products';
import roomCats from './master-data/room-cats';
import rooms from './master-data/rooms';
import roomBed from './pos/roomBed';
import booking from './booking';
import giftVoucher from './product-group/gift-voucher';
import depositCats from './master-data/deposit-cats';
import order from './pos/order';
import theupeuticIndication from './therapeutic-indication';
import warehouseHistory from './product-group/warehouse-history';
import productContainers from './master-data/product-containers';
import promotion from './promotion';
import giftVoucherGroups from './master-data/gift-voucher-groups';
import reports from './reports';

const en = {
  account,
  booking,
  location,
  rbac,
  validation,
  notifications,
  menu,
  employee,
  customerPage,
  branch,
  validationRules,
  serviceGroup,
  workSchedules,
  customerCats,
  productCats,
  products,
  roomCats,
  rooms,
  productContainers,
  promotion,
  roomBed,
  order,
  giftVoucher,
  depositCats,
  theupeuticIndication,
  warehouseHistory,
  giftVoucherGroups,
  reports,

  httpResponseMessage: {
    _400_BadRequest: 'The server cannot process the request due to a client error.',
    _401_Unauthorized_Access_Denided:
      'Access denied. You do not have the required permissions to access this feature or data. Please contact your administrator for assistance.',
    _401_Unauthorized_Session_Expired:
      'Your session has expired. Please log in again to continue accessing the application.',
    _403_Forbidden: 'The server understands the request but refuses to authorize it.',
    _404_Not_Found: '404 Page Not Found',
    _500_Internal_Server_Error:
      'The server encountered an unexpected condition that prevented it from fulfilling the request.',
    _502_Bad_Gateway:
      'The server acting as a gateway received an invalid response from the upstream server.',
    _503_Service_Unavailable: 'The server is currently unavailable due to overload or maintenance.',
    _504_GatewayTimeout:
      'The server acting as a gateway did not receive a timely response from the upstream server.'
  },

  commonMsg: {
    networkErr: 'Có vẻ như bạn đã mất kết nối. Vui lòng kiểm tra và thử lại!',
    requiredField: 'Vui lòng điền thông tin vào trường này.',
    numericalValidation: 'Please enter only numerical characters.'
  },

  page: {
    packageList: 'Danh sách Combo liệu trình',
    package: 'Combo liệu trình',
    addPackage: 'Thêm mới Combo liệu trình',
    updatePackage: 'Chỉnh sửa Combo liệu trình',
    serviceMgmt: 'Danh sách Dịch Vụ',
    addService: 'Thêm mới Dịch vụ',
    updateService: 'Cập nhật Dịch vụ',
    appointment: 'Danh sách Booking',
    inventory: 'Quản lý Tồn kho',
    membershipMgmt: 'Danh sách Hạng thành viên',
    addMembership: 'Thêm mới Hạng thành viên',
    updateMembership: 'Cập nhật Hạng thành viên',
    productTransactionHistory: 'Lịch sử Xuất/Nhập Sản Phẩm',
    productUnit: 'Danh sách Đơn vị SP',
    promotionCampaign: 'Quản lý Chiến dịch giảm giá',
    addPromotionCampaign: 'Thêm mới Chiến dịch giảm giá',
    invoice: 'Danh sách Hóa đơn',
    invoiceDetails: 'Chi tiết Đơn hàng',
    therapistReport: 'Báo cáo Kỹ Thuật Viên',
    revenueReport: 'Báo cáo Doanh Thu',
    bedRoomPerformanceReport: 'Báo cáo Hiệu suất Phòng/Giường',
    productReport: 'Báo cáo Sản phẩm',
    giftVoucherReport: 'Báo cáo Gift Voucher',
    customerReport: 'Báo cáo Khách hàng',
    promotionCampaignReport: 'Báo cáo Chương trình tháng',
    rolesAndPermissions: 'Vai trò và Phân quyền'
  },

  placeholder: {
    searchBy: {
      default: 'Tìm kiếm...',
      product: 'Tìm kiếm theo Mã SP, Tên SP',
      package: 'Tìm kiếm theo tên Combo liệu trình',
      unit: 'Tìm kiếm theo Đơn vị',
      customerORtherapist: 'Tìm kiếm theo Tên/SĐT KH, mã KTV',
      customerORinvoice: 'Tìm kiếm theo Tên/ SĐT Khách Hàng',
      nameORphoneORmail: 'Tìm kiếm theo Tên, Số điện thoại, Email',
      service: 'Tìm kiếm theo Mã DV, Tên DV',
      therapist: 'Tìm kiếm theo Tên/Mã KTV',
      employee: 'Tìm kiếm theo Tên/Mã NV',
      promotionCampaign: 'Tìm kiếm theo tên Chiến dịch Khuyến mãi',
      appointmentCode: 'Tìm kiếm theo mã Booking',
      giftVoucherCode: 'Tìm kiếm theo mã Gift Voucher',
      membershipLevel: 'Tìm kiếm theo Hạng thành viên',
      customerName: 'Tìm kiếm theo tên KH'
    },

    select: {
      default: 'Chọn',
      service: 'Chọn DV',
      serviceGroup: 'Chọn nhóm DV',
      unit: 'Chọn Đơn vị',
      bed: 'Chọn Giường'
    },

    enter: {
      numericValue: 'Nhập giá trị số',
      monetaryValue: 'Nhập giá trị tiền',
      giftVoucherCode: 'Nhập mã Gift Voucher',
      lockerNo: 'Nhập Khoá tủ'
    },

    selectTime: 'Chọn Thời gian',
    selectDate: 'Chọn Ngày',
    selectRole: 'Chọn Chức vụ',
    selectRequestTherapist: 'KTV Y/C',
    selectDefaultTherapist: 'KTV mặc định',
    selectCountry: 'Chọn Quốc gia',
    enterTtl: 'Nhập số Ngày',
    selectProductCat: 'Chọn Nhóm SP',
    selectBranch: 'Chọn Chi nhánh',
    filterWarehouse: 'Lọc theo kho',
    therapistIsBooked: 'KTV đã được book',
    therapistIsOnService: 'KTV đang thực hiện dịch vụ'
  },

  confirmationPopup: {
    cancel: 'Are you sure you want to cancel this action?',
    create: 'Are you sure you want to create this record?',
    update: 'Are you sure you want to update this record?',
    delete: 'Bạn có chắc muốn xoá bản ghi này không?',
    remove: 'Are you sure you want to remove this record?',
    logout: 'Bạn có chắc chắn muốn đăng xuất?'
  },

  toastMessages: {
    createSuccess: 'Bản ghi đã được tạo thành công!',
    createFail: 'Không tạo được bản ghi. Vui lòng thử lại!',
    updateSuccess: 'Bản ghi đã được cập nhật thành công!',
    updateFail: 'Không thể cập nhật bản ghi. Vui lòng thử lại!',
    deleteSuccess: 'Record has been successfully deleted!',
    deleteFail: 'Failed to delete the record. Please try again!',
    syncFail: 'Failed to sync data. Please try again!',
    updatePasswordSuccess: 'Cập nhật mật khẩu thành công!',
    updatePasswordFailed: 'Cập nhật mật khẩu thất bại!',
    loadFailed: 'Tải thất bại. Vui lòng thử lại!'
  },

  button: {
    clearFilter: 'Xóa lọc',
    login: 'Đăng nhập',
    cancel: 'Hủy',
    back: 'Quay lại',
    update: 'Update',
    operation: 'Thao tác',
    ok: 'OK',
    confirm: 'Xác nhận',
    details: 'Chi tiết',
    totalEntries: 'Total {{totalEntries}} entries',
    delete: 'Xoá',
    add: 'Add',
    editTotalWarehouse: 'Không thể thiết lập điều kiện sử dụng cho GV ở kho tổng',
    logout: 'Logout',
    search: 'Search',
    reset: 'Đặt lại',
    create: 'Thêm mới',
    save: 'Lưu',
    send: 'Gửi',
    close: 'Đóng',
    apply: 'Áp dụng',
    edit: 'Chỉnh sửa',
    addEmployee: 'Thêm/Bỏ nhân viên',
    print: 'Print temporarily',
    pay: 'Thanh toán',
    tempPrinting: 'In tạm',
    warehouseImport: 'Nhập kho',
    warehouseExport: 'Xuất kho',
    isExpiredProduct: 'SP hết hạn?',
    cancelBooking: 'Hủy booking',
    changePassword: 'Đổi mật khẩu',
    bookingDetails: 'Xem chi tiết booking',
    customerDetails: 'Xem thông tin khách hàng',
    editPersonalInfo: 'Sửa thông tin cá nhân',
    backToLoginPage: 'Trở về màn hình Đăng nhập',
    export: 'Xuất file excel',
    settingCondition: 'Thiết lập điều kiện',
    seriesList: 'Danh sách số Seri'
  },

  tooltip: {
    alreadySetCondition: 'Không thể chỉnh sửa, Nhóm Gift voucher đã được thiết lập điều kiện',
    invalidPackageService: 'Dịch vụ không có ở một số chi nhánh bạn chọn'
  },

  label: {
    timeInMinutes: 'Thời gian (Phút)',
    masterData: 'Master Data',
    packageName: 'Tên combo liệu trình',
    package: 'Combo liệu trình',
    all: 'Tất cả',
    password: 'Mật khẩu',
    reports: 'Báo cáo',
    list: 'Danh sách',
    role: 'Chức vụ',
    abbreviatedName: 'Tên viết tắt',
    service: 'Dịch Vụ',
    serviceCode: 'Mã Dịch Vụ',
    serviceName: 'Tên Dịch Vụ',
    serviceGroup: 'Nhóm Dịch Vụ',
    serviceCharge: 'Phí Dịch Vụ',
    product: 'Sản phẩm',
    productCode: 'Mã Sản Phẩm',
    productName: 'Tên Sản Phẩm',
    productCat: 'Nhóm Sản Phẩm',
    productContainer: 'Đơn vị SP',
    importQty: 'Số lượng Nhập',
    exportQty: 'Số lượng Xuất',
    currentStockQty: 'Số lượng tồn',
    historyWarehouse: 'Lịch sử Nhập Xuất',
    existence: 'Tồn',
    status: 'Trạng thái',
    type: 'Loại',
    cabinetKeyNo: 'Chìa khóa tủ',
    nationality: 'Quốc tịch',
    address: 'Địa chỉ',
    branch: 'Chi nhánh',
    branchCode: 'Mã Chi nhánh',
    branchName: 'Tên Chi nhánh',
    roomBed: 'Giường',
    roomName: 'Tên Phòng',
    roomType: 'Loại Phòng',
    roomRating: 'Hạng Phòng',
    identityCard: 'Số CMND/CCCD',
    dob: 'Ngày sinh',
    email: 'Email',
    basicInfo: 'Thông tin cơ bản',
    customer: 'Khách Hàng',
    customerName: 'Tên Khách Hàng',
    customerGroup: 'Nhóm Khách Hàng',
    customerGroupName: 'Tên nhóm Khách Hàng',
    customerQty: 'SL khách hàng',
    lockerNo: 'Khoá tủ',
    membershipLevel: 'Hạng thành viên',
    membershipName: 'Tên Hạng thành viên',
    membershipBenefits: 'Quyền lợi Thành viên',
    minSpendingAmount: 'Doanh thu thực (VNĐ)',
    minAccumulateSpending: 'Chi tiêu tích lũy (VNĐ)',
    membershipLevelReviewCycle: 'Chu kỳ xét lại hạng (ngày)',
    levelUpConditions: 'Điều kiện lên hạng',
    levelAdjustment: 'Xét lại hạng',
    fullName: 'Tên đầy đủ',
    phone: 'Số điện thoại',
    gender: 'Giới tính',
    currentPassword: 'Mật khẩu hiện tại',
    newPassword: 'Mật khẩu mới',
    confirmNewPassword: 'Xác nhận mật khẩu mới',
    workSchedules: 'Lịch làm việc',
    employeeCode: 'Mã Nhân viên',
    therapist: 'KTV',
    selectTherapist: 'Chọn KTV',
    commissionFee: 'Tiền Tour',
    therapistCode: 'Mã KTV',
    serviceRequestNumber: 'DV được Y/C',
    servicesAccordingToTour: 'DV theo Tour',
    therapistFee: 'Phí Y/C KTV',
    defaultTherapist: 'KTV mặc định',
    requestedTherapist: 'KTV được Y/C',
    personalInfo: 'Thông tin cá nhân',
    prefixCode: 'Ký hiệu',
    displayOrder: 'Thứ tự hiển thị',
    unitPrice: 'Đơn Giá (VNĐ)',
    exitPrice: 'Giá bán (VNĐ)',
    value: 'Giá trị',
    deposit: 'Nạp tiền',
    depositAmount: 'Số tiền cần nạp (VNĐ)',
    availableDeposit: 'Tiền nạp hiện có',
    totalAmount: 'Tổng Tiền',
    giftVoucher: 'Gift Voucher',
    giftVoucherGroup: 'Nhóm Gift Voucher',
    giftVoucherGroupName: 'Tên Nhóm Gift Voucher',
    promotionalValue: 'Giá trị Khuyến mãi',
    discountCategory: 'Loại ưu đãi',
    discount: 'Giảm giá',
    discountOnOrder: 'Giảm giá trên Đơn hàng',
    discountOnService: 'Giảm giá trên Dịch vụ',
    appointmentList: 'Danh sách Booking',
    appointmentTime: 'TG khách đến',
    appointmentCode: 'Mã booking',
    notes: 'Ghi chú',
    note1: 'Ghi chú 1',
    note2: 'Ghi chú 2',
    date: 'Ngày',
    startTime: 'TG bắt đầu',
    endTime: 'TG kết thúc',
    createdAt: 'Thời gian tạo',
    createdBy: 'Người tạo',
    purchasedAt: 'Ngày mua',
    redeemedAt: 'Ngày sử dụng',
    usagePlace: 'Nơi sử dụng',
    paidAt: 'TG Thanh toán',
    ttl: 'Hạn sử dụng (Ngày)',
    moreInfo: 'Thông tin khác',
    quantity: 'Số lượng',
    currentStock: 'Số lượng hiện có',
    serialNo: 'Số Seri',
    serialList: 'Danh sách số Seri',
    warehouse: 'Kho',
    giftVoucherCats: 'Loại Voucher',
    order: 'Đơn hàng',
    invoiceId: 'Số Hóa đơn',
    paymentMethod: 'Phương thức thanh toán',
    offers: 'Ưu đãi',
    offersPercentage: 'Ưu đãi (%)',
    depositHistory: 'Lịch sử Nạp tiền',
    printedAt: 'Ngày in',
    itemList: 'Dịch vụ/Sản phẩm',
    finalizedAmount: 'Thành tiền',
    tempCalculated: 'Tạm tính',
    paymentInformation: 'Thông tin thanh toán',
    total: 'Tổng cộng',
    other: 'Khác',
    tempCalculationSlip: 'Phiếu tạm tính',
    giftVoucherHasNotBeenSetUpCondition: 'Gift voucher chưa được thiết lập điều kiện sử dụng',
    tipIncludes: 'Đã bao gồm tip/ Tip includes',
    depositPrice: 'Tiền nạp',
    hasNotStartedServices: 'Chưa bắt đầu DV',
    processingServices: 'Đang thực hiện DV',
    moreThanOneTherapistHasBeenSelected: 'Có nhiều hơn 1 KTV đã được chọn',
    page: 'trang',
    payByDeposit: 'Thanh toán bằng tiền nạp',
    discountOn: 'Giảm giá cho',
    exportEmployees: 'Xuất danh sách Nhân viên',
    exportServices: 'Xuất danh sách Dịch vụ'
  },

  layout: {
    title: 'Moc Huong Spa'
  },

  pagination: {
    totalEntries: 'Hiển thị {{range0}} - {{range1}} trong {{total}} mục'
  },

  feeType: {
    TIPS: 'Tiền Tip',
    extra: 'Phụ phí (%)',
    commission: 'Tiền Tour (VNĐ)',
    requestedTherapist: 'Phí Y/C KTV',
    room: 'Phí Phòng',
    unitPrice: 'Đơn Giá'
  },

  discountCategory: {
    totalBillDiscount: 'Giảm trên tổng bill',
    roomDiscount: 'Giảm trên loại phòng',
    roomDiscountDetails: 'Giảm giá các phòng {{room}}',
    serviceFeeDiscount: 'Giảm giá các Dịch vụ trên đơn hàng'
  },

  noData: {
    title: 'Danh sách dữ liệu sẽ được hiển thị ở đây',
    desc: 'Bạn chưa có dữ liệu nào. Vui lòng thêm mới ngay!'
  },

  contact: 'Hotline, Viber, Zalo hỗ trợ: <strong>0935 666 158</strong>',
  copyright: 'Copyright © 2024 Mộc Hương Spa',
  forgotPassword: 'Quên mật khẩu?',

  setting: {
    logout: 'Đăng xuất',
    profile: 'Hồ sơ',
    languageEN: 'Tiếng Anh',
    languageVI: 'Tiếng Việt'
  },

  noResult: 'Không tìm thấy kết quả!',
  pleaseTryAgain: 'Vui lòng thử lại',

  status: {
    active: 'Hoạt động',
    inactive: 'Ngừng hoạt động',
    read: 'Đã đọc',
    unRead: 'Chưa đọc'
  },

  gender: {
    MALE: 'Nam',
    FEMALE: 'Nữ'
  },

  text: {
    or: 'hoặc'
  },

  messages: {
    loadPageError: 'An error occurred while loading the page',
    exportFailed: 'Xuất file không thành công',
    importFailed: 'Nhập file không thành công',
    importSuccess: 'Nhập file thành công',
    recordAssociatedWithExistingData: 'Bản ghi này đang gắn với dữ liệu khác'
  },
  description: {
    warningDeleteAction: 'Bản ghi sẽ bị xóa và bạn không thể khôi phục lại.'
  },

  importExcelError: {
    duplicatedCode: 'Trùng mã',
    duplicatedPhoneNumber: 'Trùng SĐT',
    codeIsNotEmpty: 'Mã bị trống',
    phoneIsNotEmpty: 'Số điện thoại bị trống',
    dataError: 'Bị lỗi dữ liệu',
    lines: 'Dòng',
    emailInvalid: 'Email không hợp lệ',
    duplicatedServiceExcelData: 'Trùng tên DV',
    serviceExcelDataIsNotEmpty: 'Nhóm DV/Tên DV/Tên DV viết tắt/Đơn giá/Thời gian bị trống'
  }
};

export default en;
