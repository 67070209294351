import IconFlagVI from 'src/assets/icons/iconFlagVI.svg';
import IconFlagEN from 'src/assets/icons/iconFlagEN.svg';

export enum LANGUAGE {
  VN = 'vn',
  EN = 'en'
}

export const LANGUAGE_ICON = new Map<string, string>([
  [LANGUAGE.VN, IconFlagVI],
  [LANGUAGE.EN, IconFlagEN]
]);
