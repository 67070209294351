const depositCats = {
  title: 'Top-Up Level Management',
  createTitle: 'Create New Top-Up Level',
  updateTitle: 'Update Top-Up Level',
  table: {
    depositAmountFrom: 'Minimum Deposit (VND)',
    from: 'To (VNĐ)'
  }
};

export default depositCats;
