import { messageResponse } from 'src/constants/message-response';

const warehouseHistory = {
  table: {
    title: 'Stock import/export history',
    filter: {
      process: 'Process',
      warehouseFrom: 'Warehouse Source',
      warehouseTo: 'Warehouse Target'
    },
    columns: {
      code: 'Code',
      content: 'Description',
      name: 'Product name/ Gift voucher',
      warehouseFrom: 'Warehouse Source',
      warehouseTo: 'Warehouse Target',
      createdBy: 'Created By'
    }
  },

  placeholder: {
    searchTable: 'Search by Code, Product Name, Gift Voucher, Creator',
    addressGetProduct: 'Enter pickup address',
    branchSelect: 'Select Branch',
    productSelect: 'Select Product',
    giftVoucherSelect: 'Select Gift Voucher'
  },

  form: {
    import: {
      title: 'Warehouse Import',
      titleDetail: 'Warehouse receipt details',
      fields: {
        importFrom: 'Import from',
        importTo: 'Export To'
      }
    },

    export: {
      title: 'Stock dispatch',
      titleDetail: 'Stock dispatch receipt title',
      fields: {
        exportFrom: 'Import from',
        exportTo: 'Export to'
      }
    }
  },

  conditionsModal: {
    title: 'Set conditions',
    label: {
      conditions: 'Conditions',
      placeOfUse: 'Place Of Use'
    },
    checkbox: {
      serviceChange: 'Includes service charge',
      isWeekends: ' Excluding weekends',
      isHolidays: 'Excluding holidays'
    }
  },
  message: {
    importSuccess: 'Import successful',
    exportSuccess: 'Export successful',
    importFailed: 'Import Failed',
    exportFailed: 'Export Failed',
    [messageResponse.giftVoucherNotEnoughQuantity]: 'Gift voucher not enough quantity',
    [messageResponse.productNotEnoughQuantity]: 'Product not enough quantity',
    [messageResponse.giftVoucherCodeWithSeriFromStartNumberToEndNumberIsExisted]:
      'Serial number already exists in stock',
    [messageResponse.startNumberGreaterThanEndNumber]:
      'Số Seri không hợp lệ: start number greater than end number',
    [messageResponse.someGiftVoucherCodeWithSeriFromStartNumberToEndNumber]:
      'Serial number does not exist in stock',
    [messageResponse.giftVoucherCodeNotEnoughQuantity]: 'Insufficient stock quantity',
    [messageResponse.productIdNotEnoughQuantity]: 'Insufficient product stock quantity',
    [messageResponse.someGiftVoucherCodeWithSeriFromStartNumberToEndNumberIsNotExistedInWarehouseFrom]:
      'Serial number does not exist in stock'
  },

  parentWarehouse: 'Parent Warehouse',
  allWarehouse: 'All Warehouse'
};

export default warehouseHistory;
