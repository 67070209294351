import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import { IRoomsBody, IRoomsRequest } from 'src/interfaces/master-data/rooms';

export class GetListRoomsDTO extends DTO {
  public param: object | undefined;
  public query: IRoomsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.ROOMS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IRoomsRequest) {
    super();
    this.query = query;
  }
}

export class CreateRoomsDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IRoomsBody;
  public url: string = ENDPOINT.ROOMS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IRoomsBody) {
    super();
    this.body = body;
  }
}

export class DetailRoomsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.ROOMS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateRoomsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IRoomsBody;
  public url: string = ENDPOINT.ROOMS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IRoomsBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class GetAppoinmentRoomBedDTO extends DTO {
  public param: { roomId: string, bedId: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.ROOMS_BED_APPOINMENT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { roomId: string, bedId: string }) {
    super();
    this.param = param;
  }
}
