const validation = {
  requiredField: 'Please fill in the information.',
  invalidEmail: 'Please enter a valid email address.',
  validationPhoneNumber: 'Please enter in the correct format.',
  validationPassWord:
    'Password must be at least 8 characters long and include one of these special characters "@$!%*?&"',
  messageExist: '{{name}} already exists in the system.',
  invalidUnit: 'Please enter a valid unit.',
  recordAlreadyExistsSystem: 'Record already exists in the system.',
  servicesNotAvailableBranch: 'Some services do not exist at the selected branch.',
  numberOfBedMustBeGreaterThanZero: 'The number of beds must be greater than 0.',
  minOrMaxAmountsInvaild: 'Must be greater than the value in the "Deposit Amount" field',
  rangeValue: 'The starting value must be less than the ending value.',
  notEnoughGiftVoucherCodes: 'Please select the required number of gift vouchers.',
  giftVoucherNeedSettingCondition: 'Conditions need to be set for the gift voucher(s).'
};

export default validation;
