import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateRoomsDTO,
  DetailRoomsDTO,
  GetAppoinmentRoomBedDTO,
  GetListRoomsDTO,
  UpdateRoomsDTO
} from 'src/dto/master-data/rooms.dto';
import { IAppointmentRoomBed } from 'src/interfaces/master-data/branch';
import { IRooms, IRoomsBody, IRoomsItem, IRoomsRequest } from 'src/interfaces/master-data/rooms';
import { IHttpService } from 'src/services/http.service';

export interface IRoomsStore {
  listRooms: IRoomsItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  totalUnread: number;
  fetchList(request?: IRoomsRequest): Promise<void>;
  create(body: IRoomsBody): Promise<ResponseDTO<IRoomsItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IRoomsItem>>;
  getRoomBedAppointment(param: {
    roomId: string;
    bedId: string;
  }): Promise<ResponseDTO<IAppointmentRoomBed[]>>;
  update(body: IRoomsBody, param: { id: string }): Promise<ResponseDTO<IRoomsItem>>;
  destroyStoreWhenLogout(): void;
}

export class RoomsStore implements IRoomsStore {
  listRooms: IRoomsItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  totalUnread = 0;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listRooms: observable.ref,
      pageSize: observable,
      totalPages: observable,
      totalUnread: observable,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IRoomsRequest): Promise<void> {
    const requestDTO = new GetListRoomsDTO(request);
    const result: ResponseDTO<IRooms> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listRooms = result.data?.paginatedResults ?? [];
        this.totalPages = result.data?.total ?? 0;
        this.totalRecords = result.data?.total ?? 0;
        this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  public async create(body: IRoomsBody) {
    const createDTO = new CreateRoomsDTO(body);
    const res: ResponseDTO<IRoomsItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailRoomsDTO(query);
    const res: ResponseDTO<IRoomsItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IRoomsBody, param: { id: string }) {
    const updateStoreDTO = new UpdateRoomsDTO(body, param);
    const res: ResponseDTO<IRoomsItem> = await this.http.request(updateStoreDTO);
    this.fetchList();
    return res;
  }

  public async getRoomBedAppointment(query: { roomId: string; bedId: string }) {
    const detailDTO = new GetAppoinmentRoomBedDTO(query);
    const res: ResponseDTO<IAppointmentRoomBed[]> = await this.http.request(detailDTO);
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.pageSize = 1;
      this.totalPages = 0;
      this.totalUnread = 0;
      this.listRooms = [];
    });
  }
}
