const RoomCats = {
  title: 'Room Category Management',

  table: {
    search: 'Search by Room Category'
  },

  form: {
    titleCreate: 'Create Room Category',
    titleUpdate: 'Update Room Category',
    name: 'Room Category name'
  },

  roomCatsNameAlreadyExists: 'Room Category name already exists in the system!'
};

export default RoomCats;
