import { IOrderBody, IOrderRequest } from 'src/interfaces/master-data/order';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListOrderDTO extends DTO {
  public param: object | undefined;
  public query: IOrderRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.INVOICES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IOrderRequest) {
    super();
    this.query = query;
  }
}

export class CreateOrderDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IOrderBody;
  public url: string = ENDPOINT.INVOICES;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IOrderBody) {
    super();
    this.body = body;
  }
}

export class CalculateOrderDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IOrderBody;
  public url: string = ENDPOINT.INVOICES_CALCULATE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IOrderBody) {
    super();
    this.body = body;
  }
}

export class DetailOrderDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.INVOICES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: number }) {
    super();
    this.param = param;
  }
}

export class UpdateOrderDTO extends DTO {
  public param: { id: number };
  public query: undefined;
  public body: IOrderBody;
  public url: string = ENDPOINT.INVOICES_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IOrderBody, param: { id: number }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class GetExistAppoinmentInvoicesDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_EXIST_APPOINTMENT_INVOICES;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
  }
}
