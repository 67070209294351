import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateProductContainersDTO,
  DeleteProductContainersDTO,
  DetailProductContainersDTO,
  GetListProductContainersDTO,
  UpdateProductContainersDTO
} from 'src/dto/master-data/product-containers.dto';
import { ICustomerCatsItem } from 'src/interfaces/master-data/customer-cats';
import {
  IProductContainersBody,
  IProductContainersItem,
  IProductContainersRequest
} from 'src/interfaces/master-data/product-containers';
import { IHttpService } from 'src/services/http.service';

export interface IProductContainersStore {
  listProductContainers: IProductContainersItem[];

  fetchList(request?: IProductContainersRequest): Promise<void>;
  create(body: IProductContainersBody): Promise<ResponseDTO<IProductContainersItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IProductContainersItem>>;
  update(
    body: IProductContainersBody,
    param: { id: string }
  ): Promise<ResponseDTO<IProductContainersItem>>;
  delete(param: { id: string }): Promise<ResponseDTO<IProductContainersItem>>;
  destroyStoreWhenLogout(): void;
}

export class ProductContainersStore implements IProductContainersStore {
  listProductContainers: ICustomerCatsItem[] = [];

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listProductContainers: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IProductContainersRequest): Promise<void> {
    const requestDTO = new GetListProductContainersDTO(request);
    const result: ResponseDTO<IProductContainersItem[]> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listProductContainers = result?.data ?? [];
      });
    }
  }

  public async create(body: IProductContainersBody) {
    const createDTO = new CreateProductContainersDTO(body);
    const res: ResponseDTO<IProductContainersItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailProductContainersDTO(query);
    const res: ResponseDTO<IProductContainersItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IProductContainersBody, param: { id: string }) {
    const updateStoreDTO = new UpdateProductContainersDTO(body, param);
    const res: ResponseDTO<IProductContainersItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async delete(param: { id: string }) {
    const deleteStoreDTO = new DeleteProductContainersDTO(param);
    const res: ResponseDTO<IProductContainersItem> = await this.http.request(deleteStoreDTO);
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listProductContainers = [];
    });
  }
}
