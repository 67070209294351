import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import { ICustomer } from 'src/interfaces/customer';
import { IPackageItem, IServicesItem } from 'src/interfaces/services';
import { IProductCat } from '../master-data/product-cats.dto';
import { IWarehouse } from '../master-data/inventory-history.dto';
import { IInventory } from '../master-data/inventory.dto';
import { DISCOUNT_CATS, GIFT_VOUCHER_GROUPS_CATS } from 'src/constants';

export interface IGetListPrefixCodeResponse {
  paginatedResults?: IPrefixCodeItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}

export class IGetListGiftVoucherValidResponse {
  paginatedResults?: IGiftVoucherItem[];
  current?: number;
  page?: number;
  limit?: number;
  total?: number;
}

export interface IGetListGiftVoucherResponse {
  data: IGiftVoucherItem[];
}

export interface IGetListGiftVoucherCatsResponse {
  data: IGiftVoucherCat[];
}

export interface IGiftVoucherCat {
  id: number;
  name: string;
  desc?: string;
}

export interface IDiscountCat {
  id: number;
  name: string;
  desc?: string;
}

export interface IGetListPrefixCodeRequest {
  keyword?: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  loyaltyProgramId?: number;
  roomCatId?: number;
  discountCatId?: number;
}

export interface IGetListGiftVoucherValidRequest {
  keyword?: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  branchId: number;
  serviceId?: number[];
  packageId?: number[];
}

export interface IPrefixCodeItem {
  code: string;
  exitPrice: number;
  faceValue: number;
  isIncludingServiceCharge: boolean;
  discountValue: number;
  ttl: number;
  giftVoucherCatId: number;
  discountCatId: number;
  giftVoucherQuantity: number;
  giftVoucherCat: IGiftVoucherCat;
  discountCat: IDiscountCat;
  service: IServicesItem[];
  productCategory?: IProductCat;
  inventories?: IInventory[];
}

export interface IGetListGiftVoucherRequest extends IGetListPrefixCodeRequest {
  prefixCode?: string;
  isAvailableToSell?: boolean;
  warehouseId?: number[];
}

export interface IGiftVoucherBranch {
  id: number;
  name: string;
}

export interface IGiftVoucherItem {
  code: string;
  prefixCode: string;
  customerId: number | null;
  customer: ICustomer | null;
  purchasedAt: string | null;
  expireAt: string | null;
  redeemedAt: string | null;
  service: IServicesItem;
  package: IPackageItem;
  warehouse: IWarehouse;
  prefixCodes: IPrefixCodeItem;
  isNotWeekendApplicable: boolean;
  isNotHolidayApplicable: boolean;
  ttl: number;
  seriNumber: string;
  branches: IGiftVoucherBranch[];
  validator: {
    isCanUse: boolean;
    message: string;
  };
}

export interface IPrefixCodeBody {
  code?: string;
  exitPrice: number;
  discountValue?: number;
  discountCatId?: number;
  faceValue?: number;
  discountCat?: DISCOUNT_CATS;
  giftVoucherCat?: GIFT_VOUCHER_GROUPS_CATS;
}

export interface IUpdateGiftVoucherBody {
  branchIds: number[];
  isIncludeServiceCharge?: boolean;
  isNotHolidayApplicable?: boolean;
  isNotWeekendApplicable?: boolean;
  serviceIds: number;
  ttl: number;
}

export class GetListPrefixCodeDTO extends DTO {
  public param: object | undefined;
  public query: IGetListPrefixCodeRequest;
  public body: undefined;
  public url: string = ENDPOINT.GET_PREFIX_CODE_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query: IGetListPrefixCodeRequest) {
    super();
    this.query = query;
  }
}

export class GetListGiftVoucherDTO extends DTO {
  public param: { prefixCode: string };
  public query: IGetListGiftVoucherRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_LIST_GIFT_VOUCHERS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { prefixCode: string }, query?: IGetListGiftVoucherRequest | undefined) {
    super();
    this.param = param;
    this.query = query;
  }
}
export class GetDiscountGiftVoucherDTO extends DTO {
  public param: { code: string };
  public query: { serviceId: number[]; branchId: number } | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_DISCOUNT_GIFT_VOUCHER;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(
    param: { code: string },
    query: { serviceId: number[]; branchId: number } | undefined
  ) {
    super();
    this.param = param;
    this.query = query;
  }
}
export class CreatePrefixCodeDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IPrefixCodeBody;
  public url: string = ENDPOINT.GET_PREFIX_CODE_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IPrefixCodeBody) {
    super();
    this.body = body;
  }
}

export class UpdatePrefixCodeDTO extends DTO {
  public param: { prefixCode: string };
  public query: undefined;
  public body: IPrefixCodeBody;
  public url: string = ENDPOINT.GET_PREFIX_CODE_DETAILS;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IPrefixCodeBody, param: { prefixCode: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DetailPrefixCodeDTO extends DTO {
  public param: { prefixCode: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_PREFIX_CODE_DETAILS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { prefixCode: string }) {
    super();
    this.param = param;
  }
}

export class DetailGiftVoucherDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_GIFT_VOUCHER_DETAILS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class GetListGiftVoucherCategoriesDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_LIST_GIFT_VOUCHER_CATS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
}

export class GetListGiftVoucherValidDTO extends DTO {
  public param: undefined;
  public query: IGetListGiftVoucherValidRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_LIST_GIFT_VOUCHER_VALID;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IGetListGiftVoucherValidRequest) {
    super();
    this.query = query;
  }
}

export class GetVoucherListByWarehouseIdDTO extends DTO {
  public param: { warehouseId: number } | undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_LIST_GIFT_VOUCHERS_BY_WAREHOUSE_ID;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';

  constructor(param: { warehouseId: number } | undefined) {
    super();
    this.param = param;
  }
}

export class UpdateGiftVoucherCodeDTO extends DTO {
  public param: { code: string } | undefined;
  public query: undefined;
  public body: IUpdateGiftVoucherBody | undefined;
  public url: string = ENDPOINT.UPDATE_GIFT_VOUCHER_CODE;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { code: string }, body: IUpdateGiftVoucherBody) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DeletePrefixCodeDTO extends DTO {
  public param: { prefixCode: string };
  public query: undefined;
  public body: unknown;
  public url: string = ENDPOINT.GET_PREFIX_CODE_DETAILS;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { code: string }) {
    super();
    this.param = {
      prefixCode: param.code
    };
  }
}
