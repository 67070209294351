import { isUndefined } from 'lodash';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateServicesDTO,
  DetailServicesDTO,
  ExportServicesDTO,
  GetListServicesDTO,
  GetListServicesWithPackagesDTO,
  ImportServicesDTO,
  UpdateServicesDTO
} from 'src/dto/services.dto';
import { IOptions } from 'src/interfaces';
import {
  IExportServicesRequest,
  IServices,
  IServicesBody,
  IServicesItem,
  IServicesRequest,
  IServicesWithPackages
} from 'src/interfaces/services';
import { IHttpService } from 'src/services/http.service';

export interface IServicesStore {
  listServices: IServicesItem[];
  listServicesWithPackages: IServicesWithPackages | null;
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  totalUnread: number;
  fetched: boolean;
  fetchList(request?: IServicesRequest, isStoreData?: boolean): Promise<ResponseDTO<IServices>>;
  fetchListWithPackages(request?: IServicesRequest): Promise<void>;
  search(request?: IServicesRequest): Promise<IServices | undefined>;
  create(body: IServicesBody): Promise<ResponseDTO<IServicesItem>>;
  getDetail(param: { id: string }): Promise<ResponseDTO<IServicesItem>>;
  update(body: IServicesBody, param: { id: string }): Promise<ResponseDTO<IServicesItem>>;
  destroyStoreWhenLogout(): void;
  export(request: IExportServicesRequest): Promise<boolean>;
  import(body: FormData): Promise<ResponseDTO<IServicesItem[]>>;

  get optionList(): IOptions[];
}

export class ServicesStore implements IServicesStore {
  listServices: IServicesItem[] = [];
  listServicesWithPackages: IServicesWithPackages | null = null;
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  totalUnread = 0;
  fetched = false;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listServices: observable.ref,
      listServicesWithPackages: observable.ref,
      pageSize: observable,
      totalPages: observable,
      totalUnread: observable,
      totalRecords: observable,
      fetchList: action.bound
    });
  }

  public async fetchList(
    request?: IServicesRequest,
    isStoreData?: boolean
  ): Promise<ResponseDTO<IServices>> {
    const requestDTO = new GetListServicesDTO(request);
    const result: ResponseDTO<IServices> = await this.http.request(requestDTO);

    if (isUndefined(isStoreData) || isStoreData) {
      if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
        runInAction(() => {
          this.listServices = result.data?.paginatedResults ?? [];
          this.totalPages = result.data?.total ?? 0;
          this.totalRecords = result.data?.total ?? 0;
          this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
          this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;
          this.fetched = true;
        });
      }
    }
    return Promise.resolve(result);
  }

  public async fetchListWithPackages(request?: IServicesRequest): Promise<void> {
    const requestDTO = new GetListServicesWithPackagesDTO(request);
    const result: ResponseDTO<IServicesWithPackages> = await this.http.request(requestDTO);

    runInAction(() => {
      this.listServicesWithPackages = result.data as IServicesWithPackages;
    });
  }

  public async search(request?: IServicesRequest): Promise<IServices | undefined> {
    const requestDTO = new GetListServicesDTO(request);
    const result: ResponseDTO<IServices> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      return result.data;
    }
    return undefined;
  }

  public async create(body: IServicesBody) {
    const createDTO = new CreateServicesDTO(body);
    const res: ResponseDTO<IServicesItem> = await this.http.request(createDTO);
    this.fetchList();
    return res;
  }

  public async getDetail(query: { id: string }) {
    const detailDTO = new DetailServicesDTO(query);
    const res: ResponseDTO<IServicesItem> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IServicesBody, param: { id: string }) {
    const updateStoreDTO = new UpdateServicesDTO(body, param);
    const res: ResponseDTO<IServicesItem> = await this.http.request(updateStoreDTO);
    this.fetchList();
    return res;
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.pageSize = 1;
      this.totalPages = 0;
      this.totalUnread = 0;
      this.listServices = [];
      this.fetched = false;
    });
  }

  get optionList(): IOptions[] {
    return this.listServices.map((item: IServicesItem) => ({
      label: item.name,
      value: item.id
    }));
  }

  public async export(request: IExportServicesRequest): Promise<boolean> {
    const exportServicesDTO = new ExportServicesDTO(request);
    return this.http.downloadFile(
      exportServicesDTO.interpolatedUrl(),
      undefined,
      exportServicesDTO.method
    );
  }

  public async import(body: FormData): Promise<ResponseDTO<IServicesItem[]>> {
    const importServicesDTO = new ImportServicesDTO(body);
    const res: ResponseDTO<IServicesItem[]> = await this.http.request(importServicesDTO);
    return res;
  }
}
