const Employee = {
  employeeManagement: 'Employee Management',
  createEmployees: 'Create Employees',
  updateEmployees: 'Update Employees',

  form: {
    password: 'Password',
    otherInfo: 'Other information',
    hireDate: 'Hire Date'
  },

  placeholder: {
    inputSearch: 'Search by Employee ID, Name, Email, Phone Number'
  },

  errorMessage: {
    therapistCodeExists: 'The technician code already exists in the Moc Huong Spa system'
  },

  statuses: {
    available: 'Available',
    idle: 'Idle',
    onService: 'Service in progress',
    isNotCheckin: 'Not check-in'
  }
};

export default Employee;
