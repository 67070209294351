import { messageResponse } from 'src/constants/message-response';

const workSchedules = {
  workSchedulesPage: 'Work Schedule List',
  scheduleName: 'Shift {{number}}',
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  noCheckInOut: 'No check in/out',
  month: 'Month',

  button: {
    add: '+ Add',
    import: 'Import file',
    export: 'Export file'
  },
  modal: {
    title: 'Add shift employee {{shiftNumber}}'
  },
  errorMessage: {
    [messageResponse.workScheduleDoNotEnterPasteDates]:
      'Updating work schedule data for the past is not allowed.',
    [messageResponse.workScheduleDoNotEnterPasteDates]:
      'This therapist is currently checked in at a different branch.'
  }
};

export default workSchedules;
