const products = {
  managementProduct: 'Product Management',
  createProduct: 'Create Product',
  editProduct: 'Edit Product',

  placeholder: {
    search: 'Search by Product Code, Product Name'
  }
};

export default products;
