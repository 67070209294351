const validation = {
  requiredField: 'Vui lòng điền thông tin.',
  invalidEmail: 'Vui lòng nhập email hợp lệ.',
  validationPhoneNumber: 'Vui lòng nhập theo định dạng.',
  validationPassWord:
    'Mật khẩu phải dài ít nhất 8 ký tự và chứa một trong những ký tự đặc biệt sau "@$!%*?&"',
  messageExist: '{{name}} đã tồn tại trong hệ thống.',
  invalidUnit: 'Vui lòng nhập đơn vị hợp lệ.',
  recordAlreadyExistsSystem: 'Đã tồn tại bản ghi trong hệ thống',
  servicesNotAvailableBranch: 'Một số Dịch vụ không tồn tại ở chi nhánh đã chọn.',
  numberOfBedMustBeGreaterThanZero: 'Số lượng giường phải lớn hơn 0',
  minOrMaxAmountsInvaild: 'Phải lớn hơn giá trị ở trường "Số tiền nạp"',
  rangeValue: 'Giá trị bắt đầu phải nhỏ hơn giá trị kết thúc',
  notEnoughGiftVoucherCodes: 'Vui lòng chọn đủ số lượng gift voucher',
  giftVoucherNeedSettingCondition: 'Cần thiết lập điều kiện cho Gift Voucher(s)'
};

export default validation;
