import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, matchRoutes } from 'react-router-dom';
import { PermissionType } from 'src/constants/permission';
import { PAGE_ROUTE } from 'src/constants/route';
import useService from 'src/hooks/use-service';
import { IAuthenticationService } from 'src/services/authentication.service';

interface IProtected {
  children: React.ReactElement;
}
const ProtectedRoute: React.FC<IProtected> = ({ children }) => {
  const authService: IAuthenticationService = useService('authenticationService');
  const ROUTE_PermissionType_ACCESS_MAP: { [key in string]?: Array<PermissionType> } = {
    //admin
    [PAGE_ROUTE.USERS_MANAGEMENT]: [PermissionType.USERS],
    [PAGE_ROUTE.USERS_MANAGEMENT_CREATE]: [PermissionType.USERS],
    [PAGE_ROUTE.USERS_MANAGEMENT_UPDATE]: [PermissionType.USERS]
  };
  if (!authService.isAuthenticated) {
    return <Navigate to={PAGE_ROUTE.LOGIN} replace />;
  }
  
  //check PermissionType
  const listMyPermissionType: PermissionType[] = authService.listMyPermission;

  const listRoute = Object.values(PAGE_ROUTE as Object).map((value) => ({
    path: value
  }));

  const [{ route }] = matchRoutes(listRoute, location.pathname) ?? [];
  const listPermissionTypeAccessRoute = ROUTE_PermissionType_ACCESS_MAP[route.path as PAGE_ROUTE];
  if (
    listPermissionTypeAccessRoute?.length &&
    listPermissionTypeAccessRoute.every((r) => !listMyPermissionType.includes(r))
  ) {
    return <Navigate to={PAGE_ROUTE.ACCESS_DENIED} replace />;
  }

  return children;
};

export default observer(ProtectedRoute);
