export enum RoleName {
  SUPER_ADMIN = 'SUPER-ADMIN',
  ADMIN = 'ADMIN',
  ACCOUNTANT = 'ACCOUNTANT',
  RECEPTIONIST = 'RECEPTIONIST',
  MANAGER = 'MANAGER',
  THERAPIST = 'THERAPIST'
}

export const RoleOrder = [
  RoleName.SUPER_ADMIN,
  RoleName.THERAPIST,
  RoleName.RECEPTIONIST,
  RoleName.ACCOUNTANT,
  RoleName.MANAGER,
  RoleName.ADMIN
];

export enum RoleNameCode {
  ADMIN = 1,
  ACCOUNTANT = 4,
  RECEPTIONIST = 5,
  MARKETING = 6,
  EMPLOYEE = 9,
  CONSULTANT = 2
}

export const RoleNameMapper = new Map<RoleName, string>();
RoleNameMapper.set(RoleName.SUPER_ADMIN, 'Super Admin');
RoleNameMapper.set(RoleName.ADMIN, 'Admin');
RoleNameMapper.set(RoleName.MANAGER, 'Quản lý');
RoleNameMapper.set(RoleName.RECEPTIONIST, 'Lễ tân');
RoleNameMapper.set(RoleName.ACCOUNTANT, 'Kế toán');
RoleNameMapper.set(RoleName.THERAPIST, 'KTV');

export enum PermissionType {
  // ADMIN
  USERS = 'Users',

  // Appointment
  APPOINTMENT_VIEW = 'APPOINTMENT: VIEW',
  APPOINTMENT_VIEW_UPDATE_CREATE = 'APPOINTMENT: VIEW/ADD/UPDATE',

  // Customer category
  CUSTOMER_CATEGORY_VIEW = 'CUSTOMER CATEGORY: VIEW',
  CUSTOMER_CATEGORY_VIEW_ADD_UPDATE = 'CUSTOMER CATEGORY: VIEW/ADD/UPDATE',

  // Customer
  CUSTOMER_VIEW = 'CUSTOMER: VIEW',
  CUSTOMER_VIEW_ADD_UPDATE = 'CUSTOMER: VIEW/ADD/UPDATE',

  // Branch
  BRANCH_VIEW = 'BRANCH: VIEW',
  BRANCH_VIEW_ADD_UPDATE = 'BRANCH: VIEW/ADD/UPDATE',

  // membership
  MEMBERSHIP_LEVEL_VIEW = 'MEMBERSHIP LEVEL: VIEW',
  MEMBERSHIP_LEVEL_VIEW_ADD_UPDATE = 'MEMBERSHIP LEVEL: VIEW/ADD/UPDATE',

  // service category
  SERVICE_CATEGORY_VIEW = 'SERVICE CATEGORY: VIEW',
  SERVICE_CATEGORY_VIEW_ADD_UPDATE = 'SERVICE CATEGORY: VIEW/ADD/UPDATE',

  // service
  SERVICE_VIEW = 'SERVICE: VIEW',
  SERVICE_VIEW_ADD_UPDATE = 'SERVICE: VIEW/ADD/UPDATE',

  // product category
  PRODUCT_CATEGORY_VIEW = 'PRODUCT CATEGORY: VIEW',
  PRODUCR_CATEGORY_VIEW_ADD_UPDATE = 'PRODUCT CATEGORY: VIEW/ADD/UPDATE',

  // product
  PRODUCT_VIEW = 'PRODUCT: VIEW',
  PRODUCT_VIEW_ADD_UPDATE = 'PRODUCT: VIEW/ADD/UPDATE',

  // product container
  PRODUCT_CONTAINER_VIEW_ADD_UPDATE = 'PRODUCT CONTAINER: VIEW/ADD/UPDATE',

  // gift voucher gtoup
  GIFT_VOUCHER_GROUP_VIEW = 'GIFT VOUCHER GROUP: VIEW',
  GIFT_VOUCHER_GROUP_VIEW_ADD_UPDATE = 'GIFT VOUCHER GROUP: VIEW/ADD/UPDATE',

  // inventory
  INVENTORY_VIEW = 'INVENTORY: VIEW',
  INVENTORY_VIEW_IMPORT_EXPORT_HISTORIES = 'INVENTORY: VIEW EXPORT/IMPORT HISTORIES',
  INVENTORY_IMPORT_EXPORT = 'INVENTORY: IMPORT/EXPORT',

  // room category
  ROOM_CATEGORY_VIEW = 'ROOM CATEGORY: VIEW',
  ROOM_CATEGORY_VIEW_ADD_UPDATE = 'ROOM CATEGORY: VIEW/ADD/UPDATE',

  // room
  ROOM_VIEW_LIST = 'ROOM: VIEW',
  ROOM_VIEW_ADD_UPDATE = 'ROOM: VIEW/ADD/UPDATE',
  ROOM_VIEW_ROOM_BED_AVAILABILITY = 'ROOM: VIEW ROOM/BED AVAILABILITY',

  // invoice
  INVOICE_VIEW = 'INVOICE: VIEW',
  INVOICE_VIEW_ADD_UPDATE = 'INVOICE: VIEW/ADD/UPDATE',

  // promotion campain
  PROMOTION_CAMPAIGN_VIEW = 'PROMOTION CAMPAIGN: VIEW',
  PROMOTION_CAMPAIGN_VIEW_ADD_UPDATE = 'PROMOTION CAMPAIGN: VIEW/ADD/UPDATE',

  // employee
  EMPLOYEE_VIEW = 'EMPLOYEE: VIEW',
  EMPLOYEE_VIEW_ADD_UPDATE = 'EMPLOYEE: VIEW/ADD/UPDATE',
  WORK_SCHEDULE_VIEW = 'WORK_SCHEDULE: VIEW',
  WORK_SCHEDULE_VIEW_UPDATE_IMPORT_EXPORT = 'WORK_SCHEDULE: VIEW/UPDATE/IMPORT/EXPORT',
  THERAPIST_PRIORITY_VIEW = 'THERAPIST_PRIORITY: VIEW',

  // admin
  RBAC_VIEW_UPDATE = 'RBAC: VIEW/UPDATE',

  // report
  REPORT_THERAPIST = 'REPORT: THERAPIST',
  REPORT_REVENUE = 'REPORT: REVENUE',
  REPORT_CUSTOMER = 'REPORT: CUSTOMER',
  REPORT_PRODUCT = 'REPORT: PRODUCT',
  REPORT_GIFT_VOUCHER = 'REPORT: GIFT VOUCHER',
  REPORT_ROOM_BED_PERFORMANCE = 'REPORT: ROOM BED PERFORMANCE',
  REPORT_PROMOTION_CAMPAIGN = 'REPORT: PROMOTION CAMPAIGN',

  // deposit category
  DEPOSIT_CATEGORY_VIEW_ADD_UPDATE = 'DEPOSIT CATEGORY: VIEW/ADD/UPDATE',

  // package
  PACKAGE_VIEW = 'PACKAGE: VIEW',
  PACKAGE_VIEW_ADD_UPDATE = 'PACKAGE: VIEW/ADD/UPDATE'
}

export enum CrudPermissionEnum {
  VIEW = 'VIEW',
  VIEW_ADD_UPDATE = 'VIEW/ADD/UPDATE'
}
