import { Button, Col, Modal, Row } from 'antd';
import * as React from 'react';
import styles from './toastify-confirm.module.less';
import { useTranslation } from 'react-i18next';
import { i18nKey } from 'src/locales/i18n';

export interface IToastifyConfirmProps {
  openToastify: boolean;
  onCancel?: () => void;
  idDelete?: string;
  onSubmit?: (idDelete?: string) => void;
  title?: string;
  description?: React.ReactNode;
  type?: 'logout' | 'update' | 'create';
}

const ToastifyConfirm: React.FC<IToastifyConfirmProps> = ({
  openToastify,
  onCancel,
  idDelete,
  onSubmit,
  title,
  type,
  description
}: IToastifyConfirmProps) => {
  const [t] = useTranslation();
  return (
    <div>
      <Modal
        centered
        wrapClassName={styles.bodyModal}
        open={openToastify}
        onCancel={onCancel}
        width={400}
        closable={true}
        title={title}
        zIndex={9999999}
        footer={
          <div className={styles.footer}>
            <Row gutter={12}>
              <Col span={12}>
                <Button onClick={onCancel} className={styles.footer_cancel} type="default">
                  {t(i18nKey.button.cancel)}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn-primary"
                  danger={type === 'logout'}
                  onClick={() => {
                    if (onSubmit && idDelete) {
                      onSubmit(idDelete);
                    } else if (onSubmit) {
                      onSubmit();
                    }
                  }}>
                  {t(i18nKey.button.ok)}
                </Button>
              </Col>
            </Row>
          </div>
        }>
        <div className={styles.bodyModal_content}>
          <Row style={{ textAlign: 'center' }}>
            <Col span={24}>
              <div className={styles.bodyModal_content_title}>{description}</div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ToastifyConfirm;
