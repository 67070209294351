export type ThemeType = {
  token: {
    [key: string]: unknown;
  };
  components: {
    [key: string]: unknown;
  };
};

const theme: ThemeType = {
  token: {
    colorPrimary: '#AC5B5D',
    controlHeight: 40,
    colorFill: '#FFFFFF',
    fontFamily: 'Inter',
    subMenuItemBg: '#AC5B5D'
  },
  components: {
    Layout: {
      headerBg: '#AC5B5D',
      colorBgMenu: 'red',
      Sider: {
        colorBgBase: 'red',
        subMenuItemBg: '#AC5B5D'
      }
    },
    Sider: {
      colorPrimary: '#FFFFFF',
      subMenuItemBg: '#AC5B5D'
    }
  }
};

export default theme;
