import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { AppoinmentServiceAction } from 'src/constants/booking.constant';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  CreateBookingDTO,
  DetailBookingDTO,
  GetListBookingDTO,
  UpdateAppointmentServiceStatusDTO,
  UpdateBookingDTO
} from 'src/dto/master-data/booking.dto';
import { IPaginationResponse } from 'src/interfaces';
import { IBooking, IBookingBody, IBookingRequest } from 'src/interfaces/master-data/booking';

import { IHttpService } from 'src/services/http.service';

export interface IBookingStore {
  listBooking: IBooking[];
  fetched: boolean;
  pagination: {
    page: number;
    limit: number;
    current: number;
    total: number;
  };
  params?: IBookingRequest;
  fetchList(request?: IBookingRequest): Promise<void>;
  getBookingList(request?: IBookingRequest): Promise<IBooking[]>;
  create(body: IBookingBody): Promise<ResponseDTO<IBooking>>;
  getDetail(param: { id: number }): Promise<ResponseDTO<IBooking>>;
  update(body: IBookingBody, param: { id: number }): Promise<ResponseDTO<IBooking>>;
  updateAppointmentServiceStatus(
    body: { status: AppoinmentServiceAction },
    param: { appointmentId: number; serviceInstanceId: number }
  ): Promise<ResponseDTO<any>>;
  destroyStoreWhenLogout(): void;
}

export class BookingStore implements IBookingStore {
  listBooking: IBooking[] = [];
  fetched = false;
  pagination = {
    page: PAGINATION_CONFIGURATION.DEFAULT_PAGE,
    limit: PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE,
    current: 1,
    total: 0
  };
  params?: IBookingRequest | undefined;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listBooking: observable.ref,
      pagination: observable.ref,
      fetched: observable.ref,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IBookingRequest): Promise<void> {
    this.params = request;
    const requestDTO = new GetListBookingDTO(request);
    const result: ResponseDTO<IPaginationResponse<IBooking>> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listBooking = result.data?.paginatedResults ?? [];
        this.pagination = {
          limit: result.data?.limit || this.pagination.limit,
          page: result.data?.page || this.pagination.page,
          current: result.data?.current || 0,
          total: result.data?.total || 0
        };
      });
    }
  }

  public async getBookingList(request?: IBookingRequest): Promise<IBooking[]> {
    const requestDTO = new GetListBookingDTO(request);
    const result: ResponseDTO<IPaginationResponse<IBooking>> = await this.http.request(requestDTO);
    return result.data?.paginatedResults || [];
  }

  public async create(body: IBookingBody) {
    const createDTO = new CreateBookingDTO(body);
    const res: ResponseDTO<IBooking> = await this.http.request(createDTO);
    return res;
  }

  public async getDetail(query: { id: number }) {
    const detailDTO = new DetailBookingDTO(query);
    const res: ResponseDTO<IBooking> = await this.http.request(detailDTO);
    return res;
  }

  public async update(body: IBookingBody, param: { id: number }) {
    const updateStoreDTO = new UpdateBookingDTO(body, param);
    const res: ResponseDTO<IBooking> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async updateAppointmentServiceStatus(
    body: { status: AppoinmentServiceAction },
    param: { appointmentId: number; serviceInstanceId: number }
  ) {
    const updateAppointmentServiceStatusDTO = new UpdateAppointmentServiceStatusDTO(body, param);
    return await this.http.request(updateAppointmentServiceStatusDTO);
  }

  public destroyStoreWhenLogout(): void {
    runInAction(() => {
      this.listBooking = [];
    });
  }
}
