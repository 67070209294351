export enum PAGE_ROUTE {
  LOGIN = '/login',
  LOGOUT = '/logout',
  NOT_FOUND = '/404',
  DASHBOARD = '/',
  PROFILE = '/my-profile',
  ACCESS_DENIED = '/access-denied',
  RESET_PASSWORD = '/reset-password',

  POS = '/pos',
  ROOM_BED = '/room-bed',
  ROOM_BED_ID = '/room-bed/:id',

  INVOICES = '/invoices',
  INVOICE_CREATE = '/invoices/create',
  INVOICE_UPDATE = '/invoices/:id/update',

  SERVICES = '/services',
  SERVICES_MANAGEMENT = '/services-management',
  SERVICES_CREATE = '/services/create',
  SERVICES_UPDATE = '/services/:id/update',

  //Masterdata
  MASTER_DATA = '/master-data',

  DEPOSIT_CATS = '/master-data/deposit-cats',
  DEPOSIT_CAT_CREATE = '/master-data/deposit-cats/create',
  DEPOSIT_CAT_UPDATE = '/master-data/deposit-cats/:id/update',

  ROOMS = '/rooms',
  ROOM_CREATE = '/rooms/create',
  ROOM_UPDATE = '/rooms/:id/update',

  PRODUCTS = '/master-data/products',
  PRODUCT_CREATE = '/master-data/products/create',
  PRODUCT_UPDATE = '/master-data/products/:id/update',

  BRANCHES = '/master-data/branches',
  BRANCH_CREATE = '/master-data/branches/create',
  BRANCH_UPDATE = '/master-data/branches/:id/update',

  CUSTOMER_CATS = '/master-data/customer-cats',
  CUSTOMER_CATS_CREATE = '/master-data/customer-cats/create',
  CUSTOMER_CATS_UPDATE = '/master-data/customer-cats/:id/update',

  PRODUCT_CATS = '/master-data/product-cats',
  PRODUCT_CATS_CREATE = '/master-data/product-cats/create',
  PRODUCT_CATS_UPDATE = '/master-data/product-cats/:id/update',

  ROOM_CATS = '/master-data/room-cats',
  ROOM_CATS_CREATE = '/master-data/room-cats/create',
  ROOM_CATS_UPDATE = '/master-data/room-cats/:id/update',

  MEMBERSHIPS = '/master-data/memberships',
  MEMBERSHIP_CREATE = '/master-data/memberships/create',
  MEMBERSHIP_UPDATE = '/master-data/memberships/:id/update',

  PRODUCT_CONTAINERS = '/master-data/product-containers',
  PRODUCT_CONTAINER_CREATE = '/master-data/product-containers/create',
  PRODUCT_CONTAINER_UPDATE = '/master-data/product-containers/:id/update',

  // Product group
  PRODUCT_GROUP = '/product',

  GIFT_VOUCHERS = '/product/gift-voucher',
  GIFT_VOUCHER_CREATE = '/product/gift-vouchers/create',
  GIFT_VOUCHER_UPDATE = '/product/gift-vouchers/:prefix-code/update',
  GIFT_VOUCHER_DETAILS = '/product/gift-vouchers/:prefix-code/details',

  //Promotion discount cats
  PROMOTIONS = '/promotions',
  PROMOTION_CREATE = '/promotions/create',
  PROMOTION_UPDATE = '/promotions/:id/update',

  //Gift voucher group
  GIFT_VOUCHER_GROUPS = '/master-data/gift-voucher-groups',
  GIFT_VOUCHER_GROUP_CREATE = '/master-data/gift-voucher-groups/create',
  GIFT_VOUCHER_GROUP_UPDATE = '/master-data/gift-voucher-groups/:prefix-code/update',
  GIFT_VOUCHER_GROUP_DETAILS = '/master-data/gift-voucher-groups/:prefix-code/details',

  //service group
  SERVICE_GROUPS = '/master-data/service-groups',
  SERVICE_GROUP_CREATE = '/master-data/service-groups/create',
  SERVICE_GROUP_UPDATE = '/master-data/service-groups/:id/update',

  //user
  USERS_MANAGEMENT = '/users',
  USERS_MANAGEMENT_CREATE = '/users/new',
  USERS_MANAGEMENT_UPDATE = '/users/:id/update',

  //Roles
  ROLES = '/roles',
  ROLES_CREATE = '/roles/new',
  ROLES_UPDATE = '/roles/:id/update',

  //Employees
  EMPLOYEES_MANAGEMENT = '/employees-management',
  EMPLOYEES = '/employees',
  EMPLOYEES_CREATE = '/employees/create',
  EMPLOYEES_UPDATE = '/employees/:id/update',

  //Booking
  BOOKING = '/bookings',
  BOOKING_CREATE = '/bookings/create',
  BOOKING_UPDATE = '/bookings/:id/update',

  //work schedules
  ARRANGE_TOUR = '/arrange-tour',

  //work schedules
  WORK_SCHEDULUES = '/work-schedules',
  WORK_SCHEDULUES_EDIT = '/work-schedules/edit',

  // Packages
  PACKAGES = '/packages',
  PACKAGES_CREATE = '/packages/create',
  PACKAGES_UPDATE = '/packages/:id/update',

  //Customer
  CUSTOMER = '/customers',
  CUSTOMER_MANAGEMENT = '/customers-management',
  CUSTOMER_CREATE = '/customers/create',
  CUSTOMER_EDIT = '/customers/:id/update',
  CUSTOMER_DETAIL = '/customers/:id',

  // Warehouse history
  WAREHOUSE_HISTORY = '/transaction-histories',
  WAREHOUSE_HISTORY_ACTION = '/warehouse/create/:action',
  WAREHOUSE_HISTORY_DETAIL = '/transaction-histories/:id/:type',

  // Inventory
  INVENTORY = '/inventories',
  INVENTORY_HISTORIES_DETAIL = '/inventory-txns/histories/:id',

  //notification
  NOTIFICATION = '/notifications',

  // Reports
  REPORTS = '/reports',

  // Therapist
  THERAPIST_REPORTS = '/reports/therapists',

  // Revenue
  REVENUE_REPORTS = '/reports/revenues',

  // Product
  PRODUCT_REPORTS = '/reports/products',

  // Gift voucher
  GIFT_VOUCHER_REPORTS = '/reports/gift-vouchers',

  // Customer
  CUSTOMER_REPORTS = '/reports/customers',

  // Bed Room Performance
  BED_ROOM_PERFORMANCE_REPORTS = '/reports/bed-room-performance',

  // Promotion Campaign
  PROMOTION_CAMPAIGN_REPORTS = '/reports/promotion-campaigns',

  DASHBOARD_USER_MANAGEMENT = '/setting-management',

  //Permissions
  PERMISSIONS = '/rbac'
}
