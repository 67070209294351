import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';
import { DTO } from '../base.dto';
import { ICustomer } from 'src/interfaces/customer';

export class ICustomerRequest {
  keyword?: string;
  status?: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  isActive?: boolean;
}

export class ICustomerResponse {
  paginatedResults!: ICustomer[];
  current!: number;
  page!: number;
  limit!: number;
  total!: number;
}

export class CustomerDTO extends DTO {
  public param: undefined;
  public query: ICustomerRequest;
  public body: undefined;
  public url: string = ENDPOINT.CUSTOMER;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query: ICustomerRequest) {
    super();
    this.query = query;
  }
}
