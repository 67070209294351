import React from 'react';
import { type MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { ReactComponent as MasterDataActive } from 'src/assets/icons/menu-key-active.svg';
import { ReactComponent as MasterData } from 'src/assets/icons/menu-key.svg';
import { ReactComponent as ProfileActive } from 'src/assets/icons/profile-active.svg';
import { ReactComponent as Profile } from 'src/assets/icons/profile.svg';
import { ReactComponent as ServiceActive } from 'src/assets/icons/menu-service-active.svg';
import { ReactComponent as Service } from 'src/assets/icons/menu-service.svg';
import { ReactComponent as RoomActive } from 'src/assets/icons/menu-rooms-active.svg';
import { ReactComponent as Room } from 'src/assets/icons/menu-rooms.svg';
import { ReactComponent as Customer } from 'src/assets/icons/menu-customer.svg';
import { ReactComponent as CustomerActive } from 'src/assets/icons/menu-customer-active.svg';
import { ReactComponent as PosActive } from 'src/assets/icons/menu-pos-active.svg';
import { ReactComponent as Pos } from 'src/assets/icons/menu-pos.svg';
import { ReactComponent as Booking } from 'src/assets/icons/menu-booking.svg';
import { ReactComponent as ProductGroup } from 'src/assets/icons/product-group.svg';
import { ReactComponent as ProductGroupActive } from 'src/assets/icons/product-group-active.svg';
import { ReactComponent as Promotion } from 'src/assets/icons/icon-promotion.svg';
import { ReactComponent as PromotionActive } from 'src/assets/icons/icon-promotion-active.svg';
import { ReactComponent as Reports } from 'src/assets/icons/reports.svg';
import { ReactComponent as ReportsActive } from 'src/assets/icons/report-active.svg';
import { ReactComponent as AdministrationIconActive } from 'src/assets/icons/administration-menu-active.svg';
import { ReactComponent as AdministrationIcon } from 'src/assets/icons/administration-menu.svg';

import { PAGE_ROUTE } from 'src/constants/route';
import { i18nKey } from 'src/locales/i18n';
import { PermissionType } from 'src/constants/permission';

export type MenuItem = Required<MenuProps>['items'][number];

const useMenuItem = () => {
  const location = useLocation();
  const [t] = useTranslation();
  const navigator = useNavigate();

  const defaultMenu: MenuItem[] = [
    {
      label: t(i18nKey.menu.booking),
      key: PAGE_ROUTE.BOOKING,
      icon: <Booking />,
      className: 'menu-not-child',
      onClick: () => navigator(PAGE_ROUTE.BOOKING),
      requiredPermission: [
        PermissionType.APPOINTMENT_VIEW,
        PermissionType.APPOINTMENT_VIEW_UPDATE_CREATE
      ]
    } as MenuItem,
    {
      label: t(i18nKey.menu.pos),
      key: PAGE_ROUTE.POS,
      icon:
        location.pathname.includes(PAGE_ROUTE.ROOM_BED) ||
        location.pathname.includes(PAGE_ROUTE.INVOICES) ? (
          <PosActive />
        ) : (
          <Pos />
        ),
      children: [
        {
          label: t(i18nKey.label.roomBed),
          key: PAGE_ROUTE.ROOM_BED,
          onClick: () => navigator(PAGE_ROUTE.ROOM_BED),
          requiredPermission: [PermissionType.ROOM_VIEW_ROOM_BED_AVAILABILITY]
        },
        {
          label: t(i18nKey.menu.order),
          key: PAGE_ROUTE.INVOICES,
          onClick: () => navigator(PAGE_ROUTE.INVOICES),
          requiredPermission: [PermissionType.INVOICE_VIEW, PermissionType.INVOICE_VIEW_ADD_UPDATE]
        }
      ]
    } as MenuItem,

    {
      label: t(i18nKey.label.reports),
      key: PAGE_ROUTE.REPORTS,
      icon:
        location.pathname.includes(PAGE_ROUTE.REPORTS) ||
        location.pathname.includes(PAGE_ROUTE.THERAPIST_REPORTS) ||
        location.pathname.includes(PAGE_ROUTE.REVENUE_REPORTS) ||
        location.pathname.includes(PAGE_ROUTE.PRODUCT_REPORTS) ? (
          <ReportsActive />
        ) : (
          <Reports />
        ),
      children: [
        {
          label: t(i18nKey.reports.reportType.therapist),
          key: PAGE_ROUTE.THERAPIST_REPORTS,
          onClick: () => navigator(PAGE_ROUTE.THERAPIST_REPORTS),
          requiredPermission: [PermissionType.REPORT_THERAPIST]
        },
        {
          label: t(i18nKey.reports.reportType.revenue),
          key: PAGE_ROUTE.REVENUE_REPORTS,
          onClick: () => navigator(PAGE_ROUTE.REVENUE_REPORTS),
          requiredPermission: [PermissionType.REPORT_REVENUE]
        },
        {
          label: t(i18nKey.reports.reportType.product),
          key: PAGE_ROUTE.PRODUCT_REPORTS,
          onClick: () => navigator(PAGE_ROUTE.PRODUCT_REPORTS),
          requiredPermission: [PermissionType.REPORT_PRODUCT]
        },
        {
          label: t(i18nKey.reports.reportType.giftVoucher),
          key: PAGE_ROUTE.GIFT_VOUCHER_REPORTS,
          onClick: () => navigator(PAGE_ROUTE.GIFT_VOUCHER_REPORTS),
          requiredPermission: [PermissionType.REPORT_GIFT_VOUCHER]
        },
        {
          label: t(i18nKey.reports.reportType.customer),
          key: PAGE_ROUTE.CUSTOMER_REPORTS,
          onClick: () => navigator(PAGE_ROUTE.CUSTOMER_REPORTS),
          requiredPermission: [PermissionType.REPORT_CUSTOMER]
        },
        {
          label: t(i18nKey.reports.reportType.bedRoomPerformance),
          key: PAGE_ROUTE.BED_ROOM_PERFORMANCE_REPORTS,
          onClick: () => navigator(PAGE_ROUTE.BED_ROOM_PERFORMANCE_REPORTS),
          requiredPermission: [PermissionType.REPORT_ROOM_BED_PERFORMANCE]
        },
        {
          label: t(i18nKey.reports.reportType.promotionCampaign),
          key: PAGE_ROUTE.PROMOTION_CAMPAIGN_REPORTS,
          onClick: () => navigator(PAGE_ROUTE.PROMOTION_CAMPAIGN_REPORTS),
          requiredPermission: [PermissionType.REPORT_PROMOTION_CAMPAIGN]
        }
      ]
    } as MenuItem,

    {
      label: t(i18nKey.menu.employee),
      key: PAGE_ROUTE.EMPLOYEES_MANAGEMENT,
      icon:
        location.pathname.includes(PAGE_ROUTE.EMPLOYEES) ||
        location.pathname.includes(PAGE_ROUTE.EMPLOYEES_CREATE) ||
        location.pathname.includes(PAGE_ROUTE.WORK_SCHEDULUES) ||
        location.pathname.includes(PAGE_ROUTE.EMPLOYEES_UPDATE) ? (
          <ProfileActive />
        ) : (
          <Profile />
        ),
      children: [
        {
          label: t(i18nKey.menu.arrangeTour),
          key: PAGE_ROUTE.ARRANGE_TOUR,
          onClick: () => navigator(PAGE_ROUTE.ARRANGE_TOUR),
          requiredPermission: [PermissionType.THERAPIST_PRIORITY_VIEW]
        },
        {
          label: t(i18nKey.label.workSchedules),
          key: PAGE_ROUTE.WORK_SCHEDULUES,
          onClick: () => navigator(PAGE_ROUTE.WORK_SCHEDULUES),
          requiredPermission: [
            PermissionType.WORK_SCHEDULE_VIEW_UPDATE_IMPORT_EXPORT,
            PermissionType.WORK_SCHEDULE_VIEW
          ]
        },
        {
          label: t(i18nKey.label.list),
          key: PAGE_ROUTE.EMPLOYEES,
          onClick: () => navigator(PAGE_ROUTE.EMPLOYEES),
          requiredPermission: [
            PermissionType.EMPLOYEE_VIEW,
            PermissionType.EMPLOYEE_VIEW_ADD_UPDATE
          ]
        }
      ]
    } as MenuItem,

    {
      label: t(i18nKey.label.customer),
      key: PAGE_ROUTE.CUSTOMER_MANAGEMENT,
      icon: location.pathname.includes(PAGE_ROUTE.CUSTOMER) ? <CustomerActive /> : <Customer />,
      children: [
        {
          label: t(i18nKey.label.list),
          key: PAGE_ROUTE.CUSTOMER,
          onClick: () => navigator(PAGE_ROUTE.CUSTOMER),
          requiredPermission: [
            PermissionType.CUSTOMER_VIEW,
            PermissionType.CUSTOMER_VIEW_ADD_UPDATE
          ]
        }
      ]
    } as MenuItem,

    {
      label: t(i18nKey.label.service),
      key: PAGE_ROUTE.SERVICES,
      className: 'menu-not-child',
      onClick: () => navigator(PAGE_ROUTE.SERVICES),
      requiredPermission: [PermissionType.SERVICE_VIEW, PermissionType.SERVICE_VIEW_ADD_UPDATE],
      icon:
        useMatch(PAGE_ROUTE.SERVICES) || location.pathname.includes(PAGE_ROUTE.SERVICES) ? (
          <ServiceActive />
        ) : (
          <Service />
        )
    } as MenuItem,

    {
      label: t(i18nKey.page.package),
      key: PAGE_ROUTE.PACKAGES,
      className: 'menu-not-child',
      onClick: () => navigator(PAGE_ROUTE.PACKAGES),
      icon: location.pathname.includes(PAGE_ROUTE.PACKAGES) ? <ServiceActive /> : <Service />,
      requiredPermission: [PermissionType.PACKAGE_VIEW, PermissionType.PACKAGE_VIEW_ADD_UPDATE]
    } as MenuItem,

    {
      label: t(i18nKey.label.product),
      key: PAGE_ROUTE.PRODUCT_GROUP,
      icon: location.pathname.includes(PAGE_ROUTE.PRODUCT_GROUP) ? (
        <ProductGroupActive />
      ) : (
        <ProductGroup />
      ),
      children: [
        {
          label: t(i18nKey.menu.inventory),
          key: PAGE_ROUTE.INVENTORY,
          onClick: () => navigator(PAGE_ROUTE.INVENTORY),
          requiredPermission: [
            PermissionType.INVENTORY_VIEW,
            PermissionType.INVENTORY_IMPORT_EXPORT
          ]
        },
        {
          label: t(i18nKey.menu.warehouseHistory),
          key: PAGE_ROUTE.WAREHOUSE_HISTORY,
          onClick: () => navigator(PAGE_ROUTE.WAREHOUSE_HISTORY),
          requiredPermission: [PermissionType.INVENTORY_VIEW_IMPORT_EXPORT_HISTORIES]
        }
      ]
    } as MenuItem,

    {
      label: t(i18nKey.menu.room),
      key: PAGE_ROUTE.ROOMS,
      className: 'menu-not-child',
      onClick: () => navigator(PAGE_ROUTE.ROOMS),
      icon:
        useMatch(PAGE_ROUTE.ROOMS) || location.pathname.includes(PAGE_ROUTE.ROOMS) ? (
          <RoomActive />
        ) : (
          <Room />
        ),
      requiredPermission: [PermissionType.ROOM_VIEW_LIST, PermissionType.ROOM_VIEW_ADD_UPDATE]
    } as MenuItem,

    {
      label: 'Administration',
      key: PAGE_ROUTE.DASHBOARD_USER_MANAGEMENT,
      icon: location.pathname.includes(PAGE_ROUTE.PERMISSIONS) ? (
        <AdministrationIconActive />
      ) : (
        <AdministrationIcon />
      ),
      children: [
        {
          label: 'RBAC',
          key: PAGE_ROUTE.PERMISSIONS,
          onClick: () => navigator(PAGE_ROUTE.PERMISSIONS),
          requiredPermission: [PermissionType.RBAC_VIEW_UPDATE]
        }
      ]
    } as MenuItem,

    {
      label: t(i18nKey.menu.promotionDiscountCats),
      key: PAGE_ROUTE.PROMOTIONS,
      className: 'menu-not-child',
      onClick: () => navigator(PAGE_ROUTE.PROMOTIONS),
      icon:
        useMatch(PAGE_ROUTE.PROMOTION_CAMPAIGN_REPORTS) ||
        location.pathname.includes(PAGE_ROUTE.PROMOTIONS) ? (
          <PromotionActive />
        ) : (
          <Promotion />
        ),
      requiredPermission: [
        PermissionType.PROMOTION_CAMPAIGN_VIEW,
        PermissionType.PROMOTION_CAMPAIGN_VIEW_ADD_UPDATE
      ]
    } as MenuItem,

    {
      label: t(i18nKey.label.masterData),
      key: PAGE_ROUTE.MASTER_DATA,
      icon: location.pathname.includes(PAGE_ROUTE.MASTER_DATA) ? (
        <MasterDataActive />
      ) : (
        <MasterData />
      ),
      children: [
        {
          label: t(i18nKey.label.branch),
          key: PAGE_ROUTE.BRANCHES,
          onClick: () => navigator(PAGE_ROUTE.BRANCHES),
          requiredPermission: [PermissionType.BRANCH_VIEW, PermissionType.BRANCH_VIEW_ADD_UPDATE]
        },
        {
          label: t(i18nKey.label.customerGroup),
          key: PAGE_ROUTE.CUSTOMER_CATS,
          onClick: () => navigator(PAGE_ROUTE.CUSTOMER_CATS),
          requiredPermission: [
            PermissionType.CUSTOMER_CATEGORY_VIEW,
            PermissionType.CUSTOMER_CATEGORY_VIEW_ADD_UPDATE
          ]
        },
        {
          label: t(i18nKey.label.membershipLevel),
          key: PAGE_ROUTE.MEMBERSHIPS,
          onClick: () => navigator(PAGE_ROUTE.MEMBERSHIPS),
          requiredPermission: [
            PermissionType.MEMBERSHIP_LEVEL_VIEW,
            PermissionType.MEMBERSHIP_LEVEL_VIEW_ADD_UPDATE
          ]
        },
        {
          label: t(i18nKey.label.serviceGroup),
          key: PAGE_ROUTE.SERVICE_GROUPS,
          onClick: () => navigator(PAGE_ROUTE.SERVICE_GROUPS),
          requiredPermission: [
            PermissionType.SERVICE_CATEGORY_VIEW,
            PermissionType.SERVICE_CATEGORY_VIEW_ADD_UPDATE
          ]
        },
        {
          label: t(i18nKey.label.productCat),
          key: PAGE_ROUTE.PRODUCT_CATS,
          onClick: () => navigator(PAGE_ROUTE.PRODUCT_CATS),
          requiredPermission: [
            PermissionType.PRODUCT_CATEGORY_VIEW,
            PermissionType.PRODUCR_CATEGORY_VIEW_ADD_UPDATE
          ]
        },
        {
          label: t(i18nKey.label.product),
          key: PAGE_ROUTE.PRODUCTS,
          onClick: () => navigator(PAGE_ROUTE.PRODUCTS),
          requiredPermission: [PermissionType.PRODUCT_VIEW, PermissionType.PRODUCT_VIEW_ADD_UPDATE]
        },
        {
          label: t(i18nKey.menu.depositCategory),
          key: PAGE_ROUTE.DEPOSIT_CATS,
          onClick: () => navigator(PAGE_ROUTE.DEPOSIT_CATS),
          requiredPermission: [PermissionType.DEPOSIT_CATEGORY_VIEW_ADD_UPDATE]
        },
        {
          label: t(i18nKey.label.roomType),
          key: PAGE_ROUTE.ROOM_CATS,
          onClick: () => navigator(PAGE_ROUTE.ROOM_CATS),
          requiredPermission: [
            PermissionType.ROOM_CATEGORY_VIEW,
            PermissionType.ROOM_CATEGORY_VIEW_ADD_UPDATE
          ]
        },
        {
          label: t(i18nKey.label.productContainer),
          key: PAGE_ROUTE.PRODUCT_CONTAINERS,
          onClick: () => navigator(PAGE_ROUTE.PRODUCT_CONTAINERS),
          requiredPermission: [PermissionType.PRODUCT_CONTAINER_VIEW_ADD_UPDATE]
        },
        {
          label: t(i18nKey.label.giftVoucherGroup),
          key: PAGE_ROUTE.GIFT_VOUCHER_GROUPS,
          onClick: () => navigator(PAGE_ROUTE.GIFT_VOUCHER_GROUPS),
          requiredPermission: [
            PermissionType.GIFT_VOUCHER_GROUP_VIEW,
            PermissionType.GIFT_VOUCHER_GROUP_VIEW_ADD_UPDATE
          ]
        }
      ]
    } as MenuItem
  ];

  return defaultMenu;
};

export default useMenuItem;
