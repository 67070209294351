import { IMembershipBody, IMembershipRequest } from 'src/interfaces/master-data/membership';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListMembershipDTO extends DTO {
  public param: object | undefined;
  public query: IMembershipRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.MEMBERSHIP;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IMembershipRequest) {
    super();
    this.query = query;
  }
}

export class CreateMembershipDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IMembershipBody;
  public url: string = ENDPOINT.MEMBERSHIP;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IMembershipBody) {
    super();
    this.body = body;
  }
}

export class DetailMembershipDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.MEMBERSHIP_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateMembershipDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IMembershipBody;
  public url: string = ENDPOINT.MEMBERSHIP_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IMembershipBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DeleteMembershipDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: unknown;
  public url: string = ENDPOINT.MEMBERSHIP_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
