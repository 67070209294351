import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { IProductContainersBody, IProductContainersRequest } from 'src/interfaces/master-data/product-containers';
import { DTO } from '../base.dto';

export class GetListProductContainersDTO extends DTO {
  public param: object | undefined;
  public query: IProductContainersRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.PRODUCT_CONTAINERS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query?: IProductContainersRequest) {
    super();
    this.query = query;
  }
}

export class CreateProductContainersDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: IProductContainersBody;
  public url: string = ENDPOINT.PRODUCT_CONTAINERS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IProductContainersBody) {
    super();
    this.body = body;
  }
}

export class DetailProductContainersDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.PRODUCT_CONTAINERS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateProductContainersDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: IProductContainersBody;
  public url: string = ENDPOINT.PRODUCT_CONTAINERS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IProductContainersBody, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DeleteProductContainersDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: unknown;
  public url: string = ENDPOINT.PRODUCT_CONTAINERS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
