const Menu = {
  booking: 'Booking',
  room: 'Room',
  employee: 'Employee',
  arrangeTour: 'Arrange Tour',
  customerCats: 'Customer categories',
  productCats: 'Product categories',
  promotionDiscountCats: 'Discount campaign',
  pos: 'POS',
  order: 'Order',
  depositCategory: 'Deposit Category',
  warehouseHistory: 'Import/Export History',
  inventory: 'Inventory',
  dashboard: 'Dashboard',
  myProfile: 'My Profile'
};

export default Menu;
