import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { TransactionCategoryEnum } from 'src/constants/inventory-history.constant';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  GetListWarehouseDTO,
  IExportInventoryBody,
  IImportInventoryBody,
  IWarehouse,
  IWarehouseRequest,
  ImportInventoryDTO,
  ExportInventoryDTO,
  ITransactionCategory,
  IInventoryHistoryRequest,
  GetListInventoryHistoryDTO,
  IInventoryHistory,
  DetailInventoryTransactionDTO,
  GetListWarehouseProductDTO,
  IWarehouseProductRequest,
  IWarehousePrefixCodeRequest,
  GetListWarehousePrefixCodeDTO
} from 'src/dto/master-data/inventory-history.dto';
import { IPrefixCodeItem } from 'src/dto/product-group/gift-voucher.dto';
import { IPaginationResponse } from 'src/interfaces';
import { IProductsItem } from 'src/interfaces/master-data/products';
import { IHttpService } from 'src/services/http.service';

export interface IInventoryHistoryStore {
  pagination: {
    page: number;
    limit: number;
    current: number;
    total: number;
  };
  params?: IInventoryHistoryRequest;
  listWarehouse: IWarehouse[];
  listTransactionCategory: ITransactionCategory[];
  listInventoryHistory: IInventoryHistory[];
  fetchedWarehouse: boolean;
  fetchedTransactionCategory: boolean;
  fetchList(request?: IInventoryHistoryRequest): Promise<void>;
  fetchWarehouseList(request?: IWarehouseRequest): Promise<void>;
  fetchWarehouseProductList(
    request?: IWarehouseProductRequest
  ): Promise<ResponseDTO<IPaginationResponse<IProductsItem>>>;
  fetchWarehousePrefixCodeList(
    request?: IWarehousePrefixCodeRequest
  ): Promise<ResponseDTO<IPaginationResponse<IPrefixCodeItem>>>;
  fetchTransactionCategoryList(): Promise<void>;
  createImport(body: IImportInventoryBody): Promise<ResponseDTO<any>>;
  createExport(body: IExportInventoryBody): Promise<ResponseDTO<any>>;
  getDetail(
    param: { id: string },
    query: { type: TransactionCategoryEnum }
  ): Promise<ResponseDTO<IInventoryHistory>>;
}

export class InventoryHistoryStore implements IInventoryHistoryStore {
  listWarehouse: IWarehouse[] = [];
  listTransactionCategory: ITransactionCategory[] = [];
  listInventoryHistory: IInventoryHistory[] = [];
  fetchedWarehouse = false;
  fetchedTransactionCategory = false;
  fetchedInventoryHistory = false;
  pagination = {
    page: PAGINATION_CONFIGURATION.DEFAULT_PAGE,
    limit: PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE,
    current: 1,
    total: 0
  };
  params?: IInventoryHistoryRequest | undefined;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listWarehouse: observable.ref,
      listTransactionCategory: observable.ref,
      listInventoryHistory: observable.ref,
      fetchedWarehouse: observable.ref,
      fetchedInventoryHistory: observable.ref,
      fetchedTransactionCategory: observable.ref,
      pagination: observable.ref,
      params: observable.ref,
      fetchWarehouseList: action.bound,
      fetchWarehouseProductList: action.bound,
      fetchWarehousePrefixCodeList: action.bound,
      fetchTransactionCategoryList: action.bound,
      fetchList: action.bound
    });
  }

  public async fetchList(request?: IInventoryHistoryRequest) {
    this.params = { ...this.params, ...request };
    const requestDTO = new GetListInventoryHistoryDTO(request);
    const result: ResponseDTO<IPaginationResponse<IInventoryHistory>> = await this.http.request(
      requestDTO
    );
    if (result?.data) {
      runInAction(() => {
        this.listInventoryHistory = result?.data?.paginatedResults || (result?.data as any) || [];
        this.fetchedInventoryHistory = true;
        this.pagination = {
          limit: result.data?.limit || this.pagination.limit,
          page: result.data?.page || this.pagination.page,
          current: result.data?.current || 0,
          total: result.data?.total || 0
        };
      });
    }
  }

  public async fetchWarehouseList(request?: IWarehouseRequest) {
    const requestDTO = new GetListWarehouseDTO(request);
    const result: ResponseDTO<IWarehouse[]> = await this.http.request(requestDTO);
    if (result.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listWarehouse = result.data ?? [];
        this.fetchedWarehouse = true;
      });
    }
  }


  public async fetchTransactionCategoryList() {
    const requestDTO = new GetListWarehouseDTO();
    const result: ResponseDTO<ITransactionCategory[]> = await this.http.request(requestDTO);
    if (result.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listTransactionCategory = result.data ?? [];
        this.fetchedTransactionCategory = true;
      });
    }
  }

  public async fetchWarehouseProductList(request: IWarehouseProductRequest) {
    const requestDTO = new GetListWarehouseProductDTO(
      { warehouseId: request.warehouseId },
      { page: request.page, limit: request.limit, catId: request.catId, isNotIncludeFreeItem: request.isNotIncludeFreeItem }
    );
    const result: ResponseDTO<IPaginationResponse<IProductsItem>> = await this.http.request(
      requestDTO
    );
    return result;
  }

  public async fetchWarehousePrefixCodeList(request: IWarehousePrefixCodeRequest) {
    const requestDTO = new GetListWarehousePrefixCodeDTO(
      { warehouseId: request.warehouseId },
      { page: request.page, limit: request.limit }
    );
    const result: ResponseDTO<IPaginationResponse<IPrefixCodeItem>> = await this.http.request(
      requestDTO
    );
    return result;
  }

  public async createImport(body: IImportInventoryBody) {
    const createImportDTO = new ImportInventoryDTO(body);
    return await this.http.request(createImportDTO);
  }

  public async createExport(body: IExportInventoryBody) {
    const createExportDTO = new ExportInventoryDTO(body);
    return await this.http.request(createExportDTO);
  }

  public async getDetail(param: { id: string }, query: { type: TransactionCategoryEnum }) {
    const detailDTO = new DetailInventoryTransactionDTO(param, query);
    const res: ResponseDTO<IInventoryHistory> = await this.http.request(detailDTO);
    return res;
  }
}
