import { IRoomReportsRequest } from 'src/interfaces/reports/roomBed';
import { DTO } from '../base.dto';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { ResponseType } from 'axios';

export class GetListRoomReportsDTO extends DTO {
  public param: object | undefined;
  public query: IRoomReportsRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.ROOM_BED_REPORT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType?: ResponseType = 'json';
  constructor(query?: IRoomReportsRequest) {
    super();
    this.query = query;
  }
}
