import { makeAutoObservable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { RoleOrder } from 'src/constants/permission';
import {
  IBodyUpdatePermission,
  ResponseRBACDTO,
  UpdateListPermissionDTO
} from 'src/dto/administration/user-permission.dto';
import { ResponseDTO } from 'src/dto/base.dto';
import { GetUserPermissionDTO, IUserPermissionResponse } from 'src/dto/user-permission.dto';
import { IRole } from 'src/interfaces';
import { IResourcePermission, IRolePermission } from 'src/interfaces/permission';
import { IHttpService } from 'src/services/http.service';

export interface IUserPermissionStore {
  roleList: IRole[];
  resourceList: IResourcePermission[];
  rolePermissions: IRolePermission[];

  fetchUserPermission(): Promise<void>;
  update(body: IBodyUpdatePermission): Promise<ResponseDTO<ResponseRBACDTO>>;
}

export class UserPermissionStore implements IUserPermissionStore {
  roleList: IRole[] = [];
  rolePermissions: IRolePermission[] = [];
  resourceList: IResourcePermission[] = [];

  constructor(private http: IHttpService) {
    makeAutoObservable(this);
  }

  public async fetchUserPermission(): Promise<void> {
    const requestDTO = new GetUserPermissionDTO();
    const res: ResponseDTO<IUserPermissionResponse> = await this.http.request(requestDTO);

    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        const roleList = res.data?.role || [];
        this.roleList =
          roleList.length > 0
            ? RoleOrder.reduce((roles: IRole[], roleName: string) => {
                const roleDetail = roleList.find((role: IRole) => role.name === roleName);
                if (roleDetail) roles.push(roleDetail);
                return roles;
              }, [])
            : [];

        this.resourceList = res.data?.resourcePermission || [];
        this.rolePermissions = res.data?.rolePermission || [];
      });
    }
  }

  public async update(body: IBodyUpdatePermission): Promise<ResponseDTO<ResponseRBACDTO>> {
    const updateListPermissionDto = new UpdateListPermissionDTO(body);
    const res: ResponseDTO<ResponseRBACDTO> = await this.http.request(updateListPermissionDto);
    return res;
  }
}
