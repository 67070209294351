import { action, makeObservable, observable } from 'mobx';
import { CONFIG_KEY, USER_LANGUEGE } from 'src/constants/app';
import { LANGUAGE } from 'src/constants/language';

export interface ISticky {
  isSticky: boolean;
  offset: number;
}
export interface IConfiguration {
  sticky: ISticky;
  lang: LANGUAGE;
  changeLanguage(lang: LANGUAGE): void;
  changeSticky(newSticky: ISticky): void;
}

export default class ConfigurationStore implements IConfiguration {
  @observable
  lang: LANGUAGE = LANGUAGE.VN;
  @observable
  sticky: ISticky = { isSticky: false, offset: 0 };
  constructor() {
    const configs: string | null = localStorage.getItem(CONFIG_KEY);
    if (configs) {
      JSON.stringify(configs);
    }
    this.lang = (localStorage.getItem(USER_LANGUEGE) as LANGUAGE) || LANGUAGE.VN;
    makeObservable(this);
  }

  @action.bound
  changeLanguage(lang: LANGUAGE): void {
    this.lang = lang;
    localStorage.setItem(USER_LANGUEGE, this.lang);
  }

  @action.bound
  changeSticky(newSticky: ISticky): void {
    this.sticky = newSticky;
  }
}
