import {
  CreatePrefixCodeDTO,
  DetailPrefixCodeDTO,
  GetListPrefixCodeDTO,
  IGetListPrefixCodeRequest,
  IGetListPrefixCodeResponse,
  IGiftVoucherCat,
  IPrefixCodeBody,
  IPrefixCodeItem,
  UpdatePrefixCodeDTO,
  GetListGiftVoucherDTO,
  GetListGiftVoucherCategoriesDTO,
  IGetListGiftVoucherRequest,
  IGiftVoucherItem,
  GetDiscountGiftVoucherDTO,
  GetVoucherListByWarehouseIdDTO,
  IUpdateGiftVoucherBody,
  UpdateGiftVoucherCodeDTO,
  IGetListGiftVoucherValidRequest,
  GetListGiftVoucherValidDTO,
  IGetListGiftVoucherValidResponse,
  DeletePrefixCodeDTO
} from 'src/dto/product-group/gift-voucher.dto';
import { ResponseDTO } from 'src/dto/base.dto';
import { IHttpService } from 'src/services/http.service';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { PAGINATION_CONFIGURATION, DEFAULT_VALUES } from 'src/constants';
import { IPaginationResponse } from 'src/interfaces';
export interface IGiftVoucherStore {
  listGiftVoucherCats: IGiftVoucherCat[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  totalUnread: number;
  prefixCodeRequestParams?: IGetListPrefixCodeRequest;
  fetchListPrefixCode(
    request?: IGetListPrefixCodeRequest
  ): Promise<ResponseDTO<IGetListPrefixCodeResponse>>;
  fetchListGiftVoucher(
    param: { prefixCode: string },
    request?: IGetListGiftVoucherRequest
  ): Promise<ResponseDTO<IGetListPrefixCodeResponse>>;
  getListGiftVoucher(
    request: IGetListGiftVoucherRequest
  ): Promise<ResponseDTO<IPaginationResponse<IGiftVoucherItem>>>;
  getDiscountGiftVoucher(
    params: { code: string },
    query: { serviceId: number[]; branchId: number } | undefined
  ): Promise<ResponseDTO<IGiftVoucherItem>>;
  createPrefixCode(body: IPrefixCodeBody): Promise<ResponseDTO<IPrefixCodeItem>>;
  updatePrefixCode(
    body: IPrefixCodeBody,
    param: { prefixCode: string }
  ): Promise<ResponseDTO<IPrefixCodeItem>>;
  getDetailPrefixCode(query: { prefixCode: string }): Promise<ResponseDTO<IPrefixCodeItem>>;
  getVoucherListByWarehouseId(warehouseId: number): Promise<IGiftVoucherItem[]>;
  updateGiftVoucherCode(code: string, body: IUpdateGiftVoucherBody): Promise<boolean>;
  fetchListGiftVoucherCats(): void;
  getListGiftVoucherValid(
    request: IGetListGiftVoucherValidRequest
  ): Promise<ResponseDTO<IGetListGiftVoucherValidResponse>>;
  delete(param: { code: string }): Promise<ResponseDTO<string>>;
}

export class GiftVoucherStore implements IGiftVoucherStore {
  listGiftVoucherCats: IGiftVoucherCat[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  totalUnread = 0;
  prefixCodeRequestParams?: IGetListPrefixCodeRequest;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listGiftVoucherCats: observable.ref,
      prefixCodeRequestParams: observable.ref,
      fetchListGiftVoucherCats: action.bound
    });
  }

  public async fetchListPrefixCode(
    request: IGetListPrefixCodeRequest
  ): Promise<ResponseDTO<IGetListPrefixCodeResponse>> {
    runInAction(() => {
      this.prefixCodeRequestParams = request;
    })
    const requestDTO = new GetListPrefixCodeDTO(request);
    const result: ResponseDTO<IGetListPrefixCodeResponse> = await this.http.request(requestDTO);
    return Promise.resolve(result);
  }

  public async fetchListGiftVoucher(
    param: {
      prefixCode: string;
    },
    request?: IGetListGiftVoucherRequest
  ): Promise<ResponseDTO<IGetListPrefixCodeResponse>> {
    const requestDTO = new GetListGiftVoucherDTO(param, {
      ...request,
      page: request?.page || this.pageNumber,
      limit: request?.limit || this.pageSize
    });
    const result: ResponseDTO<IGetListPrefixCodeResponse> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.totalRecords = result.data?.total ?? 0;
        this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
    return Promise.resolve(result);
  }

  public async getDiscountGiftVoucher(
    param: { code: string },
    query: { serviceId: number[]; branchId: number } | undefined
  ): Promise<ResponseDTO<IGiftVoucherItem>> {
    const requestDTO = new GetDiscountGiftVoucherDTO(param, query);
    const result: ResponseDTO<IGiftVoucherItem> = await this.http.request(requestDTO);
    return result;
  }

  public async getListGiftVoucher(
    request: IGetListGiftVoucherRequest
  ): Promise<ResponseDTO<IPaginationResponse<IGiftVoucherItem>>> {
    const requestDTO = new GetListGiftVoucherDTO(
      { prefixCode: request.prefixCode || DEFAULT_VALUES.EMPTY_STRING },
      { ...request, prefixCode: undefined }
    );
    const result: ResponseDTO<IPaginationResponse<IGiftVoucherItem>> = await this.http.request(
      requestDTO
    );
    return result;
  }

  public async createPrefixCode(body: IPrefixCodeBody) {
    const createDTO = new CreatePrefixCodeDTO(body);
    const res: ResponseDTO<IPrefixCodeItem> = await this.http.request(createDTO);
    return res;
  }

  public async updatePrefixCode(body: IPrefixCodeBody, param: { prefixCode: string }) {
    const updateStoreDTO = new UpdatePrefixCodeDTO(body, param);
    const res: ResponseDTO<IPrefixCodeItem> = await this.http.request(updateStoreDTO);
    return res;
  }

  public async getDetailPrefixCode(query: { prefixCode: string }) {
    const detailDTO = new DetailPrefixCodeDTO(query);
    const res: ResponseDTO<IPrefixCodeItem> = await this.http.request(detailDTO);
    return Promise.resolve(res);
  }

  public async fetchListGiftVoucherCats() {
    const requestDTO = new GetListGiftVoucherCategoriesDTO();
    const result: ResponseDTO<IGiftVoucherCat[]> = await this.http.request(requestDTO);
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listGiftVoucherCats = result?.data ?? [];
      });
    }
  }

  public async getVoucherListByWarehouseId(warehouseId: number): Promise<IGiftVoucherItem[]> {
    const requestDTO = new GetVoucherListByWarehouseIdDTO({ warehouseId });
    const result: ResponseDTO<IGiftVoucherItem[]> = await this.http.request(requestDTO);
    return result.data as IGiftVoucherItem[];
  }

  public async updateGiftVoucherCode(code: string, body: IUpdateGiftVoucherBody): Promise<boolean> {
    const createDTO = new UpdateGiftVoucherCodeDTO({ code }, body);
    const res: ResponseDTO<IPrefixCodeItem> = await this.http.request(createDTO);

    return res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS;
  }

  public async getListGiftVoucherValid(
    request: IGetListGiftVoucherValidRequest
  ): Promise<ResponseDTO<IGetListGiftVoucherValidResponse>> {
    const requestDTO = new GetListGiftVoucherValidDTO(request);
    const result: ResponseDTO<IGetListGiftVoucherValidResponse> = await this.http.request(
      requestDTO
    );
    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      return Promise.resolve(result);
    }
    return Promise.reject(result);
  }

  public async delete(param: { code: string }) {
    const deleteStoreDTO = new DeletePrefixCodeDTO(param);
    const res: ResponseDTO<string> = await this.http.request(deleteStoreDTO);
    return res;
  }
}
