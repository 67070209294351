export enum GiftVoucherCategory {
  DISCOUNT_ON_ORDER = 1,
  DISCOUNT_ON_SERVICE = 2
}

export enum GiftVoucherCatEnum {
  DISCOUNT_WITH_SERVICES = 'DISCOUNT-WITH-SERVICES',
  SERVICE_SPECIFIC_DISCOUNT = 'SERVICE-SPECIFIC-DISCOUNT',
}

export enum GiftVoucherDiscountCatEnum {
  SERVICE = 'SERVICE',
  PACKAGE = 'PACKAGE'
}
