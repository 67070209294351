import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { NOTIFICATION_FILTER_TYPE } from 'src/constants/notification';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  GetCountNotificationDTO,
  INotification,
  INotificationRequest,
  INotificationResponse,
  ListNotificationDTO,
  ReadAllNotificationDTO,
  ReadNotificationByIdDTO
} from 'src/dto/notification/notification.dto';
import { IHttpService } from 'src/services/http.service';

export interface INotificationStore {
  list: INotification[];
  currentList: INotification[];
  totalPages: number;
  totalRecords?: number;
  pageSize: number;
  pageNumber: number;
  unreadCount: number;

  fetchList(request?: INotificationRequest): Promise<INotification[]>;
  readById(id: number): Promise<boolean>;
  readAll(): Promise<boolean>;
  getCount(request?: INotificationRequest): Promise<void>;

  resetList(): void;
}

export class NotificationStore implements INotificationStore {
  list: INotification[] = [];
  currentList: INotification[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  unreadCount = 0;

  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      list: observable.ref,
      pageSize: observable,
      totalPages: observable,
      resetList: action.bound
    });
  }

  public async fetchList(request?: INotificationRequest): Promise<INotification[]> {
    const requestDTO = new ListNotificationDTO({
      ...request,
      page: request?.page || this.pageNumber,
      limit: request?.limit || this.pageSize
    });
    const result: ResponseDTO<INotificationResponse> = await this.http.request(requestDTO);

    if (result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.currentList = result.data?.paginatedResults ?? [];

        this.pageSize = result.data?.limit ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber = result.data?.page ?? PAGINATION_CONFIGURATION.DEFAULT_PAGE;

        if (result.data?.page === PAGINATION_CONFIGURATION.DEFAULT_PAGE) {
          this.list = this.currentList.slice(0);
        } else {
          this.list = this.list.concat(result.data?.paginatedResults ?? []);
        }

        if (request?.state === NOTIFICATION_FILTER_TYPE.ALL)
          this.totalRecords = result.data?.total ?? this.totalRecords;
      });
    }

    return result.data?.paginatedResults || [];
  }

  public async readById(id: number): Promise<boolean> {
    const requestDTO = new ReadNotificationByIdDTO({ id });
    const result: ResponseDTO<INotificationResponse> = await this.http.request(requestDTO);

    runInAction(() => {
      const oldList = [...this.list];
      const updateIndex = oldList.findIndex((item: INotification) => item.id === id);
      oldList[updateIndex].readAt = new Date().toISOString();
      this.list = oldList;
    });

    return result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS;
  }

  public async readAll(): Promise<boolean> {
    const requestDTO = new ReadAllNotificationDTO();
    const result: ResponseDTO<INotificationResponse> = await this.http.request(requestDTO);

    runInAction(() => {
      const oldList = [...this.list];
      this.list = oldList.map((item: INotification) => ({
        ...item,
        readAt: new Date().toISOString()
      }));
    });

    return result.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS;
  }

  public async getCount(request?: INotificationRequest): Promise<void> {
    const requestDTO = new GetCountNotificationDTO(request);
    const result: ResponseDTO<number> = await this.http.request(requestDTO);

    runInAction(() => {
      this.unreadCount = result.data as number;
    });
  }

  public resetList(): void {
    this.list = [];
    this.pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
    this.pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  }
}
