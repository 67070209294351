const Customer = {
  title: 'Customer Management',
  allergies: 'Allergy information',
  is_pregnant: 'Pregnant',

  label: {
    date: 'Time',
    newCustomerGroup: 'New Customer'
  },

  create: {
    title: 'Create Customer'
  },

  errMsg: {
    existingPhoneNo: 'Phone number already exists in the system!'
  },

  placeholder: {
    search: 'Search by Name, Email, Phone Number'
  },

  edit: {
    title: 'Update customer information'
  },

  detail: {
    title: 'Customer details',
    depositSpending: 'Accumulated spending',
    membershipChangedAt: 'Membership upgrade date'
  }
};

export default Customer;
